import { axiosWithAuth, axiosWithoutAuth } from "../utils/customAxios";

const SettingService = 
{
    getProductVersion : async () : Promise<string> => {
        const response = await axiosWithoutAuth.get('systemsetting/productversion');
        return response.data.message;
    }
}

export default SettingService;