import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BrandProvider } from "./contexts/BrandProvider";
import { LocaleProvider } from "./contexts/LocaleProvider";
import { AuthProvider } from "./contexts/AuthProvider";
import { MasterDataProvider } from "./contexts/MasterDataProvider";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { NavigationProvider } from "./contexts/NavigationProvider";
import { TimezoneProvider } from "./contexts/TimezoneProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const hostname = window.location.hostname;
export const appUrl =
  hostname === "localhost" ? `http://${hostname}:3000` : `https://${hostname}`;

const clientInstance = new PublicClientApplication({
  auth: {
    clientId: "7efe1c76-2f6a-4899-be6e-c83d8a4aef04", // `${process.env.REACT_APP_CLIENTID}` // TODO - Remove hardcoding
    redirectUri: `${appUrl}/`,
    postLogoutRedirectUri: appUrl,
  },
});

root.render(
  <Router>
    <BrandProvider>
      <LocaleProvider>
        <AuthProvider>
          <TimezoneProvider>
            <MsalProvider instance={clientInstance}>
              <NavigationProvider>
                {/* <MasterDataProvider> */}
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
                {/* </MasterDataProvider> */}
              </NavigationProvider>
            </MsalProvider>
          </TimezoneProvider>
        </AuthProvider>
      </LocaleProvider>
    </BrandProvider>
  </Router>
);
