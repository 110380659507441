import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { useEffect, useState } from "react";
import {
  DonutStorageVisualization,
  StorageLocation,
} from "../../../types/storage";
import useBranding from "../../../hooks/useBranding";
import { storageSizetoKB } from "./StorageSettings";

interface StorageVisualizationChartProps {
  editing: boolean;
  data?: StorageLocation;
  setDonutStorage: React.Dispatch<
    React.SetStateAction<DonutStorageVisualization[]>
  >;
  donutStorage: DonutStorageVisualization[];
  freeLabel: string;
  usedLabel: string;
  configuring:boolean;
}

const StorageVisualizationChart: React.FC<StorageVisualizationChartProps> = ({
  editing,
  data,
  setDonutStorage,
  donutStorage,
  freeLabel,
  usedLabel,
  configuring
}) => {
  const brandingCtx = useBranding();

  useEffect(() => {
    const totalSize =
      data?.totalSizeInBytes && data?.totalSizeInBytes / storageSizetoKB;
    const usedSize =
      data?.usedSizeInBytes && data.usedSizeInBytes / storageSizetoKB;
    const usedSizePercentage =
      totalSize === 0
        ? usedSize === 0
          ? 0
          : 100
        : usedSize && totalSize && (usedSize / totalSize) * 100;
    if (
      totalSize !== undefined &&
      usedSize !== undefined &&
      usedSizePercentage !== undefined
    ) {
      if (totalSize === 0) {
        setDonutStorage([
          {
            field: freeLabel,
            spacePercentage: usedSize === 0 ? 100 : 0,
            spaceInGB: undefined,
            color: "grey",
            legend: "Undefined",
          },
          {
            field: usedLabel,
            spacePercentage: usedSize === 0 ? 0 : usedSizePercentage,
            spaceInGB: usedSize === 0 ? undefined : usedSize,
            color: brandingCtx?.branding?.themeColors[0].colorValue ?? "red",
            legend: usedSize === 0 ? "Undefined" : `${usedSize.toFixed(2)} GB`,
          },
        ]);
      } else {
        setDonutStorage([
          {
            field: freeLabel,
            spacePercentage:
              usedSizePercentage > 100 ? 0 : 100 - usedSizePercentage,
            spaceInGB: totalSize - usedSize,
            color: "grey",
            legend: `${(totalSize - usedSize).toFixed(2)} GB`,
          },
          {
            field: usedLabel,
            spacePercentage: usedSizePercentage,
            spaceInGB: usedSize,
            color: brandingCtx?.branding?.themeColors[0].colorValue ?? "red",
            legend: `${usedSize.toFixed(2)} GB`,
          },
        ]);
      }
    }
  }, [data]);

  const renderTooltip = (context: any) => {
    const { value } = context.point || context;
    return <div>{value.toFixed(2)}%</div>;
  };

  return (
    <>
      {!editing && !configuring && (
        <>
          <div className="col-md-4 storageChartDonut topPush20">
            <Chart
              onLegendItemClick={() => {}}
              style={{
                height: "280px",
              }}
            >
              <ChartTooltip
                visible={
                  !donutStorage.find(
                    (donut: DonutStorageVisualization) =>
                      donut.field === "Free" && donut.spaceInGB === undefined
                  )
                }
                render={renderTooltip}
              />
              <ChartArea background="transparent" />
              <ChartSeries>
                <ChartSeriesItem
                  type="donut"
                  data={donutStorage}
                  categoryField="field"
                  field="spacePercentage"
                  colorField="color"
                ></ChartSeriesItem>
              </ChartSeries>
              <ChartLegend
                labels={{
                  content: (e: any) => {
                    return e.text + ": " + e.dataItem.legend;
                  },
                }}
                visible={true}
                position="bottom"
              ></ChartLegend>
            </Chart>
          </div>
          <div className="col-md-3">&nbsp;</div>
        </>
      )}
    </>
  );
};

export default StorageVisualizationChart;
