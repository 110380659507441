import React, { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  DropDownList,
  DropDownListChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { EntityKey } from "../../../../../types/master-data/EntityKey";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { CallSubsetType } from "../../../../../types/CallSubsetType/CallSubsetType";

interface IRecordingPolicyDropdown extends EntityKey {
  id: number;
  title: string;
  include: boolean;
}

const recordingPolicyDropdownData: IRecordingPolicyDropdown[] = [
  {
    id: 0,
    include: true,
    title: "Include following Call Subset",
    localizationKey: "IncludeBelowCalls",
  },
  {
    id: 1,
    include: false,
    title: "Exclude following Call Subset",
    localizationKey: "ExcludeBelowCalls",
  },
];

interface RecordingPolicyDialogProps {
  fetchLabelKeyTranslation: (key: string, defaultValue: string) => string;
  toggleRecPolicyDialog: () => void;
  excludeCallSubset: boolean;
  callSubset: CallSubsetType | undefined;
  internalChecked: boolean;
  receivedChecked: boolean;
  dialedChecked: boolean;
  externalChecked: boolean;
  updateRecordingPolicy: () => void;
  setNewRecordingPolicy: (
    dialedCheckedVal: boolean,
    receivedCheckedVal: boolean,
    internalCheckedVal: boolean,
    externalCheckedVal: boolean,
    excludeCallSubsetVal: boolean
  ) => void;
}

const RecordingPolicyDialog: React.FC<RecordingPolicyDialogProps> = ({
  fetchLabelKeyTranslation,
  toggleRecPolicyDialog,
  excludeCallSubset,
  callSubset,
  internalChecked,
  receivedChecked,
  dialedChecked,
  externalChecked,
  updateRecordingPolicy,
  setNewRecordingPolicy,
}) => {
  const [internalCheckedTemp, setInternalCheckedTemp] =
    useState<boolean>(internalChecked);
  const [receivedCheckedTemp, setReceivedCheckedTemp] =
    useState<boolean>(receivedChecked);
  const [dialedCheckedTemp, setdialedCheckedTemp] =
    useState<boolean>(dialedChecked);
  const [externalCheckedTemp, setExternalCheckedTemp] =
    useState<boolean>(externalChecked);
  const [excludeCallSubsetTemp, setExcludeCallSubsetTemp] =
    useState<boolean>(excludeCallSubset);
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);

  useEffect(() => {
    compareState();
  }, [
    externalCheckedTemp,
    internalCheckedTemp,
    dialedCheckedTemp,
    receivedCheckedTemp,
    excludeCallSubsetTemp,
  ]);

  const updateInternal = (e: CheckboxChangeEvent) => {
    setInternalCheckedTemp(e.value);
    setNewRecordingPolicy(
      dialedCheckedTemp,
      receivedCheckedTemp,
      e.value,
      externalCheckedTemp,
      excludeCallSubsetTemp
    );
  };

  const updateExternal = (e: CheckboxChangeEvent) => {
    setExternalCheckedTemp(e.value);
    setNewRecordingPolicy(
      dialedCheckedTemp,
      receivedCheckedTemp,
      internalCheckedTemp,
      e.value,
      excludeCallSubsetTemp
    );
  };

  const updateDialed = (e: CheckboxChangeEvent) => {
    setdialedCheckedTemp(e.value);
    setNewRecordingPolicy(
      e.value,
      receivedCheckedTemp,
      internalCheckedTemp,
      externalCheckedTemp,
      excludeCallSubsetTemp
    );
  };

  const updateReceived = (e: CheckboxChangeEvent) => {
    setReceivedCheckedTemp(e.value);
    setNewRecordingPolicy(
      dialedCheckedTemp,
      e.value,
      internalCheckedTemp,
      externalCheckedTemp,
      excludeCallSubsetTemp
    );
  };

  const handleRecordingPolicyDropdownData = (
    event: DropDownListChangeEvent
  ) => {
    setExcludeCallSubsetTemp(!event.value.include);
    setNewRecordingPolicy(
      dialedCheckedTemp,
      receivedCheckedTemp,
      internalCheckedTemp,
      externalCheckedTemp,
      !event.value.include
    );
  };

  const recordingPolicyItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span>
        {fetchLabelKeyTranslation(
              itemProps.dataItem.localizationKey ?? "",
              itemProps.dataItem.title
            )}
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const recordingPolicyValueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: IRecordingPolicyDropdown
  ) => {
    if (!value) {
      return element;
    }
    const children = [
      <span>
        {fetchLabelKeyTranslation(value.localizationKey ?? "", value.title)}
      </span>,
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };

  const compareState = () => {
    if (
      internalChecked === internalCheckedTemp &&
      externalChecked === externalCheckedTemp &&
      receivedChecked === receivedCheckedTemp &&
      dialedChecked === dialedCheckedTemp &&
      excludeCallSubset === excludeCallSubsetTemp
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  return (
    <Dialog
      title={`${
        fetchLabelKeyTranslation(
              "RecordingPolicyDailogTitle",
              "Recording Policy"
            )
      }`}
      onClose={toggleRecPolicyDialog}
    >
      <div
        style={{
          textAlign: "center",
          padding: "15px 0 0 0",
          minHeight: "65px",
        }}
      >
        <div>
          <DropDownList
            className="recordingpolicy-dropdown font-weight-bold w-100"
            data={recordingPolicyDropdownData}
            value={recordingPolicyDropdownData.find(
              (data: IRecordingPolicyDropdown) =>
                data.include === !excludeCallSubsetTemp
            )}
            textField="title"
            dataItemKey="id"
            itemRender={recordingPolicyItemRender}
            valueRender={recordingPolicyValueRender}
            onChange={handleRecordingPolicyDropdownData}
          />
        </div>
      </div>
      <p className="font-weight-bold">
        <label className="font-weight-bold">
          {`${
            fetchLabelKeyTranslation("CallSubsetText", "Call Subset")
          }:`}
        </label>
        &nbsp;
        <span className="font-weight-bold text-black-11">
          {callSubset?.name}
        </span>
      </p>
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="w-100 float-left font-weight-bold ">{`${
              fetchLabelKeyTranslation(
                    "CallDirectionText",
                    "Call Direction"
                  )
            }:`}</div>
            <div className="w-100 float-left border-black-3 border-w-2 border-dashed p-l-10 p-t-5">
              <div>
                <Checkbox
                  className="p-r-5"
                  checked={dialedCheckedTemp}
                  onChange={updateDialed}
                  disabled={!receivedCheckedTemp && dialedCheckedTemp}
                />
                <label className="font-weight-bold text-black-11">
                  {`${
                    fetchLabelKeyTranslation("DialedRow", "Dialled")
                  }`}
                </label>
              </div>
              <div>
                <Checkbox
                  className="p-r-5"
                  checked={receivedCheckedTemp}
                  onChange={updateReceived}
                  disabled={receivedCheckedTemp && !dialedCheckedTemp}
                />
                <label className="font-weight-bold text-black-11">
                  {`${
                    fetchLabelKeyTranslation("ReceivedRow", "Received")
                  }`}
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="w-100 float-left font-weight-bold">{`${
              fetchLabelKeyTranslation("CallTypeText", "Call Type")
            }:`}</div>
            <div className="w-100 float-left border-black-3 border-w-2 border-dashed  p-l-10 p-t-5">
              <div>
                <Checkbox
                  className="p-r-5"
                  checked={internalCheckedTemp}
                  onChange={updateInternal}
                  disabled={internalCheckedTemp && !externalCheckedTemp}
                />
                <label className="font-weight-bold text-black-11">
                  {`${
                   fetchLabelKeyTranslation("InternalRow", "Internal")
                  }`}
                </label>
              </div>
              <div>
                <Checkbox
                  className="p-r-5"
                  checked={externalCheckedTemp}
                  onChange={updateExternal}
                  disabled={externalCheckedTemp && !internalCheckedTemp}
                />
                <label className="font-weight-bold text-black-11">
                  {`${
                    fetchLabelKeyTranslation("ExternalRow", "External")
                  }`}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={toggleRecPolicyDialog}
        >
          {`${
            fetchLabelKeyTranslation("CancelButton", "Cancel")
          }`}
        </button>
        <button
          className={`k-button k-button-md text-white k-rounded-md bg-primary k-button-solid border-none ${
            ((callSubset === undefined ? true : false) || buttonStateTracker) &&
            "disabledBtn"
          }`}
          disabled={
            (callSubset === undefined ? true : false) || buttonStateTracker
          }
          onClick={updateRecordingPolicy}
        >
          {`${
            fetchLabelKeyTranslation("UpdateButton", "Update")
          }`}
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default RecordingPolicyDialog;
