import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { AccessPermissionEnum } from "../../../enums";
import { GridCellProps } from "@progress/kendo-react-grid";
import { CallDetail } from "../../../types/call";
import useAuth from "../../../hooks/useAuth";

interface CustomGridCellProps extends GridCellProps {
  dataItem: CallDetail;
  handlePlayerExpand: (callDetail: CallDetail) => void;
  fetchLabelKeyTranslation:(key: string, defaultValue: string) => string;
}

const CustomGridCellForCallPlayIcon = (props: CustomGridCellProps) => {
  const auth = useAuth();
  const isDiscarded : boolean = (props.dataItem.status === "Discarded")
  return (
    <td className="text-center">
        <span className="iBtn">
          {props.dataItem.expanded ? (
            <span title={props.fetchLabelKeyTranslation("CallCloseText","Close")}>
              <Button
                style={{ opacity: "0.7" }}
                className="text-white radius-50 bg-primary"
                onClick={() => props.handlePlayerExpand(props.dataItem)}
              >
                <i className="bi bi-x-lg"></i>
              </Button>
            </span>
          ) : (
            <span title={props.fetchLabelKeyTranslation("CallPlayText","Play")}>
              <Button
                style={{ opacity: "0.7" }}
                className="text-white radius-50 bg-primary"
                onClick={() => props.handlePlayerExpand(props.dataItem)}
                disabled={isDiscarded}
              >
                <i className="bi bi bi-play fs-18"></i>
              </Button>
            </span>
          )}
        </span>
    </td>
  );
};

export default CustomGridCellForCallPlayIcon;
