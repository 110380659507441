import {
  Loader,
  LoaderSize,
  LoaderThemeColor,
} from "@progress/kendo-react-indicators";

interface LoadingOverlayProps {
  customStyle: React.CSSProperties;
  themeColor?: LoaderThemeColor;
  size?: LoaderSize;
  loadingText?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  customStyle,
  themeColor,
  size,
  loadingText,
}) => {
  return (
    <div className="loaderBgOut" style={customStyle}>
      <div className="loaderBgIn">
        <Loader type={"infinite-spinner"} themeColor={themeColor} size={size} />
        <span className="loaderTxt">{loadingText ?? ""}</span>
      </div>
    </div>
  );
};

export default LoadingOverlay;
