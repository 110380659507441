import {
  IntlProvider,
  load,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import { createContext, useEffect, useState } from "react";
import useSessionStorage from "../hooks/useSessionStorage";
import { Dictionary } from "../types/Dictionary";
import { LocalizationCode } from "../types/locale/LocalizationCode";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import enNumbers from "cldr-numbers-full/main/en/numbers.json";
import enCurrencies from "cldr-numbers-full/main/en/currencies.json";
import enCaGregorian from "cldr-dates-full/main/en/ca-gregorian.json";
import enDateFields from "cldr-dates-full/main/en/dateFields.json";

import frNumbers from "cldr-numbers-full/main/fr/numbers.json";
import frCurrencies from "cldr-numbers-full/main/fr/currencies.json";
import frCaGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import frDateFields from "cldr-dates-full/main/fr/dateFields.json";

import nlNumbers from "cldr-numbers-full/main/nl/numbers.json";
import nlCurrencies from "cldr-numbers-full/main/nl/currencies.json";
import nlCaGregorian from "cldr-dates-full/main/nl/ca-gregorian.json";
import nlDateFields from "cldr-dates-full/main/nl/dateFields.json";

import svNumbers from "cldr-numbers-full/main/sv/numbers.json";
import svCurrencies from "cldr-numbers-full/main/sv/currencies.json";
import svCaGregorian from "cldr-dates-full/main/sv/ca-gregorian.json";
import svDateFields from "cldr-dates-full/main/sv/dateFields.json";

import plNumbers from "cldr-numbers-full/main/pl/numbers.json";
import plCurrencies from "cldr-numbers-full/main/pl/currencies.json";
import plCaGregorian from "cldr-dates-full/main/pl/ca-gregorian.json";
import plDateFields from "cldr-dates-full/main/pl/dateFields.json";

import ltNumbers from "cldr-numbers-full/main/lt/numbers.json";
import ltCurrencies from "cldr-numbers-full/main/lt/currencies.json";
import ltCaGregorian from "cldr-dates-full/main/lt/ca-gregorian.json";
import ltDateFields from "cldr-dates-full/main/lt/dateFields.json";

import lvNumbers from "cldr-numbers-full/main/lv/numbers.json";
import lvCurrencies from "cldr-numbers-full/main/lv/currencies.json";
import lvCaGregorian from "cldr-dates-full/main/lv/ca-gregorian.json";
import lvDateFields from "cldr-dates-full/main/lv/dateFields.json";

import itNumbers from "cldr-numbers-full/main/it/numbers.json";
import itCurrencies from "cldr-numbers-full/main/it/currencies.json";
import itCaGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import itDateFields from "cldr-dates-full/main/it/dateFields.json";

import deNumbers from "cldr-numbers-full/main/de/numbers.json";
import deCurrencies from "cldr-numbers-full/main/de/currencies.json";
import deCaGregorian from "cldr-dates-full/main/de/ca-gregorian.json";
import deDateFields from "cldr-dates-full/main/de/dateFields.json";

import fiNumbers from "cldr-numbers-full/main/fi/numbers.json";
import fiCurrencies from "cldr-numbers-full/main/fi/currencies.json";
import fiCaGregorian from "cldr-dates-full/main/fi/ca-gregorian.json";
import fiDateFields from "cldr-dates-full/main/fi/dateFields.json";

import etNumbers from "cldr-numbers-full/main/et/numbers.json";
import etCurrencies from "cldr-numbers-full/main/et/currencies.json";
import etCaGregorian from "cldr-dates-full/main/et/ca-gregorian.json";
import etDateFields from "cldr-dates-full/main/et/dateFields.json";

import daNumbers from "cldr-numbers-full/main/da/numbers.json";
import daCurrencies from "cldr-numbers-full/main/da/currencies.json";
import daCaGregorian from "cldr-dates-full/main/da/ca-gregorian.json";
import daDateFields from "cldr-dates-full/main/da/dateFields.json";

import esNumbers from "cldr-numbers-full/main/es/numbers.json";
import esCurrencies from "cldr-numbers-full/main/es/currencies.json";
import esCaGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import esDateFields from "cldr-dates-full/main/es/dateFields.json";

import thNumbers from "cldr-numbers-full/main/th/numbers.json"
import thCurrencies from "cldr-numbers-full/main/th/currencies.json";
import thCaGregorian from "cldr-dates-full/main/th/ca-gregorian.json";
import thDateFields from "cldr-dates-full/main/th/dateFields.json";

import useBranding from "../hooks/useBranding";
import localeService from "../services/locale.service";
import useLocalStorage from "../hooks/useLocalStorage";

interface LocaleTranslation {
  locale: LocalizationCode;
  componentTranslations: Dictionary<Dictionary<string>>;
  masterDataTranslations: Dictionary<Dictionary<string>> | undefined;
  setDateTime: Date;
}

export interface AppLocale {
  previous: LocaleTranslation;
  current: LocaleTranslation;
}

type LocaleContextType = {
  supportedLocales: LocalizationCode[];
  selectedLocale?: AppLocale;
  localeSwitchFailed: boolean;
  setUserLocale: (locale: LocalizationCode) => void;
  setComponentTranslations: (
    groupKey: string
  ) => Promise<Dictionary<string> | undefined>;
  setMasterDataTranslation: (
    groupKey: string[]
  ) => Promise<Dictionary<Dictionary<string>> | undefined>;
  setPreviousAppLocale: (keyGroup: string) => void;
};

export const defaultLocaleCode = {
  id: 1,
  code: "en-GB",
  displayName: "English GB",
  Country: { id: 1, name: "UK" },
};

const defaultLocale: LocaleTranslation = {
  locale: {
    id: 1,
    code: "en-GB",
    displayName: "English GB",
    Country: { id: 1, name: "UK" },
  },
  componentTranslations: {},
  masterDataTranslations: undefined,
  setDateTime: new Date(),
};

const LocaleContext = createContext<LocaleContextType | null>(null);

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const brandingCtx = useBranding();
  const [supportedLocales, setSupportedLocales] = useState<LocalizationCode[]>(
    []
  );
  const [selectedLocale, setSelectedLocale] = useLocalStorage<
    AppLocale | undefined
  >("AppLocale", undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [kendoLocale, setKendoLocale] = useState<string>("en-US");
  const [localeSwitchFailed, setLocaleSwitchFailed] = useState<boolean>(false);

  useEffect(() => {
    const sendRequest = async () => {
      try {
        setLoading(true);
        const resp = await localeService.fetchSupportedLocales();
        setSupportedLocales(resp);
        const defaultCurrentLocale : LocaleTranslation = {
          locale: brandingCtx?.branding?.defaultLocale ?? defaultLocaleCode,
          componentTranslations: {},
          setDateTime: new Date(),
          masterDataTranslations: {}
        }
        let appLocale: AppLocale = {
          previous: defaultLocale,
          current: defaultLocale,
        };
        setSelectedLocale(appLocale);
        if (selectedLocale) {
          appLocale = selectedLocale;
        } else if (brandingCtx?.branding?.defaultLocale) {
          appLocale = {
            ...appLocale,
            current: {
              locale: brandingCtx?.branding?.defaultLocale,
              componentTranslations: {},
              masterDataTranslations: undefined,
              setDateTime: new Date(),
            },
          };
        }
        if (brandingCtx?.branding) {
          setSelectedLocale(appLocale);
        }
        loadKendoTranslations(appLocale?.current?.locale);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    sendRequest();
  }, [brandingCtx?.branding]);

  const setUserLocale = (locale: LocalizationCode) => {
    setLocaleSwitchFailed(false);
    const currentDate = new Date();
    if (
      locale.code !== selectedLocale?.current.locale.code ||
      new Date(selectedLocale.current.setDateTime).getDate() <
        currentDate.getDate()
    ) {
      setSelectedLocale({
        previous: selectedLocale?.current ?? defaultLocale,
        current: {
          locale,
          componentTranslations: {},
          masterDataTranslations: undefined,
          setDateTime: currentDate,
        },
      });
      loadKendoTranslations(locale)
    }
  };

  const loadKendoTranslations = async (locale: LocalizationCode) => {
    localeService
      .fetchKendoTranslations(locale.code)
      .then((res) => {
        load(
          likelySubtags,
          currencyData,
          weekData,
          enNumbers,
          enCurrencies,
          enCaGregorian,
          enDateFields,
          frNumbers,
          frCurrencies,
          frCaGregorian,
          frDateFields,
          nlNumbers,
          nlCurrencies,
          nlCaGregorian,
          nlDateFields,
          svNumbers,
          svCurrencies,
          svCaGregorian,
          svDateFields,
          plNumbers,
          plCurrencies,
          plCaGregorian,
          plDateFields,
          ltNumbers,
          ltCurrencies,
          ltCaGregorian,
          ltDateFields,
          lvNumbers,
          lvCurrencies,
          lvCaGregorian,
          lvDateFields,
          itNumbers,
          itCurrencies,
          itCaGregorian,
          itDateFields,
          deNumbers,
          deCurrencies,
          deCaGregorian,
          deDateFields,
          fiNumbers,
          fiCurrencies,
          fiCaGregorian,
          fiDateFields,
          etNumbers,
          etCurrencies,
          etCaGregorian,
          etDateFields,
          daNumbers,
          daCurrencies,
          daCaGregorian,
          daDateFields,
          esNumbers,
          esCurrencies,
          esCaGregorian,
          esDateFields,
          thNumbers,
          thCurrencies,
          thCaGregorian,
          thDateFields
        );
        loadMessages(res, locale.code);
        setKendoLocale(locale.code);
      })
      .catch((err) => {
        setKendoLocale("en-US");
      });
  }

  const setComponentTranslations = async (
    groupKey: string
  ): Promise<Dictionary<string> | undefined> => {
    setLocaleSwitchFailed(false);
    try {
      if (selectedLocale) {
        const resp = await localeService.fetchTranslations(
          selectedLocale.current.locale.code,
          groupKey
        );
        selectedLocale.current.componentTranslations[groupKey] = resp;
        return resp;
      }
    } catch (err) {
      if (selectedLocale?.current.locale.id !== 1) {
        setLocaleSwitchFailed(true);
      }
      throw err;
    }
  };

  const setMasterDataTranslation = async (
    keyGroups: string[]
  ): Promise<Dictionary<Dictionary<string>> | undefined> => {
    setLocaleSwitchFailed(false);
    try {
      if (selectedLocale) {
        const resp = await localeService.fetchMasterDataTranslations(
          selectedLocale.current.locale.code,
          keyGroups
        );
        selectedLocale.current.masterDataTranslations = resp;
        return resp;
      }
    } catch (err) {
      if (selectedLocale?.current.locale.id !== 1) {
        setLocaleSwitchFailed(true);
      }
      throw err;
    }
  };

  const setPreviousAppLocale = (keyGroup: string) => {
    if (
      selectedLocale &&
      selectedLocale.previous.componentTranslations[keyGroup]
    ) {
      setSelectedLocale({
        previous: defaultLocale,
        current: selectedLocale.previous,
      });
    }
  };

  return (
    <LocaleContext.Provider
      value={{
        supportedLocales,
        selectedLocale,
        setUserLocale,
        setComponentTranslations,
        setMasterDataTranslation,
        setPreviousAppLocale,
        localeSwitchFailed,
      }}
    >
      <LocalizationProvider language={kendoLocale}>
        <IntlProvider locale={kendoLocale.split("-")[0]}>
          {children}
        </IntlProvider>
      </LocalizationProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleContext;
