import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';
import { AUTH_BASE_URL, AUTH_CLIENT_ID } from '../../constants';
import useBranding from '../../hooks/useBranding';
import { AccountInfo, SilentRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { UserTempTokenRequest } from '../../types/demo/UserTempTokenRequest';
import demoService from '../../services/demo.service';
import { Button } from '@progress/kendo-react-buttons';
import msIcon from "../../assets/images/ms-icon.svg";
import useSwal from '../../hooks/useSwal';
import { SweetAlertOptions } from 'sweetalert2';
import { Dictionary } from '../../types/Dictionary';
import useLocale from '../../hooks/useLocale';
import { AxiosError } from 'axios';
import { Loader } from '@progress/kendo-react-indicators';
import useTranslation from '../../hooks/useTranslation';

const DemoLoginRegister = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const brandingCtx = useBranding();
  const swal = useSwal();
  const trans = useTranslation("DemoLoginRegister");
  const localeCtx = useLocale();
  const { instance: microsoftInstance } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["DemoLoginRegister"]) {
      trans.fetchTranslations("DemoLoginRegister");
    }
  }, [localeCtx?.selectedLocale]);

  const getFromPath = (): string => {
    const from = location.state?.from?.pathname + location.state?.from?.search || "/home";
    return from;
  }

  const getAuthAppbaseUrl = (): string => {
    let authAppBaseUrl = AUTH_BASE_URL;
    if (brandingCtx?.branding?.authAppConfiguration?.authClientAppUrl) {
      authAppBaseUrl = brandingCtx.branding.authAppConfiguration.authClientAppUrl;
    }
    return authAppBaseUrl;
  }

  const getAuthAppClientId = (): string => {
    let authAppClientId = AUTH_CLIENT_ID;
    if (brandingCtx?.branding?.authAppConfiguration?.authAppClientId) {
      authAppClientId = brandingCtx.branding.authAppConfiguration.authAppClientId;
    }
    return authAppClientId;
  }

  const getAuthAppLoginUrl = (): string => {
    const from = getFromPath();
    const originalRedirectUri = searchParams.get("originalRedirectUri");

    const authAppBaseUrl = getAuthAppbaseUrl();
    let authAppClientId = getAuthAppClientId();

    const authAppLoginUrl = `${authAppBaseUrl}/login?clientId=${authAppClientId}&originalRedirectUri=${originalRedirectUri ?? from}&isAutoMsLoginEnabled=true`;
    return authAppLoginUrl;
  }

  const loginWithMS = () => {
    const authAppLoginUrl = getAuthAppLoginUrl();
    window.location.replace(authAppLoginUrl);
  }

  const getMicrosoftGraphToken = async (account: AccountInfo) => {
    const tokenRequest: SilentRequest = {
      scopes: ["User.Read", "openid", "profile"],
      account: account,
    };

    let token: string | undefined;
    try {
      const { accessToken } = await microsoftInstance.acquireTokenSilent(tokenRequest);
      token = accessToken;
    } catch (e) {
      const { accessToken } = await microsoftInstance.acquireTokenPopup(tokenRequest);
      token = accessToken;
    }
    return token;
  };

  const getAPIAccessTempToken = async (reqPayload: UserTempTokenRequest) => {
    try {
      setLoading(true);
      let authAppClientId = AUTH_CLIENT_ID;
      if (brandingCtx?.branding?.authAppConfiguration.authAppClientId) {
        authAppClientId = brandingCtx.branding.authAppConfiguration.authAppClientId;
      }
      if (authAppClientId) {
        const statusCode = await demoService.verifyTokenAndCreateCustomer(reqPayload, authAppClientId);
        let msg = "";
        switch (statusCode) {
          case 200: msg = trans.fetchLabelKeyTranslation("CustomerCreationText", "Demo customer created successfully")
            break;
          default: msg = trans.fetchLabelKeyTranslation("SomethingWentWrongText", "Something went wrong")
            break;
        }

        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: msg,
          confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK")
        }
        swal.fire(swalOptions).then((resp) => {
          loginWithMS();
        });
      }
    }
    catch (err) {
      if (err instanceof AxiosError) {
        let msg = "";
        switch (err?.response?.status) {
          case 400: msg = trans.fetchLabelKeyTranslation("InvalidPartyProviderText", "Invalid third party provider")
            break;
          case 401: msg = trans.fetchLabelKeyTranslation("InvalidCredentialsText", "Invalid credentials")
            break;
          case 404: msg = trans.fetchLabelKeyTranslation("ClientIdMissingText", "ClientId is missing")
            break;
          case 409: msg = trans.fetchLabelKeyTranslation("EmailExistsText", "Email already registered")
            break;
          case 422: msg = trans.fetchLabelKeyTranslation("UserInsertionErrorText", "User could not be inserted")
            break;
          case 500: msg = trans.fetchLabelKeyTranslation("SomethingWentWrongText", "Something went wrong")
            break;
          default: msg = trans.fetchLabelKeyTranslation("SomethingWentWrongText", "Something went wrong")
            break;
        }

        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: msg,
          text: trans.fetchLabelKeyTranslation("PleaseTryAgainText", "Please Try Again!"),
          confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK")
        };
        swal.fire(swalOptions);
      }
    } finally {
      setLoading(false);
    }
  }

  const userThirdPartyLoginHandler = async (thirdPartyProvider: "Microsoft") => {
    try {
      let authAppClientId = AUTH_CLIENT_ID;
      if (brandingCtx?.branding?.authAppConfiguration.authAppClientId) {
        authAppClientId = brandingCtx.branding.authAppConfiguration.authAppClientId;
      }
      if (authAppClientId) {
        switch (thirdPartyProvider) {
          case "Microsoft": {
            const { account } = await microsoftInstance.loginPopup();
            if (account) {
              const token = await getMicrosoftGraphToken(account);
              const reqPayload: UserTempTokenRequest = {
                username: account.username,
                accessToken: token,
                thirdPartyProvider: "Microsoft",
              };
              await getAPIAccessTempToken(reqPayload);
            }
            break;
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className="loginBg float-left w-100 h-100">
      <div className="h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            <div className="formGroup h-100">
              <div
                className="formInrG cardEffect"
                style={{ position: "relative" }}
              >
                {brandingCtx?.branding?.logos.find(
                  (l) => l.name === "HeaderPrimaryLogo"
                )?.logoImageUrl && (
                    <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                      {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
                      <img
                        src={
                          brandingCtx?.branding?.logos.find(
                            (l) => l.name === "HeaderPrimaryLogo"
                          )?.logoImageUrl
                        }
                        alt="Logo"
                      />
                    </div>
                  )}

                {loading && (
                  <div className="d-flex align-items-center justify-content-center">
                    <Loader type={"infinite-spinner"} />
                  </div>
                )}
                
                {!loading &&
                  <div className="k-form-buttons d-flex flex-wrap justify-content-center m-t-15" style={{ width: '100%' }}>
                    <>
                      <Button
                        id="msRegisterButton"
                        type={"button"}
                        style={{
                          width: "100%",
                          textTransform: "uppercase",
                        }}
                        className={`btn bg-primary text-white fs-16 p-t-7 p-b-7 imgBtn`}
                        onClick={loginWithMS}
                      >
                        <img
                          className="p-r-5"
                          src={msIcon}
                          height="24spx"
                          width="24px"
                        />
                        {trans.fetchLabelKeyTranslation("LoginwithMicrosoftText", "Login with Microsoft")}
                      </Button>
                      <Button
                        type={"button"}
                        style={{
                          width: "100%",
                          textTransform: "uppercase",
                        }}
                        className={`btn bg-primary text-white fs-16 p-t-7 p-b-7 imgBtn`}
                        onClick={() => userThirdPartyLoginHandler("Microsoft")}
                      >
                        <img
                          className="p-r-5"
                          src={msIcon}
                          height="24spx"
                          width="24px"
                        />
                        {trans.fetchLabelKeyTranslation("RegisterwithMicrosoftText", "Register with Microsoft")}
                      </Button>
                    </>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DemoLoginRegister
