import { Dictionary } from "../Dictionary";
import { CRMSystemType } from "../master-data/CRMSystemType";

export interface CRMSystemDetail {
  id: number;
  name: string;
  crmSystemType: CRMSystemType;
  crmSystemConfig: Dictionary<string> | undefined;
}

export interface CRMJobStatus {
  batchJobState: {
    id: number;
    name: string;
    localizationKey: string
  },
  contactsSynced: number,
  lastSyncDatetime: string
}

export interface UpsertCRMSystemDetail {
  id: number;
  name: string;
  crmSystemType: string;
  crmSystemConfig: Dictionary<string> | undefined;
}

export interface ZohoCredentials{
  domain: string;
  client_id: string;
  client_secret: string;
  redirect_uri: string;
  grantToken: string;
}

export interface ZohoSessionStore{
  formConfigValues: Dictionary<string>;
  name?: string;
  systemTypes?: string[];
  redirectUri?: string;
  previousCRM?: CRMSystemDetail;
}

export const configInfo: Record<string, string[]> =
{
  "Agile": ["Url", "Email", "Api Key", "Api Token"],
  "Fresh Sales": ["Url", "Api Token"],
  "Hubspot": ["Url", "Access Token"],
  "Nimble": ["Url", "Api Token"],
  "Pipe Drive": ["Url", "Api Key"],
  "Salesforce": ["Url", "Client Id", "Client Secret", "Username", "Password", "Security Token"],
  "Workbooks": ["Url", "Api Token"],
  "Zendesk": ["Url", "Api Token"],
  "Connectwise": ["Url", "Company Name", "Client Id", "Private Api Token", "Public Api Token"],
  "Dynamics 365": ["Url", "Client Id", "Tenant Id", "Client Secret"],
  "Sugar CRM": ["Url", "Username", "Password"],
  "Zoho": ["Url", "Client Id", "Client Secret", "Refresh Token", "Domain Suffix"]
}

export const maskedFields = new Set()
maskedFields.add("apitoken")
maskedFields.add("accesstoken")
maskedFields.add("clientsecret")
maskedFields.add("privateapitoken")
maskedFields.add("password")