import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useEffect, useRef, useState } from "react";
import { Loader } from "@progress/kendo-react-indicators";
import CustomInput from "../../../components/custom/form/CustomInput";
import useLocale from "../../../hooks/useLocale";
import { Dictionary } from "../../../types/Dictionary";
import {
  InsertScorecardQuestionGroup,
  Scorecard,
  ScorecardQuestionGroup,
  UpdateScorecardQuestionGroup,
} from "../../../types/scorecard";
import { axiosWithAuth } from "../../../utils/customAxios";
import useSwal from "../../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import scorecardService from "../../../services/scorecard.service";
import useTranslation from "../../../hooks/useTranslation";

interface IUpsertQuestionDialogBox {
  toggleDialog: (questionGroup: ScorecardQuestionGroup | undefined) => void;
  questionGroup?: ScorecardQuestionGroup;
  scorecardId?: String;
  upsertSC: () => void;
  totalQuestionGroups: number;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  scorecard: Scorecard;
}

const UpsertQuestionGroupDialog: React.FC<IUpsertQuestionDialogBox> = ({
  toggleDialog,
  questionGroup,
  scorecardId,
  upsertSC,
  totalQuestionGroups,
  setSelected,
  scorecard
}) => {
  const trans = useTranslation("UpsertQuestionGroupDialogBox");
  const localeCtx = useLocale();
  const swal = useSwal();
  const formRef = useRef<any>();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    trans.fetchTranslations("UpsertQuestionGroupDialogBox");
  }, [localeCtx?.selectedLocale]);

  const nameValidator = (value: string) => {
    if (!value) {
      return `${
        trans.fetchLabelKeyTranslation(
              "SCQuestionGrpNameValidationEmptyMsg",
              "Scorecard question group name cannot be empty" 
            )
      }`;
    }
    if (value.length > 100) {
      return `${
        trans.fetchLabelKeyTranslation(
              "SCQuestionGrpNameValidationLengthMsg",
              "Scorecard question group name length cannot be more than 100!"
            )
      }`;
    }

    return "";
  };

  const archiveSweetAlert = async() =>{
    toggleDialog(undefined);
    const swalOptions: SweetAlertOptions<any, any> = {
      icon: "info",
      title: `${
        trans.fetchLabelKeyTranslation("SwtAltAddFailedTitle","Scorecard cannot be Updated")
      }`,
      text: `${
        trans.fetchLabelKeyTranslation("SwtAltAddFailedText","You can archive the Scorecard")
      }`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:  `${
        trans.fetchLabelKeyTranslation("SwtAltArchiveCfnBtnText","Yes, Archive it!")
      }`,
      cancelButtonText: `${
        trans.fetchLabelKeyTranslation("SwtAltCancelText","Cancel")
      }`
    };
    const resp = await swal.fire(swalOptions);
    if (resp.isConfirmed) {
      try{
        const updatedScorecard: Scorecard = scorecard;
        updatedScorecard.isArchived = true;
        await axiosWithAuth
        .put(`/scorecard/${scorecard.id}`, updatedScorecard)
        .then((response) => {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "success",
            title: `${
              trans.fetchLabelKeyTranslation("SwtAltArchiveSuccessTitle","Scorecard Archived")
            }`,
            text: `${
              trans.fetchLabelKeyTranslation("SwtAltArchiveSuccessText","Scorecard has been archived successfully") 
            }`,
          };
          swal.fire(swalOptions);
        })
      }catch(err){
        // Archiving Failed
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: `${
            trans.fetchLabelKeyTranslation("SwtAltErrorTitle","Error")
          }`,
          text: `${
            trans.fetchLabelKeyTranslation("SwtAltArchiveErrorText","Could not archive the Scorecard")
          }`,
        };
        swal.fire(swalOptions);            
      }
    }   
  }

  const addHandler = async (newQuestionGroup: InsertScorecardQuestionGroup) => {
    setLoading(true)
    const isUsed: boolean = await scorecardService.fetchScorecardUsage(Number(scorecardId));
    if (isUsed) {
      // If user wants to archive
      await archiveSweetAlert()
    }else{
      // Add
      await axiosWithAuth
      .post(`scorecard/${scorecardId}/scorecardquestiongroup`, newQuestionGroup)
      .then((response) => {
        setSelected(totalQuestionGroups);
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: `${
            trans.fetchLabelKeyTranslation(
                  "SwtAltQuestionGrpAddedTitle",
                  "Scorecard Question Group added." 
                )
          }`,
          confirmButtonText: `${
            trans.fetchLabelKeyTranslation("OKText", "OK")
          }`,
        };
        swal.fire(swalOptions);
      })
      .catch((err)=>{
        // Adding Failed.
        if(err.response.status === 400){
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "warning",
            title: `${trans.fetchLabelKeyTranslation("SwtAltFailedTxt","Something went wrong!")
            }`,
            text: `${trans.fetchLabelKeyTranslation(
                    "InvalidSCGroupNameText",
                    "Scorecard can not have 2 question Group with same name."
                  )
            }`,
            confirmButtonText: `${trans.fetchLabelKeyTranslation("OKText", "OK")
            }`,
          };
          swal.fire(swalOptions);
        }
        else{
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "error",
            title: `${
              trans.fetchLabelKeyTranslation("SwtAltErrorTitle","Error")
            }`,
            text:`${
              trans.fetchLabelKeyTranslation("SwtAltAddErrorText", "Could not add the Scorecard Question Group")
            }` ,
          };
          swal.fire(swalOptions);         
        }
      })
      .finally(()=>{
        setLoading(false)
        toggleDialog(undefined);
      })  
    }
    await upsertSC();
  };

  const editHandler = async (
    newQuestionGroup: UpdateScorecardQuestionGroup
  ) => {
    setLoading(true);
    const isUsed: boolean = await scorecardService.fetchScorecardUsage(Number(scorecardId));
    if (isUsed) {
      // If user wants to archive
      await archiveSweetAlert(); 
    }
    else{
      await axiosWithAuth
      .put(
        `scorecard/${scorecardId}/scorecardquestiongroup/${questionGroup?.id}`,
        newQuestionGroup
      )
      .then((response) => {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: `${
            trans.fetchLabelKeyTranslation(
                  "SwtAltQuestionGrpUpdatedTitle",
                  "Scorecard question Group Updated" 
                )
          }`,
          text: `${
            trans.fetchLabelKeyTranslation(
                  "SwtAltQuestionGrpUpdatedText",
                  "Scorecard question group has been updated" 
                )
          }`,
          confirmButtonText: `${
            trans.fetchLabelKeyTranslation("OKText", "OK")
          }`,
        };
        swal.fire(swalOptions);
      })
      .catch((err)=>{
        // Updation Failed.
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: `${
            trans.fetchLabelKeyTranslation("SwtAltErrorTitle","Error")
          }`,
          text: `${
            trans.fetchLabelKeyTranslation("SwtAltUpdateErrorText","Could not update the Scorecard Question Group")
          }`,
        };
        swal.fire(swalOptions);         
      })
      .finally(()=>{
        setLoading(false);
        toggleDialog(undefined);
      })        
    }
    await upsertSC();
  };

  const handleUpsert = async () => {
    const form = formRef.current as Form;
    const insertNewquestionGroup: InsertScorecardQuestionGroup = {
      scorecardQuestionGroupName: form.values.questionGroupName,
    };
    const updateQuestionGroup: UpdateScorecardQuestionGroup = {
      id: questionGroup?.id ?? 0,
      scorecardQuestionGroupName: form.values.questionGroupName,
      description: questionGroup?.description,
      questions: questionGroup?.questions,
    };
    questionGroup
      ? await editHandler(updateQuestionGroup)
      : await addHandler(insertNewquestionGroup);
  };

  return (
    <Form
      ref={formRef}
      initialValues={{
        questionGroupName: questionGroup?.scorecardQuestionGroupName,
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              questionGroup
                ? `${
                    trans.fetchLabelKeyTranslation(
                          "TitleEditQuestionGroup",
                          "Edit Question Group" 
                        )
                  }`
                : `${
                    trans.fetchLabelKeyTranslation(
                          "TitleAddQuestionGroup",
                          "Add Question Group" 
                        )
                  }`
            }
            onClose={() => toggleDialog(undefined)}
            closeIcon={!loading}
          >
            <div className="formAdd" style={{ marginBottom: "0px", minWidth: "250px" }}>
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">
                  {`${
                    trans.fetchLabelKeyTranslation(
                          "FieldQuesGroupText",
                          "Group Name" 
                        )
                  }`}
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="questionGroupName"
                      name="questionGroupName"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("questionGroupName")}
                      placeholder={`${
                        trans.fetchLabelKeyTranslation(
                              "FieldQuesGrpTextPlaceholder",
                              "Enter question group name..." 
                            )
                      }`}
                      component={CustomInput}
                      validator={nameValidator}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button
                className={`btn bg-black-5`}
                onClick={() => toggleDialog(undefined)}
                disabled={loading}
                >
                {
                  trans.fetchLabelKeyTranslation("SwtAltCancelText", "Cancel")
                }
              </Button>
              <Button
                className={`btn bg-primary text-white ${
                  !formRenderProps.valid ? "disabledBtn" : ""
                }`}
                disabled={!formRenderProps.valid || loading}
                onClick={handleUpsert}
              >
                {
                  trans.fetchLabelKeyTranslation("SaveBtnText", "Save")
                }
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default UpsertQuestionGroupDialog;
