import { Tooltip } from "@progress/kendo-react-tooltip";
import { useEffect, useState } from "react";
import { RecordingTrackSegment, TrackSegment } from "../../types/player-track";
import { processTrackSegments } from "../../utils/trackInsightUtils";
import useTranslation from "../../hooks/useTranslation";
import useLocale from "../../hooks/useLocale";
import { removeAllSpaces } from "../../utils/stringUtils";

interface RecordingTrackProps {
  trackLabel: string;
  duration: number;
  segments?: RecordingTrackSegment[];
  error?: string;
  onTrackSpanPlay: (time: number) => void;
  isAIAnalysed?: boolean;
  isDiscarded?: boolean;
}

export type ShortLabel = {
  label: string;
  cssLabel: string;
};

const getUniqueTrackSegmentLabels = (
  trackSegments: TrackSegment[]
): ShortLabel[] => {
  const result: ShortLabel[] = [];
  const map = new Map<string, ShortLabel>();
  trackSegments.forEach((t) => {
    if (!map.get(t.label)) {
      map.set(t.label, t);
    }
  });

  map.forEach((l) => result.push(l));

  return result;
};

const RecordingTrack: React.FC<RecordingTrackProps> = ({
  trackLabel,
  duration,
  segments,
  error,
  onTrackSpanPlay,
  isAIAnalysed,
  isDiscarded
}) => {
  const [trackSegments, setTrackSegments] = useState<
    TrackSegment[] | undefined
  >();
  const localeCtx = useLocale();
  const trans = useTranslation("RecordingTrack");
  useEffect(() => {
    if (!error && segments) {
      const processedSegments = processTrackSegments(segments, duration);
      setTrackSegments(processedSegments);
    }
  }, [error, segments, duration]);

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["RecordingTrack"]) {
      trans.fetchTranslations("RecordingTrack");
    }
  }, [localeCtx?.selectedLocale]);

  const trackSpanClickHandler = (
    e: React.MouseEvent<HTMLSpanElement>,
    start: number
  ) => {
    onTrackSpanPlay(start / 100);
  };

  const SegmentLabel = (l: ShortLabel) => {
    let translatedLabel = "";
    if (l.label.toLowerCase().startsWith("participant")) {
      const translatedParticipant = trans.fetchLabelKeyTranslation("ParticipantText", "participant");
      translatedLabel = l.label.toLowerCase().startsWith("participant") ? l.label.replace("participant", translatedParticipant) : l.label;
    }
    else {
      const trimmedLabel = removeAllSpaces(l.label);
      translatedLabel = trans.fetchLabelKeyTranslation(trimmedLabel, l.label);
    }

    return (
      <li key={l.label}>
        <span className={`legends-bg ${l.cssLabel}`}></span>{" "}
        {translatedLabel}
      </li>
    );
  }
  return (
    <div className="row">
      <div className="col-md-12 d-flex">
        <div className="track m-b-7" style={{ width: "100%" }}>
          <div className="track-single">
            {!isDiscarded && isAIAnalysed &&
              trackSegments &&
              trackSegments.map((t) => {
                return (
                  <span
                    key={t.id}
                    className={`trackCursor ${t.cssLabel}`}
                    style={{ width: `${t.width}%`, left: `${t.left}%` }}
                    onClick={(e) => trackSpanClickHandler(e, t.left * duration)}
                  ></span>
                );
              })}
          </div>
          <div className="legends d-flex align-items-center">
            <span className="float-left fs-12 font-weight-bold pr-3">
              <div className="d-flex">
                <span>{trackLabel}</span>
              </div>
            </span>
            <ul className="list-unstyled d-flex mb-0">
              {trackSegments &&
                getUniqueTrackSegmentLabels(trackSegments).map((l: ShortLabel) => (
                  <SegmentLabel {...l}/>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordingTrack;
