import React, { useEffect, useState } from "react";
import "./UserProfile.scss";
import {
  PlatformUserDetail,
  RecordingPolicy,
  TinyUser,
} from "../../../../../types/user";
import { LicenseCount } from "../../../../../types/customer/LicenseCount";
import { AccessPermissionEnum } from "../../../../../enums/accessPermissionEnum";
import { Button } from "@progress/kendo-react-buttons";
import { Dictionary } from "../../../../../types/Dictionary";
import useLocale from "../../../../../hooks/useLocale";
import useAuth from "../../../../../hooks/useAuth";
import { CallSubsetType } from "../../../../../types/CallSubsetType/CallSubsetType";
import useMasterData from "../../../../../hooks/useMasterData";
import useSwal from "../../../../../hooks/useSwal";
import RecordingPolicyDialog from "./RecordingPolicyDialog";
import useTranslation from "../../../../../hooks/useTranslation";

const rpLocaleKeyName: { [key: string]: string } = {
  "Record Received Calls": "RecordReceivedCallsName",
  "Do Not Record Received Calls": "DoNotRecordReceivedCallsName",
  "Record Dialled Calls": "RecordDialledCallsName",
  "Do Not Record Dialled Calls": "DoNotRecordDialledCallsName",
  "Record External Calls": "RecordExternalCallsName",
  "Do Not Record External Calls": "DoNotRecordExternalCallsName",
  "Record Internal Calls": "RecordInternalCallsName",
  "Do Not Record Internal Calls": "DoNotRecordInternalCallsName",
  "Record Received Calls From External Numbers only": "RRCFENOName",
  "Do Not Record Received Calls From External Numbers only": "DNRRCFENOName",
  "Record Received Calls From Internal numbers only": "RRCFINOName",
  "Do Not Record Received Calls From Internal numbers only": "DNRRCFINOName",
  "Record Dialled Calls to External Numbers only": "RDCTENOName",
  "Do Not Record Dialled Calls to External Numbers only": "DNRDCTENOName",
  "Record Dialled Calls to Internal Numbers only": "RDCTINOName",
  "Do Not Record Dialled Calls to Internal Numbers only": "DNRDCTINOName",
  "Record All Calls": "RecordAllCallsName",
  "Do Not Record Any Call": "DoNotRecordAnyCallName",
};

interface IDeviceDetailsProps {
  details: PlatformUserDetail;
  updatedDeviceDetails: (details: PlatformUserDetail) => void;
  isUserInNetworkOfLoggedInUser?: TinyUser;
}

const DeviceDetails: React.FC<IDeviceDetailsProps> = ({
  details,
  updatedDeviceDetails,
  isUserInNetworkOfLoggedInUser,
}) => {
  const trans = useTranslation("DeviceDetail")
  const auth = useAuth();
  const localeCtx = useLocale();
  const Swal = useSwal();
  const masterData = useMasterData();
  const [licenseDialogVisible, setLicenseDialogVisible] =
    useState<boolean>(false);
  const [recPolicyDialogVisible, setRecPolicyDialogVisible] =
    useState<boolean>(false);
  const [platformDetails, setPlatformDetails] =
    useState<PlatformUserDetail>(details);
  const [recordingPolicy, setRecordingPolicy] = useState<RecordingPolicy>(
    details.recordingPolicy
  );
  const [dialedChecked, setDialedChecked] = useState<boolean>(false);
  const [receivedChecked, setReceivedChecked] = useState<boolean>(false);
  const [internalChecked, setInternalChecked] = useState<boolean>(false);
  const [externalChecked, setExternalChecked] = useState<boolean>(false);
  const [excludeCallSubset, setExcludeCallSubset] = useState<boolean>(
    details.recordingPolicy === null
      ? false
      : !details.recordingPolicy.isIncludedForRecording
  );
  const [callSubset, setCallSubset] = useState<CallSubsetType | undefined>(
    details.recordingPolicy == null
      ? masterData?.data?.callSubsetTypes.find((e) => e.name === "All Calls")
      : details.recordingPolicy.callSubset
  );

  useEffect(() => {
    setPlatformDetails(details);
  }, [details]);

  useEffect(() => {
    updatedDeviceDetails(platformDetails);
    setRecordingPolicyCheckedBoxesValue();
  }, [platformDetails]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["DeviceDetail"]
    ) {
      trans.fetchTranslations("DeviceDetail");
    }
  }, [localeCtx?.selectedLocale]);

  const setNewRecordingPolicy = (
    dialedCheckedVal: boolean,
    receivedCheckedVal: boolean,
    internalCheckedVal: boolean,
    externalCheckedVal: boolean,
    excludeCallSubsetVal: boolean
  ) => {
    let subset = undefined;
    let callSubSets = masterData?.data?.callSubsetTypes?.filter((e) => {
      if (dialedCheckedVal !== receivedCheckedVal) {
        if (
          dialedCheckedVal &&
          e.rules.findIndex(
            (r) => r.ruleField.name === "CallDirection" && r.value === "Dialled"
          ) === -1
        ) {
          return false;
        }
        if (
          receivedCheckedVal &&
          e.rules.findIndex(
            (r) =>
              r.ruleField.name === "CallDirection" && r.value === "Received"
          ) === -1
        ) {
          return false;
        }
      }
      if (internalCheckedVal !== externalCheckedVal) {
        if (
          internalCheckedVal &&
          e.rules.findIndex(
            (r) => r.ruleField.name === "CallType" && r.value === "Internal"
          ) === -1
        ) {
          return false;
        }
        if (
          externalCheckedVal &&
          e.rules.findIndex(
            (r) => r.ruleField.name === "CallType" && r.value === "External"
          ) === -1
        ) {
          return false;
        }
      }
      return true;
    });

    if (
      dialedCheckedVal &&
      receivedCheckedVal &&
      internalCheckedVal &&
      externalCheckedVal
    ) {
      subset = masterData?.data?.callSubsetTypes.find(
        (e) => e.name === "All Calls"
      );
    } else if (
      !dialedCheckedVal &&
      !receivedCheckedVal &&
      !internalCheckedVal &&
      !externalCheckedVal
    ) {
      subset = undefined;
    } else if (
      callSubSets !== undefined &&
      callSubSets !== null &&
      callSubSets.length > 0
    ) {
      subset = callSubSets[0];
    } else {
      subset = undefined;
    }

    let newPolicy: RecordingPolicy = {
      id:
        platformDetails.recordingPolicy === null
          ? 0
          : platformDetails.recordingPolicy.id,
      isIncludedForRecording: !excludeCallSubsetVal,
      callSubset:
        subset ??
        masterData?.data?.callSubsetTypes.find((e) => e.name === "All Calls"),
    };

    setCallSubset(subset);
    setRecordingPolicy(newPolicy);
  };

  const setRecordingPolicyCheckedBoxesValue = () => {
    var _dialed = true;
    var _received = true;
    var _internal = true;
    var _external = true;
    if (
      platformDetails.recordingPolicy === null ||
      platformDetails.recordingPolicy.callSubset?.name === "All Calls"
    ) {
      _dialed = true;
      _received = true;
      _internal = true;
      _external = true;
    } else {
      _dialed = false;
      _received = false;
      _internal = false;
      _external = false;
      var _rules = platformDetails.recordingPolicy.callSubset?.rules;
      if (
        _rules &&
        _rules.findIndex(
          (r) => r.ruleField.name === "CallDirection" && r.value === "Dialled"
        ) !== -1
      ) {
        _dialed = true;
      }
      if (
        _rules &&
        _rules.findIndex(
          (r) => r.ruleField.name === "CallDirection" && r.value === "Received"
        ) !== -1
      ) {
        _received = true;
      }
      if (
        _rules &&
        _rules.findIndex(
          (r) => r.ruleField.name === "CallType" && r.value === "Internal"
        ) !== -1
      ) {
        _internal = true;
      }
      if (
        _rules &&
        _rules.findIndex(
          (r) => r.ruleField.name === "CallType" && r.value === "External"
        ) !== -1
      ) {
        _external = true;
      }
      if (_dialed === _received) {
        _dialed = true;
        _received = true;
      }
      if (_internal === _external) {
        _internal = true;
        _external = true;
      }
    }
    setDialedChecked(_dialed);
    setReceivedChecked(_received);
    setInternalChecked(_internal);
    setExternalChecked(_external);
  };

  const toggleRecPolicyDialog = () => {
    setRecPolicyDialogVisible(!recPolicyDialogVisible);
  };

  const updateRecordingPolicy = () => {
    setPlatformDetails({
      ...platformDetails,
      recordingPolicy: recordingPolicy,
    });
    toggleRecPolicyDialog();
  };

  const getRecordingPolicyName = (
    subsetType: CallSubsetType | undefined,
    _excludeCallSubsetVal: boolean
  ) => {
    if (subsetType === undefined) {
      return `${
        trans.fetchLabelKeyTranslation(
              "NotAValidSelectionMsg",
              "Not A Valid Selection"
            )
      }`;
    }
    let _policyName = `Record ${subsetType?.name}`;
    if (_excludeCallSubsetVal) {
      if (subsetType?.name === "All Calls") {
        _policyName = `Do Not Record Any Call`;
      } else {
        _policyName = `Do Not ${_policyName}`;
      }
    }
    return `${
      trans.fetchLabelKeyTranslation(rpLocaleKeyName[_policyName], _policyName)
    }`;
  };

  const PlatformName = () => {
    const platformKey = masterData?.data?.platforms.find((p) => p.name === platformDetails.customerPlatformName)?.localizationKey ?? platformDetails.customerPlatformName;
    const platform = masterData?.fetchMasterDataKeyTranslation("Platform", platformKey, platformDetails.customerPlatformName);
    return <>{platform}</>
  }

  return (
    <div className="dvcListCol">
      <div
        className="dvcListHead border-bottom-solid border-w-1 border-black-1 p-b-10 m-b-7"
        style={{ minHeight: "30px" }}
      >
        <div className="dvcHeadTitle float-left fs-15 font-weight-semi">
          <PlatformName />
        </div>
      </div>
      <div className="dvcListBody">
        <div className="row">
          <div className="col-md-6">
            <div className="dvcPointLg">
              <div className="dvcPointLgRow">
                <div className="dvcPointLgCol point-p">
                  {trans.fetchLabelKeyTranslation("DeviceIdTitle", "Device ID")}
                </div>
                <div className="dvcPointLgCol point-dot">:</div>
                <div className="dvcPointLgCol point-h font-weight-semi wordBreakAll">
                  {platformDetails.deviceIdentifier}
                </div>
              </div>
              <div className="dvcPointLgRow">
                <div className="dvcPointLgCol point-p">
                  {trans.fetchLabelKeyTranslation(
                        "RecordingPolicyTitle",
                        "Recording Policy"
                      )}
                </div>
                <div className="dvcPointLgCol point-dot">:</div>
                <div className="dvcPointLgCol point-h font-weight-semi">
                  {details?.recordingPolicy
                    ? getRecordingPolicyName(
                        details?.recordingPolicy?.callSubset,
                        !details?.recordingPolicy?.isIncludedForRecording
                      )
                    : getRecordingPolicyName(
                        masterData?.data?.callSubsetTypes.find(
                          (e) => e.name === "All Calls"
                        ),
                        false
                      )}
                  <Button
                    className={`btn btn-link p-l-2 ${
                      !auth?.checkUserAccess(
                        AccessPermissionEnum.ManageUsersAndGroups
                      ) ||
                      !isUserInNetworkOfLoggedInUser ||
                      masterData?.telepoDisableConfig?.usermanagement.users
                        .profile.disableRecordingPolicy
                        ? "disabledIconBtn"
                        : "iconBtn"
                    }`}
                    onClick={toggleRecPolicyDialog}
                    disabled={
                      !auth?.checkUserAccess(
                        AccessPermissionEnum.ManageUsersAndGroups
                      ) ||
                      !isUserInNetworkOfLoggedInUser ||
                      masterData?.telepoDisableConfig?.usermanagement.users
                        .profile.disableRecordingPolicy
                    }
                  >
                    <span className="linkLbl-i p-l-1">
                      <i className="bi bi-pencil"></i>
                    </span>
                  </Button>
                  {recPolicyDialogVisible && (
                    <RecordingPolicyDialog
                      fetchLabelKeyTranslation={trans.fetchLabelKeyTranslation}
                      toggleRecPolicyDialog={toggleRecPolicyDialog}
                      setNewRecordingPolicy={setNewRecordingPolicy}
                      excludeCallSubset={excludeCallSubset}
                      callSubset={callSubset}
                      internalChecked={internalChecked}
                      receivedChecked={receivedChecked}
                      dialedChecked={dialedChecked}
                      externalChecked={externalChecked}
                      updateRecordingPolicy={updateRecordingPolicy}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {details.platform === "Broadworks" && (
              <div className="dvcPointLg pointSm">
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("UserIdTitle","User Id")
                    }
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.bwUserId
                      ? platformDetails.deviceDetails.bwUserId
                      : ""}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("DDITitle", "DDI")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.ddi
                      ? platformDetails.deviceDetails.ddi
                      : ""}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("NameTitle", "Name")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {`${
                      platformDetails.deviceDetails.bwFirstName
                        ? platformDetails.deviceDetails.bwFirstName
                        : ""
                    } 
                        ${
                          platformDetails.deviceDetails.bwLastName
                            ? platformDetails.deviceDetails.bwLastName
                            : ""
                        } `.trim()}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("EmailTitle", "Email")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.email
                      ? platformDetails.deviceDetails.email
                      : ""}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation(
                          "ExtensionNumberTitle",
                          "Extension Number"
                        )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.extensionNo
                      ? platformDetails.deviceDetails.extensionNo
                      : ""}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation(
                          "RecordingModeTitle",
                          "Recording Mode"
                        )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.recordingMode
                      ? platformDetails.deviceDetails.recordingMode
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation(
                          "PauseResumeNotifyMethodTitle",
                          "Pause Resume Notify Method"
                        )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.pauseResumeNotifyMethod
                      ? platformDetails.deviceDetails.pauseResumeNotifyMethod
                      : ""}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation(
                          "RecordVoiceMessaging",
                          "Record Voice Messaging"
                        )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.recordVoiceMessaging !=
                    undefined
                      ? platformDetails.deviceDetails.recordVoiceMessaging.toString()
                      : ""}
                  </div>
                </div>
              </div>
            )}
            {details.platform === "SBC-MsTeams" && (
              <div className="dvcPointLg pointSm">
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("EmailTitle","Email")
                    }
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.microsoftEmail
                      ? platformDetails.deviceDetails.microsoftEmail
                      : ""}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("NameTitle", "Name")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.givenName
                      ? platformDetails.deviceDetails.givenName
                      : ""}
                  </div>
                </div>
              </div>
            )}

            {details.platform === "SBC-Others" && (
              <div className="dvcPointLg pointSm">
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("NameTitle", "Name")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.sbcOthersName
                      ? platformDetails.deviceDetails.sbcOthersName
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("TypeTitle", "Type")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.sbcOthersType
                      ? platformDetails.deviceDetails.sbcOthersType
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("DDITitle", "DDI")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.sbcOthersDDI
                      ? platformDetails.deviceDetails.sbcOthersDDI
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("LocationTitle", "Location")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.sbcOthersLocation
                      ? platformDetails.deviceDetails.sbcOthersLocation
                      : ""}
                  </div>
                </div>
              </div>
            )}

            {details.platform === "Telepo" && (
              <div className="dvcPointLg pointSm">
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("UserIdTitle","User Id")
                    }
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.telepoUserId
                      ? platformDetails.deviceDetails.telepoUserId
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation(
                          "TelepoGivenNameTitle",
                          "Given Name"
                        )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.givenName
                      ? platformDetails.deviceDetails.givenName
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation(
                          "TelepoFamilyNameTitle",
                          "Family Name"
                        )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.familyName
                      ? platformDetails.deviceDetails.familyName
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("EmailTitle", "Email")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.telepoPlatformEmail
                      ? platformDetails.deviceDetails.telepoPlatformEmail
                      : ""}
                  </div>
                </div>
              </div>
            )}
            {details.platform === "MsTeams" && (
              <div className="dvcPointLg pointSm">
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation("UserIdTitle","User Id")
                    }
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.msTeamsUserGuid
                      ? platformDetails.deviceDetails.msTeamsUserGuid
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation(
                          "MicrosoftUserEmail",
                          "User Email"
                        )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.msTeamsEmail
                      ? platformDetails.deviceDetails.msTeamsEmail
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation(
                          "MicrosoftUserGivenName",
                          "Given Name"
                        )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.msTeamsGivenName
                      ? platformDetails.deviceDetails.msTeamsGivenName
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {trans.fetchLabelKeyTranslation(
                          "MicrosoftUserSurname",
                          "Surname"
                        )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.msTeamsSurname
                      ? platformDetails.deviceDetails.msTeamsSurname
                      : ""}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceDetails;
