import {  axiosWithAuth } from "../utils/customAxios";
import { ReasonCodeInstance } from "../types/call/CallResultReasonCode";
import { BooleanSuccess } from "../types/success/BooleanSuccess";

const reasonCodeService = {

  getAllReasonCodes:async(): Promise<ReasonCodeInstance[]> =>{
    try{
      const response=await axiosWithAuth.get(`ReasonCode`);
      const fetchedReasonCodes:ReasonCodeInstance[]=response.data;
      return fetchedReasonCodes;
    }catch(err){
      throw err;
    }
  },
  
  getReasonCodesForOutcome:async(callResultId:number): Promise<ReasonCodeInstance[]> =>{
    try{
      const response=await axiosWithAuth.get(
        `CallResult/${callResultId}/mapping`
      );
      const fetchedReasonCodesForOutcome:ReasonCodeInstance[]=response.data;
      return fetchedReasonCodesForOutcome;
    }catch(err){
      throw err;
    }
  },

  createReasonCode:async(reasonCodeData:ReasonCodeInstance):Promise<ReasonCodeInstance>=>{
    try{
      const response = await axiosWithAuth.post(`ReasonCode`, reasonCodeData)
      const createdReasonCode:ReasonCodeInstance=response.data;
      return createdReasonCode;
    }catch(err){
      throw err;
    }
  },

  updateReasonCode:async(newReasonCodeData:ReasonCodeInstance,reasonCodeId:number): Promise<ReasonCodeInstance> =>{
    try{
      const response = await axiosWithAuth.put(`ReasonCode/${reasonCodeId}`,newReasonCodeData);
      const updatedReasonCode:ReasonCodeInstance=response.data;
      return updatedReasonCode;
    }catch(err){
      throw err;
    }
  },

  deleteReasonCode:async(reasonCodeId:number) =>{
    try{
      await axiosWithAuth.delete(`ReasonCode/${reasonCodeId}`);
    }catch(err){
      throw err;
    }
  },

  fetchReasonCodeUsage: async (reasonCodeId: number): Promise<boolean> => {
    try {
      const response = await axiosWithAuth.get(`/ReasonCode/${reasonCodeId}/usage`);
      const isUsed: BooleanSuccess = response.data;
      return isUsed.state;
    } catch (err) {
      throw err;
    }
  }
};

export default reasonCodeService;
