import { align } from "@progress/kendo-drawing";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useRef, useState } from "react";
import useLocale from "../../hooks/useLocale";
import useLocalStorage from "../../hooks/useLocalStorage";
import { SweetAlertOptions } from "sweetalert2";
import { Dictionary } from "../../types/Dictionary";
import useSwal from "../../hooks/useSwal";
import SettingService from "../../services/setting.service";
import useTranslation from "../../hooks/useTranslation";
import useAuth from "../../hooks/useAuth";

const Footer: React.FC = () => {
  const trans = useTranslation("Footer");
  const localeCtx = useLocale();
  const auth = useAuth();
  const [userBehaviourTracking, setUserBehaviourTracking] = useLocalStorage<
    string | undefined
  >("UserBehaviourTracking", undefined);

  const [productVersion, setProductVersion] = useState<string>()

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["Footer"]) {
      trans.fetchTranslations("Footer");
    }
  }, [localeCtx?.selectedLocale]);

  const AddTrackingScript = () => {
    var userTrackingScriptTag = document.createElement("script");
    userTrackingScriptTag.setAttribute(
      "src",
      "https://cdn.pagesense.io/js/tollring/fd30445c804849179de522922709acda.js"
    );
    document.head.appendChild(userTrackingScriptTag);
  };

  const allowUserTracking = () => {
    setUserBehaviourTracking("Allow");
    AddTrackingScript();
  };

  const denyUserTracking = () => {
    setUserBehaviourTracking("Deny");
  };

  useEffect(() => {
    if (userBehaviourTracking === "Allow") {
      AddTrackingScript();
    }
  }, []);

  useEffect(() => {
    const getProdVer = async () => {
      const prodVer = await SettingService.getProductVersion();

      setProductVersion(prodVer);
    };
    
    getProdVer();
  },[]);

  return (
    <div>
      <table
        className="footerContent text-center"
        style={{
          background: "#fff",
          width: "100%",
        }}
      >
        <tbody>
        {userBehaviourTracking === undefined && (
          <tr>
            <td style={{ width: "100%" }}>
              <span style={{ padding: "0 10px 0 0" }}>
                {trans.fetchLabelKeyTranslation("ConsentText",
                    "Our website uses cookies to give you the best and most relevant experience.By clicking on accept, you give your consent to the use of cookies as per our privacy policy."
                  )}
              </span>
              <span>
                <Button
                  className="btn bg-primary text-white m-r-10"
                  onClick={allowUserTracking}
                >
                  { trans.fetchLabelKeyTranslation("AcceptButtonText", "Accept")}
                </Button>
                <Button className="btn bg-black-5" onClick={denyUserTracking}>
                  {trans.fetchLabelKeyTranslation("DenyButtonText", "Deny")}
                </Button>
              </span>
            </td>
          </tr>
        )}
        <tr>
          <td className="text-right text-muted small">
            <span> {trans.fetchLabelKeyTranslation("Version", "Version")} </span>
          {productVersion}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Footer;
