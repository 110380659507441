import erro404img from "../../assets/images/404-error.gif";
import { Link } from "react-router-dom";

const NotFoundError = () => {
  console.log("404 called...");
  return (
    <div className="errorContainer float-left w-100 text-center">
      <div className="errorImg">
        <img className="w-30" src={erro404img} alt={"Error! Not Found..."} />
      </div>
      <div className="errorTxt" style={{ position: "relative", top: "-25px" }}>
        <div className="errorTxt-h fs-30 font-weight-semi p-b-20">
          Ooops page not found
        </div>
        <div className="errorTxt-p fs-16 p-b-20">
          The link you followed is probably broken, or the page has been removed
        </div>
        <div className="errorBtn">
          <Link
            className="fs-15 bg-primary text-white padding-6 p-l-15 p-r-15"
            replace
            to="/home"
          >
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundError;
