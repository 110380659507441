import { Popup } from "@progress/kendo-react-popup";
import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DrawerOverlay from "./DrawerOverlay";
import "./Header.css";
import { getUserInitials } from "../../utils/profileUtils";
import Notifications from "./Notifications";
import LanguageSelector from "./LanguageSelector";
import useAuth from "../../hooks/useAuth";
import useBranding from "../../hooks/useBranding";
import { Dictionary } from "../../types/Dictionary";
import { SweetAlertOptions } from "sweetalert2";
import useLocale from "../../hooks/useLocale";
import { AUTH_BASE_URL, AUTH_CLIENT_ID } from "../../constants";
import { appUrl } from "../..";
import { AccessPermissionEnum, SessionStorageKeys } from "../../enums";
import useSwal from "../../hooks/useSwal";
import HelpGuide from "../../pages/helpguide/HelpGuide";
import useCustomNavigation from "../../hooks/useCustomNavigation";
import useTranslation from "../../hooks/useTranslation";
import { LocalStorageKeys } from "../../enums/localStorageKeys";

export type UserPermissionsType = {
  recording: boolean;
  insights: boolean;
  users: boolean;
  groups: boolean;
};

interface IHeaderProps { }

const navItems = [
  {
    id: 0,
    label: "Home",
    labelKey: "HomeTitle",
    route: "/home",
    active: false,
    popup: false,
  },
  {
    id: 1,
    label: "Search",
    labelKey: "SearchTitle",
    route: "/search",
    active: false,
    popup: false,
  },
  {
    id: 2,
    label: "Settings",
    labelKey: "SettingsTitle",
    active: false,
    popup: true,
    popupItems: [
      {
        id: 3,
        label: "User Management",
        labelKey: "UserManagementTitle",
        route: "/settings/user-control",
      },
      // {
      //   id: 4,
      //   label: "User Groups",
      //   labelKey: "UserGroupsTitle",
      //   route: "/settings/user-groups",
      // },
      // {
      //   id: 5,
      //   label: "Permission Group",
      //   labelKey: "PermissionGroupTitle",
      //   route: "/settings/permission-group",
      // },
      {
        id: 6,
        label: "Topics",
        labelKey: "TopicsTitle",
        route: "/settings/topics",
      },
      // {
      //   id: 7,
      //   label: "Moments",
      //   labelKey: "MomentsTitle",
      //   route: "/settings/moments",
      // },
      {
        id: 8,
        label: "Call Outcomes",
        labelKey: "CallOutcomesTitle",
        route: "/settings/outcomes",
      },
      {
        id: 10,
        label: "Evaluation Scorecards",
        labelKey: "EvaluationScorecardsTitle",
        route: "/settings/scorecards",
      },
      {
        id: 11,
        label: "System Settings",
        labelKey: "SystemSettingTitle",
        route: "/settings/system?selectedtab=0",
      },
      {
        id: 12,
        label: "Notification Setup",
        labelKey: "NotificationSetupTitle",
        route: "/settings/notification-setup",
      },
    ],
  },
];

const Header: React.FC<IHeaderProps> = () => {
  const trans = useTranslation("Header");
  const customNav = useCustomNavigation();
  let redirectBackToURL = window.location.origin + window.location.pathname + window.location.search;
  const localeCtx = useLocale();
  const brandingCtx = useBranding();
  const swal = useSwal();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const settingsAnchor = useRef<any>(null);
  const profileAnchor = useRef<any>(null);
  const [settingsActive, setSettingsActive] = useState(false);
  const [profileIconActive, setprofileIconActive] = useState(false);
  const [notificationActive, setNotificationActive] = useState(false);
  const [navSelectedId, setNavSelectedId] = useState(
    navItems.findIndex((i) => i.active === true)
  );

  const [voiceAIToken, setVoiceAIToken] = useState<string | null>(localStorage.getItem('VoiceAIToken'));

  const handleStorageChange = async (event: any) => {
    if (event.key === 'VoiceAIToken') {
      const oldToken = voiceAIToken;
      const newToken = event.newValue;
      if (oldToken !== newToken) {
        console.log(`VoiceAIToken changed from ${oldToken} to ${newToken}`);
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "info",
          title: "Session Updated",
          text: trans.fetchLabelKeyTranslation("SessionUpdatedText", "Your session has been updated. Please refresh.")
        };
        swal.fire(swalOptions).then((resp) => {
          window.location.replace(appUrl);
        });
      }
    }
  };
  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [voiceAIToken]);



  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["Header"]) {
      trans.fetchTranslations("Header");
    }
  }, [localeCtx?.selectedLocale]);


  useEffect(() => {
    let settingsSelected = false,
      settingsURL = "/";
    const urlParams = window.location.pathname.split("/");
    for (let i = 1; urlParams.length > 1 && i < urlParams.length; i++) {
      settingsURL +=
        i !== urlParams.length - 1 ? `${urlParams[i]}/` : urlParams[i]!=="system"?urlParams[i]:`${urlParams[i]}?selectedtab=0`;
    }
    navItems.forEach((nav) => {
      if (nav.popup) {
        const res = nav.popupItems?.find((item) => item.route === settingsURL);
        settingsSelected = res ? true : false;
      }
    });
    if (urlParams[1] === "home" || urlParams[1] === "login")
      setNavSelectedId(0);
    else if (urlParams[1] === "search") setNavSelectedId(1);
    else if (settingsSelected) setNavSelectedId(3);
    else setNavSelectedId(-1);

    navItems.forEach(_navItem => {
      if (_navItem.popup === false && _navItem.route === settingsURL) {
        document.title = trans.fetchLabelKeyTranslation(_navItem.labelKey, _navItem.label);
        return true;
      }
      else if (_navItem.popup === true && _navItem.popupItems?.findIndex(_pitems => _pitems.route === settingsURL) !== -1) {
        let _selectedNav = _navItem.popupItems?.find(_pitems => _pitems.route === settingsURL) ?? _navItem;
        document.title = trans.fetchLabelKeyTranslation(_selectedNav.labelKey, _selectedNav.label);
        return true;
      }
    });

  }, [navSelectedId, window.location.pathname,trans.translationsLoading]);

  useEffect(() => {
    if (profileIconActive || settingsActive || notificationActive) {
      const body = document.getElementById("root");
      const eventHandler = () => {
        setprofileIconActive(false);
        setSettingsActive(false);
        setNotificationActive(false);
      };
      if (body != null) {
        body.addEventListener("click", eventHandler);
      }
      return () => body?.removeEventListener("click", eventHandler);
    }
  }, [profileIconActive, settingsActive, notificationActive]);

  const navlinkSelectHandler = (id: number): void => {
    setNavSelectedId(id);
    setSettingsActive(false);
    setNotificationActive(false);
  };

  const logoutHandler = async () => {
    let authAppBaseUrl = AUTH_BASE_URL;
    if (brandingCtx?.branding?.authAppConfiguration.authClientAppUrl) {
      authAppBaseUrl =
        brandingCtx.branding.authAppConfiguration.authClientAppUrl;
    }
    let authAppClientId = AUTH_CLIENT_ID;
    if (brandingCtx?.branding?.authAppConfiguration.authAppClientId) {
      authAppClientId =
        brandingCtx.branding.authAppConfiguration.authAppClientId;
    }

    try {
      console.log("Loggin out...");
      window.localStorage.removeItem(
        LocalStorageKeys.VoiceAIInsightsFilter
      );
      window.sessionStorage.removeItem(SessionStorageKeys.insightId);
      sessionStorage.removeItem("VoiceAiCallFilter");
      sessionStorage.removeItem("ShareToken");
      localStorage.removeItem("VoiceAIToken");

      window.location.replace(
        `${authAppBaseUrl}/logout?clientId=${authAppClientId}&redirectUri=${appUrl}/login&language=${localeCtx?.selectedLocale?.current.locale.code}`
      );
    } catch (err) {
      console.error(err);
    }
  };

  const toggleSettingsPopup = () => {
    setNotificationActive(false);
    setprofileIconActive(false);
    setSettingsActive(!settingsActive);
  };

  const onNotificationToggle = () => {
    setSettingsActive(false);
    setprofileIconActive(false);
    setNotificationActive(!notificationActive);
  };

  const onProfileToggle = () => {
    setprofileIconActive(false);
  };

  const renderNavItems = navItems.map((navItem) => {
    var item = { ...navItem };
    if (item.popup && item.popupItems) {
      item.popupItems = item.popupItems.filter((pItem) => {
        if (
          (pItem?.label === "User Management" &&
            (auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups) ||
              auth?.checkUserAccess(
                AccessPermissionEnum.ManageSecurityControl
              ))) ||
          (pItem?.label === "Topics" &&
            auth?.checkTopicAvailibility() &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageTopics)) ||
          (pItem?.label === "Call Outcomes" &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageOutcomes)) ||
          (pItem?.label === "Evaluation Scorecards" &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageScorecards)) ||
          (pItem?.label === "System Settings" &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageAccount))
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    return (
      <li key={item.id} className={`nav-item ${item.popup ? "nav-li" : ""}`}>
        {!item.popup ? (
          <Link
            to={item.route ? item.route : "/"}
            onClick={async (e) => {              
              e.preventDefault();
              var ctrlClickedKey = e.ctrlKey;
              const isSuccess: boolean | undefined = await customNav?.navigateTo({ url: item.route ? item.route : "/", ctrlClicked: ctrlClickedKey })
              if (isSuccess) {
                navlinkSelectHandler(item.id)
              }
            }}
            className={`nav-link mt-1 cursor-pointer ${item.id === navSelectedId && "active"
              }`}
          >
            {trans.fetchLabelKeyTranslation(item.labelKey, item.label)}
          </Link>
        ) : (
          <>
            {(auth?.checkUserAccess(
              AccessPermissionEnum.ManageUsersAndGroups
            ) ||
              auth?.checkUserAccess(AccessPermissionEnum.ManageTopics) ||
              auth?.checkUserAccess(
                AccessPermissionEnum.ManageSecurityControl
              ) ||
              auth?.checkUserAccess(AccessPermissionEnum.ManageScorecards) ||
              auth?.checkUserAccess(AccessPermissionEnum.ManageOutcomes) ||
              auth?.checkUserAccess(AccessPermissionEnum.ManageAccount)) && (
                <React.Fragment>
                  <div
                    className={`popup-btn-link nav-link mt-1 cursor-pointer ${item.popupItems?.find((pi) => pi.id === navSelectedId) &&
                      "active"
                      }`}
                    onClick={toggleSettingsPopup}
                    ref={settingsAnchor}
                  >
                    {trans.fetchLabelKeyTranslation(item.labelKey, item.label)}
                    {/* {item.label} */}
                    <i className="bi bi-chevron-down rotate-icon p-l-3"></i>
                  </div>
                  <Popup anchor={settingsAnchor.current} show={settingsActive}>
                    <ul className="dropMenu min-w-200 droplist-ul">
                      {item.popupItems?.map((popupItem) => {
                        if (
                          !auth?.checkTopicAvailibility() &&
                          popupItem.labelKey === "TopicsTitle"
                        ) {
                          return;
                        }
                        return (
                          <li key={popupItem.id} className="droplist-li">
                            <Link
                              to={popupItem.route}
                              onClick={async (e) => {                                
                                e.preventDefault();
                                var ctrlClickedKey = e.ctrlKey;
                                const isSuccess: boolean | undefined = await customNav?.navigateTo({ url: popupItem.route, ctrlClicked: ctrlClickedKey })
                                if (isSuccess) {
                                  navlinkSelectHandler(popupItem.id)
                                }
                              }}
                              className="droplist-a"
                            >
                              {trans.fetchLabelKeyTranslation(popupItem.labelKey,popupItem.label)}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </Popup>
                </React.Fragment>
              )}
          </>
        )}
      </li>
    );
  });

  const changePasswordHandler = () => {
    let authAppBaseUrl = AUTH_BASE_URL;
    if (brandingCtx?.branding?.authAppConfiguration.authClientAppUrl) {
      authAppBaseUrl =
        brandingCtx.branding.authAppConfiguration.authClientAppUrl;
    }

    let authAppClientId = AUTH_CLIENT_ID;
    if (brandingCtx?.branding?.authAppConfiguration.authAppClientId) {
      authAppClientId =
        brandingCtx.branding.authAppConfiguration.authAppClientId;
    }

    window.location.replace(
      `${authAppBaseUrl}/change-password?clientId=${authAppClientId}&redirectUri=${appUrl}/login&language=${localeCtx?.selectedLocale?.current.locale.code}&redirectBackTo=${btoa(redirectBackToURL)}`
    );
  };

  return (
    <>
      <div className="header-contain d-flex  navbar-expand-lg navbar-light">
        {auth?.accessToken && <DrawerOverlay />}
        {brandingCtx?.branding?.logos?.find((l) => l.name === "HeaderPrimaryLogo")
          ?.logoImageUrl && (
          <div className="d-flex my-auto hdrTrk-logo">
            <Link
              to="/home"
              onClick={async(e) =>{                
                e.preventDefault();
                var ctrlClickedKey = e.ctrlKey;
                const isSuccess:boolean|undefined = await customNav?.navigateTo({url:"/home", ctrlClicked: ctrlClickedKey});
                if(isSuccess){
                  setNavSelectedId(0)
                }
              }}
              className="navbar-logo cursor-pointer"
            >
              <img
                src={
                  brandingCtx?.branding?.logos.find(
                    (l) => l.name === "HeaderPrimaryLogo"
                  )?.logoImageUrl
                }
                alt="logo"
              />
            </Link>
          </div>
        )}
        {auth?.accessToken &&
          auth?.userPermissions &&
          !location.pathname.includes("/login") &&
          !location.pathname.includes("/customer-platform") && (
            <ul className="nav ml-lg-5 collapse navbar-collapse justify-content-end">
              {renderNavItems}
            </ul>
          )}
        <div className="hdrTrk-nav ml-auto">
          <div className="nav-custom">
            <ul className="nav-custom-ul">
              {auth?.accessToken &&
                !location.pathname.includes("/login") &&
                !location.pathname.includes("/customer-platform") && (
                  <Notifications
                    notificationActive={notificationActive}
                    onNotificationToggle={onNotificationToggle}
                  fetchLabelKeyTranslation={trans.fetchLabelKeyTranslation}
                  />
                )}
              {auth?.accessToken && 
                !location.pathname.includes("/login") && (
                <li
                  className="nav-li li-usr usrPic cursor-pointer"
                  ref={profileAnchor}
                >
                  <div className="topUsrAreaPic navuser">
                    <div className="topUsrAreaPic-i" title={auth?.user?.email}>
                      <div className="contact-list-icon">
                        <div className="contact-list-icon-txt">
                          <div className="contact-list-icon-img">
                            {/* <img src="assets/images/user-img.jpg" alt="User"> */}
                            <span
                              onClick={() => {
                                setSettingsActive(false);
                                setNotificationActive(false);
                                setprofileIconActive(!profileIconActive);
                              }}
                            >
                              {auth?.user ? (
                                !auth.user.firstName && !auth.user.lastName ? (
                                  <i className="bi bi-person"></i>
                                ) : (
                                  getUserInitials(
                                    auth?.user.firstName,
                                    auth?.user.lastName
                                  )
                                )
                              ) : (
                                "U"
                              )}
                            </span>
                            <Popup
                              anchor={profileAnchor.current}
                              show={profileIconActive}
                            >
                              <ul
                                className="dropMenu min-w-40 droplist-ul"
                                style={{
                                  left: "-5px",
                                }}
                                onClick={onProfileToggle}
                              >
                                <li className="droplist-li">

                                  <Link   
                                    to={`/settings/user-control/user/${auth?.user?.id}`}
                                    onClick={(e)=>{                                      
                                      e.preventDefault();
                                      var ctrlClickedKey = e.ctrlKey;
                                      customNav?.navigateTo({url:`/settings/user-control/user/${auth?.user?.id}`, ctrlClicked: ctrlClickedKey})}}

                                    className="droplist-a"
                                  >
                                    {trans.fetchLabelKeyTranslation("ProfileTitle", "Profile")}
                                  </Link>
                                </li>
                                <li className="droplist-li">
                                  <Link
                                    to={`/settings/notification-setup`}
                                    className="droplist-a"
                                  >
                                    {`${
                                      trans.fetchLabelKeyTranslation(
                                            "MyNotificationsTitle",
                                            "My Notifications"
                                          )
                                    }`}
                                  </Link>
                                </li>
                                <li
                                  className="droplist-li"
                                  onClick={async()=>{
                                    const isSuccess:boolean|undefined=await customNav?.navigateTo({isNavigating:false});
                                    if(isSuccess){
                                      changePasswordHandler();
                                    }
                                  }}
                                >
                                  <Link to={`/`} onClick={(e)=>e.preventDefault()} className="droplist-a">

                                    {trans.fetchLabelKeyTranslation("ChangePasswordTitle", "Change Password")}
                                  </Link>
                                </li>
                                <li
                                  className="droplist-li"
                                  onClick={async()=>{
                                    const isSuccess:boolean|undefined = await customNav?.navigateTo({isNavigating:false});
                                    if(isSuccess){
                                      logoutHandler();
                                    }
                                  }}
                                >
                                  <span className="droplist-a">
                                  {trans.fetchLabelKeyTranslation("LogoutTitle", "Log Out")}
                                  </span>
                                </li>
                              </ul>
                            </Popup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {!auth?.accessToken && location.pathname.includes("/shared") && (
                <li
                  className="nav-li li-usr usrPic cursor-default"
                  ref={profileAnchor}
                >
                  <div className="topUsrAreaPic navuser">
                    <div className="topUsrAreaPic-i" title={"Guest User"}>
                      <div className="contact-list-icon">
                        <div className="contact-list-icon-txt">
                          <div className="contact-list-icon-img">
                            <span className="fs-14">GU</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
            )}
            <li>
              {!location.pathname.includes("/customer-platform") && (
                <HelpGuide fetchLabelKeyTranslation={trans.fetchLabelKeyTranslation}></HelpGuide>
              )}
            </li>
            <li>
                <LanguageSelector />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
