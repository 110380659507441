import { RecordingTranscription, Word } from "../types/recording";
import { SpeakerTranscriptInfo } from "../types/transcription";

export const processRawTranscript = (
  rawTranscript: RecordingTranscription,
  lockedSegmentCount: number
): SpeakerTranscriptInfo[] => {
  const processedTranscript: SpeakerTranscriptInfo[] = [];
  const transcriptWords: Word[] = [];

  if(rawTranscript.transcription) {
    rawTranscript.transcription.forEach(transcriptionObject => {
      const words = transcriptionObject.words;
    words.forEach(word => {
      transcriptWords.push(word);
    });
  });
}
  if (transcriptWords.length === 0) {
    return [];
  }

  let speakerInt:number = getSpeakerInt(transcriptWords[0]);
  
  let speakerStart = transcriptWords[0].start;
  let speakerText = transcriptWords[0].word ? transcriptWords[0].word: " ";
  let cntId = 0;
  for (let i = 1; i < transcriptWords.length; i++) {
    if (speakerInt === getSpeakerInt(transcriptWords[i])) {
      // break paragraphs into chunks if there is silence of greater than 2s.
      if (transcriptWords[i].start - transcriptWords[i - 1].end > 2) {
        processedTranscript.push({
          id: cntId,
          speaker: speakerInt,
          text: speakerText,
          start: speakerStart,
          end: transcriptWords[i].start,
          active: false,
        });
        speakerText = "";
        speakerInt = getSpeakerInt(transcriptWords[i]);
        speakerStart = transcriptWords[i].start;
        cntId += 1;
      }
    }
    if (speakerInt !== getSpeakerInt(transcriptWords[i])) {
      processedTranscript.push({
        id: cntId,
        speaker: speakerInt,
        text: speakerText,
        start: speakerStart,
        end: transcriptWords[i].start,
        active: false,
      });
      speakerText = "";
      speakerInt = getSpeakerInt(transcriptWords[i]);
      speakerStart = transcriptWords[i].start;
      cntId += 1;
    }

    speakerText = speakerText + " " + (transcriptWords[i].word ? transcriptWords[i].word : " ");
  }

  // if no. of speakers = 1
  if (processedTranscript.length === 0 || speakerText.length > 0) {
    processedTranscript.push({
      id: cntId,
      speaker: speakerInt,
      text: speakerText,
      start: speakerStart,
      end: transcriptWords[transcriptWords.length - 1].end,
      active: false,
    });
  }

  // handling [locked] segments
  if(lockedSegmentCount > 0){
    processedTranscript.forEach((ele)=>{
      ele.text = ele.text.replace(/\[locked\]( \[locked\])* /g, "[locked] ").trim();
    })
  }
  
  return processedTranscript;
};

export const getSpeakerInt = (wordObj: Word): number => {
  let speakerInt: number = 1;
  const underscoreIndex = wordObj?.speaker?.indexOf("_") ? wordObj?.speaker?.indexOf("_") : -1;
  if (underscoreIndex !== -1) {
    const textAfterUnderscore:string = wordObj.speaker.slice(underscoreIndex + 1);
    speakerInt = !isNaN(parseInt(textAfterUnderscore)) ? parseInt(textAfterUnderscore) : speakerInt;
  }
  return speakerInt;
}