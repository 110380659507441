import { Dictionary } from "../types/Dictionary";
import { AccessPermission } from "../types/master-data";

export const IsValidString = (str?: string): boolean => {
  if (str === undefined || str === null || str.trim() === "") {
    return false;
  } else {
    return true;
  }
};

export const getInitials = (input: string): string => {
  const words = input.split(" ");
  const res = words
    .map((w) => w[0])
    .join("")
    .substring(0, 2);

  return res;
};

export const convertToPascalCase = (input: string): string => {
  const words = input.split(" ");
  const res = words
    .map((w) => {
      if (w.trim() !== "") {
        return w[0].toLocaleUpperCase() + w.substring(1);
      }
    })
    .join(" ");

  return res;
};

export const removeAllSpacesAndConvertToLowercase = (input: string): string => {
  return input.replace(/\s/g, '').toLowerCase();
}

export const removeAllSpaces = (input: string): string => {
  return input.replace(/\s/g, '');
}

export const convertKeyToPascalCase = (key: string) => {
  const result = key
    .replace(/([A-Z]+)/g, " $1")
    .replace(/([A-Z][a-z])/g, " $1");
  console.log(result);
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

interface AccessPermissionNameSequencePair {
  name: string,
  sequenceNumber: Number
}

interface AccessPermissionWithSequence extends AccessPermission {
  sequenceNo: Number
}

const AccessPermissionWithSequenceList : AccessPermissionNameSequencePair[] = [
  {
    name: "Listen Calls",
    sequenceNumber: 1
  },
  {
    name: "Share Calls",
    sequenceNumber: 2
  },
  {
    name: "Evaluate Calls",
    sequenceNumber: 3
  },
  {
    name: "Delete Calls",
    sequenceNumber: 4
  },
  {
    name: "Manage Compliance",
    sequenceNumber: 5
  },
  {
    name: "Manage Users & Groups",
    sequenceNumber: 6
  },
  {
    name: "Manage Scorecards",
    sequenceNumber: 7
  },
  {
    name: "Manage Topics",
    sequenceNumber: 8
  },
  {
    name: "Manage Outcomes",
    sequenceNumber: 9
  },
  {
    name: "Manage Security Control",
    sequenceNumber: 10
  },
  {
    name: "Manage Account",
    sequenceNumber: 11
  }
]


export const AccessPermissionListSequenceBuilder = (permissions: AccessPermission[]) : AccessPermission[] => {
  const accessPermissionWithSequence: AccessPermissionWithSequence[] = permissions.map((p) => {
    const permissionWithSequence: AccessPermissionWithSequence = {
      id: p.id,
      name: p.name,
      isPlaybackPermission: p.isPlaybackPermission,
      localizationKey: p.localizationKey,
      localizationValue: p.localizationValue,
      sequenceNo: AccessPermissionWithSequenceList.find(x => x.name === p.name)?.sequenceNumber ?? 0,
    }
    return permissionWithSequence
  })
  const sortedAccessPermissions = accessPermissionWithSequence.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));
  const orderedPermissionList : AccessPermission []= sortedAccessPermissions.map((p)=> {
    const permission:  AccessPermission = {
      id: p.id,
      name: p.name,
      isPlaybackPermission: p.isPlaybackPermission,
      localizationKey: p.localizationKey,
      localizationValue: p.localizationValue
    }
    return permission
  })
  return orderedPermissionList
}