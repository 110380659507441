export const mentionStyling={
    control: {
        backgroundColor: "#fff",
        fontSize: 12,
      },

      "&multiLine": {
        control: {
          fontFamily: "monospace",
          height: 150,
          overflow:"hidden"

        },
        highlighter: {
          padding: 5,
          border: "1px solid transparent",
          fontSize:"14px",
        },
        input: {
          fontSize:"14px",
          padding: 5,
          border: "1px solid silver",
          outline:"none",
          borderRadius:"2px",
          overflow:"auto"
        },
      },

      '&singleLine': {
        control: {
          fontFamily: "monospace",
        },
        highlighter: {
          padding:"1px 2px",
          border: "1px solid transparent",
          fontSize:"12px",
        },
        input: {
          fontSize:"12px",
          border: "1px solid silver",
          outline:"none",
          borderRadius:"2px",
        },
      },

      suggestions: {
        list: {
          backgroundColor: "white",
          border: "1px solid rgba($black,0.15)",
          height:"200px",
          overflowY:"auto",
          padding:"5px",
        },
        item: {
            display:"flex",
            alignItems:"center",
            height:"60px",
            padding: '5px 15px',
            '&focused': {
                height:"60px",
              backgroundColor: '#cee4e5',
            },
          },
      },
}