export interface Filter {
    id: number;
    title: string;
    translationLabelKey?: string;
}

export interface PeriodFilter {
    id: number;
    title: string;
    displayTitle: string;
    translationLabelKey?: string;
    filters?: Filter[];
}

export const PeriodFilterTypes: PeriodFilter[] = [
    {
        id: 1,
        title: "Day",
        displayTitle: "Day",
        translationLabelKey: "DayFilterLabel",
        filters: [
            {
                id: 1,
                title: "Today",
                translationLabelKey: "TodayFilterLabel"
            },
            {
                id: 2,
                title: "Yesterday",
                translationLabelKey: "YesterdayFilterLabel"
            },
            {
                id: 3,
                title: "Last 7 Days",
                translationLabelKey: "Last7DaysFilterLabel"
            },
            {
                id: 4,
                title: "Last 30 Days",
                translationLabelKey: "Last30DaysFilterLabel"
            },
        ]
    },
    {
        id: 2,
        title: "Week",
        displayTitle: "Week",
        translationLabelKey: "WeekFilterLabel",
        filters: [
            {
                id: 1,
                title: "This Week",
                translationLabelKey: "ThisWeekFilterLabel"
            },
            {
                id: 2,
                title: "Last Week",
                translationLabelKey: "LastWeekFilterLabel"
            }
        ]
    },
    {
        id: 3,
        title: "Month",
        displayTitle: "Month",
        translationLabelKey: "MonthFilterLabel",
        filters: [
            {
                id: 1,
                title: "This Month",
                translationLabelKey: "ThisMonthFilterLabel"
            },
            {
                id: 2,
                title: "Last Month",
                translationLabelKey: "LastMonthFilterLabel"
            }
        ]
    },
    {
        id: 4,
        title: "Custom",
        displayTitle: "Custom",
        translationLabelKey: "CustomFilterLabel"
    },
];