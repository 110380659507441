import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { useEffect, useMemo, useState } from "react";
import AccountSettings from "./AccountSettings";
import EmailServerSettings from "./EmailServerSettings";
import PlatformLicenses from "./PlatformLicenses";
import SecurityPrivacySettings from "./SecurityPrivacySettings";
import StorageSettings from "./StorageSettings";
import { Dictionary } from "../../../types/Dictionary";
import useLocale from "../../../hooks/useLocale";
import useAuth from "../../../hooks/useAuth";
import { AccessPermissionEnum } from "../../../enums";
import NotFoundError from "../../error/NotFoundError";
import UnauthorizedAccess from "../../error/UnauthorizedAccess";
import useSwal from "../../../hooks/useSwal";
import AuditLogSettings from "./audit-log-settings/AuditLogSettings";
import RecordingPolicySettings from "./recording-policy/RecordingPolicySettings";
import LoadingOverlay from "../../../components/LoadingOverlay";
import CRMSetting from "./CRMSetting";
import APIAccess from "./api-access/APIAccess";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { EntityKey } from "../../../types/master-data/EntityKey";
import React from "react";
import useTranslation from "../../../hooks/useTranslation";
import { useSearchParams } from "react-router-dom";
import { Customer } from "../../../types/customer";
import { AxiosError } from "axios";
import customerService from "../../../services/customer.service";

const SystemSettings: React.FC = () => {
  const trans = useTranslation("SystemSettings")
  const auth = useAuth();
  const Swal = useSwal();
  const localeCtx = useLocale();
  const [pageFreezeLoader, setPageFreezeLoader] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  var selectedTabViaNavigation = parseInt(searchParams.get("selectedtab")??"0");
  const [selected, setSelected] = useState<number>(selectedTabViaNavigation??0);
  const grantToken = searchParams.get("code")
  const [customerInfo, setCustomerInfo] = useState<Customer>();
  const [customerError, setCustomerError] = useState<string>();


  useEffect(() => {
    const getCustomerInfo = async () => {
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId !== undefined && customerId > 0) {
        try {
          setPageFreezeLoader(true);
          setCustomerError(undefined);
          const customer = await customerService.getCustomerDetailsById(
            customerId
          );
          setCustomerInfo(customer);
        } catch (err) {
          if (err instanceof AxiosError) {
            setCustomerError(err.response?.data?.error ?? err.message);
          }
        } finally {
          setPageFreezeLoader(false);
        }
      }
    };
    getCustomerInfo();
  }, [auth?.tokenPayload?.CustomerId]);
  
  interface ISystemSettingsDropdown extends EntityKey {
    id: number;
    title: string;
  }

  const mobileDropdownData: ISystemSettingsDropdown[] = [
    {
      id: 0,
      title: "Account",
      localizationKey: "SystemSettingsAccountTab",
    },
    {
      id: 1,
      title: "Storage",
      localizationKey: "SystemSettingsStorageTab",
    },
    {
      id: 2,
      title: "Licence",
      localizationKey: "SystemSettingsLicenseTab",
    },
    {
      id: 3,
      title: "Email Account",
      localizationKey: "SystemSettingsEmailTab",
    },
    {
      id: 4,
      title: "Security & Privacy",
      localizationKey: "SystemSettingsSecurityTab",
    },
    {
      id: 5,
      title: "Audit Logs",
      localizationKey: "SystemSettingsAuditTab",
    },
    {
      id: 6,
      title: "Recording Policy",
      localizationKey: "SystemSettingsRecordingPolicyTab",
    },
    {
      id: 7,
      title: "CRM",
      localizationKey: "SystemSettingsCRMTab",
    },
    {
      id: 8,
      title: "API Access",
      localizationKey: "SystemSettingsAPIAccessTab",
    },
  ];

  const mobileItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span>
        {trans.fetchLabelKeyTranslation(
          itemProps.dataItem.localizationKey ?? "",
          itemProps.dataItem.title
        )}
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const mobileValueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: ISystemSettingsDropdown
  ) => {
    if (!value) {
      return element;
    }
    const currentlySelected = mobileDropdownData[selected];
    const children = [
      <span key={currentlySelected.title}>
        {trans.fetchLabelKeyTranslation(
          currentlySelected.localizationKey ?? "",
          currentlySelected.title
        )}
      </span>,
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };

  const MobileSystemSettingsMemoized = useMemo(() => {
    return (id: number) => {
      if (window.screen.width > 700) {
        return false;
      }
      switch (id) {
        case 0: return <AccountSettings customerInfo={customerInfo} customerError={customerError} setCustomerInfo={setCustomerInfo} pageFreezeLoader={pageFreezeLoader} />
        case 1: return <StorageSettings />
        case 2: return <PlatformLicenses customerInfo={customerInfo} customerError={customerError} pageFreezeLoader={pageFreezeLoader}/>
        case 3: return <EmailServerSettings />
        case 4: return <SecurityPrivacySettings />
        case 5: return <AuditLogSettings
          title={true}
          search={true}
          filters={true}
          excludedColumns={[]}
        />
        case 6: return <RecordingPolicySettings setPageFreezeLoader={setPageFreezeLoader} />
        case 7: return <CRMSetting />
        case 8: return <APIAccess setPageFreezeLoader={setPageFreezeLoader} />
        default: return <AccountSettings customerInfo={customerInfo} customerError={customerError} setCustomerInfo={setCustomerInfo} pageFreezeLoader={pageFreezeLoader} />
      }
    };
  }, [selected]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "SystemSettings"
      ]
    ) {
      trans.fetchTranslations("SystemSettings");
    }
  }, [localeCtx?.selectedLocale]);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?selectedtab=" +
      e.selected;
    window.history.pushState({ path: newurl }, "", newurl);
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageAccount) ? (
        <UnauthorizedAccess />
      ) : (
        <>
          {trans.translationsLoading && !pageFreezeLoader && (
            <LoadingOverlay
              customStyle={{ position: "fixed", marginTop: "55px" }}
              themeColor={"light"}
              size={"medium"}
              loadingText={trans.fetchLabelKeyTranslation(
                "SwitchLanguageText",
                ""
              )}
            />
          )}
          {pageFreezeLoader && (
            <LoadingOverlay
              customStyle={{
                position: "fixed",
                marginTop: "55px",
                zIndex: "999999",
              }}
              themeColor={"light"}
              size={"medium"}
              loadingText={"Please wait..."}
            />
          )}
          <div className="m-b-20">
            <div className="row">
              <div className="col-md-12">
                <div className="card cardEffect">
                  <div className="card-header bg-white d-flex justify-content-between">
                    <span className="detailHeader d-flex align-items-center">
                      <span className="fs-16 font-weight-semi p-r-20">
                        {`${trans.fetchLabelKeyTranslation(
                          "SystemSettingsTitle",
                          "System Settings"
                        )}`}
                      </span>
                    </span>
                    {customerInfo?.isActive === false && <span className="badge rounded-pill bg-danger text-white px-3 py-2 font-weight-normal"><i className="bi bi-exclamation-triangle mr-2"></i>Inactive</span>}
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="settingData k-custom-tab p-t-20 p-b-20 systemSet settingDataMobile">
                          {/* Mobile View */}
                          <div className="tabGroupDropdown">
                            <DropDownList
                              style={{ width: "100%", marginBottom: "10px" }}
                              textField="title"
                              dataItemKey="id"
                              data={mobileDropdownData}
                              itemRender={mobileItemRender}
                              valueRender={mobileValueRender}
                              onChange={(e) => {
                                setSelected(e.value?.id);
                              }}
                              defaultValue={mobileDropdownData[selected]}
                            />
                            {MobileSystemSettingsMemoized(selected)}
                          </div>
                          <TabStrip
                            tabPosition={"left"}
                            selected={selected}
                            onSelect={handleSelect}
                            className="tabGroupData"
                          >
                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                                <TabStripTab
                                  title={`${trans.fetchLabelKeyTranslation(
                                      "SystemSettingsAccountTab",
                                      "Account"
                                    )
                                    }`}
                                >
                                  <AccountSettings 
                                    customerInfo={customerInfo}
                                    setCustomerInfo={setCustomerInfo} 
                                    customerError={customerError} 
                                    pageFreezeLoader={pageFreezeLoader} />
                                </TabStripTab>
                              )}

                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${trans.fetchLabelKeyTranslation(
                                  "SystemSettingsStorageTab",
                                  "Storage"
                                )}`}
                              >
                                <StorageSettings />
                              </TabStripTab>
                            )}
                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                                <TabStripTab
                                  title={`${
                                  trans.fetchLabelKeyTranslation(
                                      "SystemSettingsLicenseTab",
                                      "Licence"
                                      )
                                    }`}
                                >
                                  <PlatformLicenses
                                    customerInfo={customerInfo}
                                    customerError={customerError} 
                                    pageFreezeLoader={pageFreezeLoader} 
                                  />
                                </TabStripTab>
                              )}

                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${trans.fetchLabelKeyTranslation(
                                  "SystemSettingsEmailTab",
                                  "Email Account"
                                )}`}
                              >
                                <EmailServerSettings />
                              </TabStripTab>
                            )}
                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${trans.fetchLabelKeyTranslation(
                                  "SystemSettingsSecurityTab",
                                  "Security & Privacy"
                                )}`}
                              >
                                <SecurityPrivacySettings />
                              </TabStripTab>
                            )}

                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${trans.fetchLabelKeyTranslation(
                                  "SystemSettingsAuditTab",
                                  "Audit Logs"
                                )}`}
                              >
                                <AuditLogSettings
                                  title={true}
                                  search={true}
                                  filters={true}
                                  excludedColumns={[]}
                                />
                              </TabStripTab>
                            )}
                            <TabStripTab
                              title={`${trans.fetchLabelKeyTranslation(
                                "SystemSettingsRecordingPolicyTab",
                                "Recording Policy"
                              )}`}
                            >
                              <RecordingPolicySettings
                                setPageFreezeLoader={setPageFreezeLoader}
                              />
                            </TabStripTab>
                            <TabStripTab title="CRM">
                              <CRMSetting />
                            </TabStripTab>
                            {auth.checkUserAccess(
                              AccessPermissionEnum.ManageSecurityControl
                            ) && (
                              <TabStripTab
                                title={`${trans.fetchLabelKeyTranslation(
                                  "SystemSettingsAPIAccessTab",
                                  "API Access"
                                )}`}
                              >
                                <APIAccess
                                  setPageFreezeLoader={setPageFreezeLoader}
                                />
                              </TabStripTab>
                            )}
                          </TabStrip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SystemSettings;
