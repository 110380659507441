let sField = "",sOrder="";

function compare( a:any, b:any) {
  if(sField && (a[sField]?.toLocaleLowerCase() === "none" || b[sField].toLocaleLowerCase() === "none")){
    return a[sField].toLocaleLowerCase()==="none" ? 1 : -1;
  }
  if (sField && a[sField]?.toLocaleLowerCase() < b[sField]?.toLocaleLowerCase() ){
    return sOrder==="desc"?1:-1;
  }
  if (sField && a[sField]?.toLocaleLowerCase() > b[sField]?.toLocaleLowerCase() ){
    return sOrder==="desc"?-1:1;
  }
  return 0;
}

export const sortDataFunc = (data: any, sortField : any,sortOrder:string)  => {
  var sortData = data && [...data];
  sField = sortField;
  sOrder=sortOrder;
  if(sortData?.length>0){
    if(!sField){

      sOrder==="desc"?sortData?.sort()?.reverse():sortData?.sort();
    }else{
     sortData?.sort(compare);
    }
  }

  return sortData;
}

