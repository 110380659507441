import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import CustomComboBox from "../../../components/custom/form/CustomComboBox";
import CustomFormTextArea from "../../../components/custom/form/CustomFormTextArea";
import CustomInput from "../../../components/custom/form/CustomInput";
import useAuth from "../../../hooks/useAuth";
import useLocale from "../../../hooks/useLocale";
import useMasterData from "../../../hooks/useMasterData";
import customerService from "../../../services/customer.service";
import { Customer } from "../../../types/customer/Customer";
import "./AccountSettings.scss";
import { UpsertCustomer } from "../../../types/customer";
import { Dictionary } from "../../../types/Dictionary";
import useSwal from "../../../hooks/useSwal";
import { Link } from "react-router-dom";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { CustomerMfaModes } from "../../../types/customer/CustomerMfaModes";
import { CustomerMfaConfig } from "../../../types/customer/CustomerMfaConfig";
import { UpsertCustomerMfaConfig } from "../../../types/customer/UpsertCustomerMfaConfig";
import CustomSwitch from "../../../components/custom/form/CustomSwitch";
import { Switch } from "@progress/kendo-react-inputs";
import useTranslation from "../../../hooks/useTranslation";

interface AccountSettingsProps {
  customerInfo: Customer | undefined;
  setCustomerInfo:React.Dispatch<React.SetStateAction<Customer | undefined>>;
  customerError:string | undefined;
  pageFreezeLoader: boolean;
}

const AccountSettings: React.FC<AccountSettingsProps> = ({customerInfo, customerError, setCustomerInfo,pageFreezeLoader}) => {
  const trans = useTranslation("AccountSettings");
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal();
  const masterData = useMasterData();
  const [loading, setLoading] = useState<boolean>(pageFreezeLoader);
  const [error, setError] = useState<string>();
  const [editing, setEditing] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const formRef = useRef<any>();
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);
  const [customerMFA, setCustomerMFA] = useState<CustomerMfaModes>();
  const [checkMFAEnabled, setCheckMFAEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "AccountSettings"
      ]
    ) {
      trans.fetchTranslations("AccountSettings");
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    const getMFA = async () => {
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId !== undefined && customerId > 0) {
        try {
          setLoading(true);
          setError(undefined);
          const custMFA = await customerService.getCustomerMFAModes(customerId);
          setCustomerMFA(custMFA);
          if (
            custMFA.mfaModeNames &&
            custMFA.mfaModeNames.length &&
            custMFA.mfaModeNames?.length > 0
          ) {
            setCheckMFAEnabled(true);
            const form = formRef.current as Form;
            form.values.mfaEnabled = true;
          } else {
            setCheckMFAEnabled(false);
          }
        } catch (err) {
          if (err instanceof AxiosError) {
            setError(err.response?.data?.error ?? err.message);
          }
        } finally {
          setLoading(false);
        }
      }
    };
    getMFA();
  }, [auth?.tokenPayload?.CustomerId]);

  const userEditClickHandler = () => {
    setEditing(true);
  };

  const onUserEditCancelHandler = () => {
    setEditing(false);
    const form = formRef.current as Form;
    form.resetForm();
  };

  const isCustomerDataUpdated = (
    customerInfo: Customer,
    dataItem: { [name: string]: any }
  ): boolean => {
    if (
      customerInfo.name === dataItem.customerName &&
      customerInfo.email === dataItem.email &&
      customerInfo.address === dataItem.customerAddress &&
      customerInfo.contact === dataItem.contact &&
      customerInfo.timezone?.displayName === dataItem.timezone.displayName &&
      customerInfo.locale?.code === dataItem.locale.code &&
      customerInfo.country?.name === dataItem.country.name
    ) {
      return false;
    }

    return true;
  };

  const onSubmitHandler = async (dataItem: { [name: string]: any }) => {
    try {
      if (customerInfo) {
        const isCustomerUpdated: boolean = isCustomerDataUpdated(
          customerInfo,
          dataItem
        );
        const isMFAUpdated: boolean =
          checkMFAEnabled === dataItem.mfaEnabled ? false : true;

        if (isCustomerUpdated) {
          setUpdating(true);
          let updatedCustomer: UpsertCustomer = {
            name: dataItem.customerName,
            email: dataItem.email,
            address: dataItem.customerAddress,
            contact: dataItem.contact,
            timezoneidentifier: dataItem.timezone.timezoneIdentifier,
            localeCode: dataItem.locale.code,
            countryName: dataItem.country.name,
            externalCustomerInfo: customerInfo.externalCustomerInfo,
          };
          const updatedCustomerResponse =
            await customerService.updateCustomerDetailsById(
              customerInfo?.id,
              updatedCustomer
            );
          setCustomerInfo(updatedCustomerResponse);
        }

        if (isMFAUpdated) {
          const upsertMFA: UpsertCustomerMfaConfig = {
            CustomerMfaConfigList: [],
          };
          if (dataItem.mfaValues) {
            dataItem.mfaValues.forEach((u: string) => {
              upsertMFA.CustomerMfaConfigList.push({
                MfaModeName: u,
                IsEnabled: dataItem.mfaEnabled,
              });
            });
          }
          if (dataItem.mfaEnabled) {
            upsertMFA.CustomerMfaConfigList.push({
              MfaModeName: "EmailAuthentication",
              IsEnabled: dataItem.mfaEnabled,
            });
          } else {
            upsertMFA.CustomerMfaConfigList = [];
          }

          const updatedMFAResponse = await customerService.upsertMFAModes(
            customerInfo?.id,
            upsertMFA
          );
          if (
            upsertMFA.CustomerMfaConfigList &&
            upsertMFA.CustomerMfaConfigList.length > 0
          ) {
            let custMFAModes: CustomerMfaModes = { mfaModeNames: [] };
            custMFAModes.mfaModeNames?.push(
              upsertMFA.CustomerMfaConfigList[0].MfaModeName
            );
            setCustomerMFA(custMFAModes);
            setCheckMFAEnabled(true);
          } else {
            setCheckMFAEnabled(false);
          }
        }

        setEditing(false);
        if (isCustomerUpdated || isMFAUpdated) {
          swal.fire({
            icon: "success",
            confirmButtonText: `${trans.fetchLabelKeyTranslation(
              "SwtAltOkText",
              "Ok"
            )}`,
            title: `${trans.fetchLabelKeyTranslation(
              "SwtAltAccountDetailsUpdated",
              "Account Details Updated"
            )}`,
          });
        }
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err);
        swal.fire({
          icon: "error",
          title: `${trans.fetchLabelKeyTranslation(
            "SwtAltErrorText",
            "Error"
          )}`,
          text: `${err.message}`,
        });
      }
    } finally {
      setUpdating(false);
    }
  };

  const compareState = () => {
    const form = formRef.current as Form;
    if (
      form.values.locale.id === customerInfo?.locale?.id &&
      (form.values.timezone
        ? form.values.timezone.id === customerInfo?.timezone?.id
        : true) &&
      (form.values.country
        ? form.values.country.id === customerInfo?.country?.id
        : true) &&
      form.values.customerAddress == customerInfo?.address &&
      form.values.contact == customerInfo?.contact &&
      form.values.email == customerInfo?.email &&
      form.values.customerName == customerInfo?.name &&
      form.values.mfaEnabled == checkMFAEnabled
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  const printMfaModeNames = (modeNames: string[] | undefined): string => {
    let translatedNames: string[] = [];
    if (modeNames && modeNames?.length > 0) {
      modeNames.forEach((value, index) => {
        switch (value) {
          case "EmailAuthentication": {
            translatedNames.push(
              trans.fetchLabelKeyTranslation(
                "EmailAuthenticationText",
                "Email Authentication"
              )
            );
            break;
          }
          default:
            translatedNames.push("");
            break;
        }
      });
    }

    const names = translatedNames.join(", ");
    return names;
  };

  return (
    <div className="m-b-10">
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container emlBox">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">
                  {trans.fetchLabelKeyTranslation(
                    "AccountSettingsTitle",
                    "Accounts Details"
                  )}
                </span>
                {!editing && !error && !customerError && (
                  <span className="float-right">
                    <Button
                      className={`btn bg-transparent text-black-12 text-primary m-r-5 p-r-7 p-l-7 ${
                        masterData?.telepoDisableConfig?.systemSetting.account
                          .disableChanges
                          ? "disabledBtn"
                          : "border-primary"
                      }`}
                      style={{ height: "26px" }}
                      onClick={userEditClickHandler}
                      disabled={
                        masterData?.telepoDisableConfig?.systemSetting.account
                          .disableChanges
                      }
                    >
                      <span className="p-r-5">
                        {trans.fetchLabelKeyTranslation(
                          "AccountSettingsTabEdit",
                          "Edit"
                        )}
                      </span>
                      <i className="bi bi-pencil"></i>
                    </Button>
                  </span>
                )}
              </div>
              {(loading || !masterData?.data) && (
                <div className="m-t-30" style={{ textAlign: "center" }}>
                  <Loader type={"infinite-spinner"} />
                </div>
              )}
              {!loading && (error || customerError) && (
                <div className="float-left w-100 m-t-10">
                  <div className="selectScorecard">
                    <div>
                      <span className="tx-red fs-14">{customerError || error}</span>
                    </div>
                  </div>
                </div>
              )}
              {!loading && !error && !customerError && masterData?.data && (
                <Form
                  onSubmit={onSubmitHandler}
                  ref={formRef}
                  initialValues={{
                    customerName:
                      customerInfo?.externalCustomerInfo?.customerName ??
                      customerInfo?.name,
                    customerAddress: customerInfo?.address,
                    country: masterData?.data?.countries.find(
                      (c) => c.id === customerInfo?.country?.id
                    ) ?? customerInfo?.country,
                    timezone: masterData?.data?.timezones.find(
                      (c) => c.id === customerInfo?.timezone?.id
                    ) ?? customerInfo?.timezone,
                    locale: customerInfo?.locale,
                    email: customerInfo?.email,
                    contact: customerInfo?.contact,
                    mfaValues: customerMFA?.mfaModeNames,
                    mfaEnabled: checkMFAEnabled,
                  }}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                      <div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {trans.fetchLabelKeyTranslation(
                                "AccountSettingsCompanyName",
                                "Company Name"
                              )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>
                                  {customerInfo?.externalCustomerInfo
                                    ?.customerName ?? customerInfo?.name}
                                </span>
                              ) : (
                                <Field
                                  className="textInput"
                                  id={"customerName"}
                                  name={"customerName"}
                                  value={formRenderProps.valueGetter(
                                    "customerName"
                                  )}
                                  component={CustomInput}
                                  disabled={
                                    customerInfo?.externalCustomerInfo !== null
                                  }
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {trans.fetchLabelKeyTranslation(
                                "AccountSettingsEmail",
                                "Email"
                              )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>{customerInfo?.email}</span>
                              ) : (
                                <Field
                                  id={"email"}
                                  name={"email"}
                                  className="comboBoxInput"
                                  textField="email"
                                  value={formRenderProps.valueGetter("email")}
                                  component={CustomInput}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {trans.fetchLabelKeyTranslation(
                                "AccountSettingsContact",
                                "Contact"
                              )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>{customerInfo?.contact}</span>
                              ) : (
                                <Field
                                  id={"contact"}
                                  name={"contact"}
                                  className="comboBoxInput"
                                  textField="contact"
                                  value={formRenderProps.valueGetter("contact")}
                                  component={CustomInput}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {trans.fetchLabelKeyTranslation(
                                "AccountSettingsAddress",
                                "Address"
                              )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>{customerInfo?.address}</span>
                              ) : (
                                <Field
                                  id={"customerAddress"}
                                  name={"customerAddress"}
                                  style={{ height: "100px" }}
                                  className="comboBoxInput"
                                  placeholder={`${"Customer Address"}`}
                                  max={1000}
                                  autoSize={true}
                                  rows={15}
                                  showTextLimitHint={false}
                                  value={formRenderProps.valueGetter(
                                    "customerAddress"
                                  )}
                                  component={CustomFormTextArea}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {trans.fetchLabelKeyTranslation(
                                "AccountSettingsCountry",
                                "Country"
                              )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>
                                  {
                                    masterData?.data?.countries.find(
                                      (ct) =>
                                        ct.id === customerInfo?.country?.id
                                    )?.localizationValue
                                  }
                                </span>
                              ) : (
                                <Field
                                  id={"country"}
                                  name={"country"}
                                  className="comboBoxInput"
                                  placeholder="Please select ..."
                                  data={masterData?.data?.countries ?? []}
                                  textField="localizationValue"
                                  value={formRenderProps.valueGetter("country")}
                                  loading={!masterData?.data?.countries}
                                  component={CustomComboBox}
                                  dataItemKey="id"
                                  sorting={true}
                                  sortField={"localizationValue"}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {trans.fetchLabelKeyTranslation(
                                "AccountSettingsTimeZone",
                                "Time Zone"
                              )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>
                                  {
                                    masterData?.data?.timezones.find(
                                      (tz) =>
                                        tz.id === customerInfo?.timezone?.id
                                    )?.localizationValue
                                  }
                                </span>
                              ) : (
                                <Field
                                  id={"timezone"}
                                  name={"timezone"}
                                  className="comboBoxInput"
                                  placeholder="Please select ..."
                                  data={masterData?.data?.timezones ?? []}
                                  textField="localizationValue"
                                  value={formRenderProps.valueGetter(
                                    "timezone"
                                  )}
                                  loading={!masterData?.data?.timezones}
                                  component={CustomComboBox}
                                  dataItemKey="id"
                                  sorting={true}
                                  sortField={"localizationValue"}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi d-flex align-items-center">
                              {trans.fetchLabelKeyTranslation(
                                "AccountSettingsLanguage",
                                "Language"
                              )}
                            </div>
                            <div className="trkCol-dot d-flex align-items-center">
                              :
                            </div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>
                                  {customerInfo?.locale?.displayName ?? "N/A"}
                                </span>
                              ) : (
                                <Field
                                  id={"locale"}
                                  name={"locale"}
                                  className="comboBoxInput"
                                  data={localeCtx?.supportedLocales ?? []}
                                  textField="displayName"
                                  value={formRenderProps.valueGetter("locale")}
                                  loading={!localeCtx?.supportedLocales}
                                  component={CustomComboBox}
                                  dataItemKey="id"
                                  sorting={true}
                                  sortField={"displayName"}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi d-flex align-items-center">
                              {trans.fetchLabelKeyTranslation(
                                "AccountSettingsMFA",
                                "MFA Modes"
                              )}
                            </div>
                            <div className="trkCol-dot d-flex align-items-center">
                              :
                            </div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>
                                  {checkMFAEnabled ? (
                                    printMfaModeNames(customerMFA?.mfaModeNames)
                                  ) : (
                                    <div
                                      className="switchButton"
                                      title={`${trans.fetchLabelKeyTranslation(
                                        "MFADisabledText",
                                        "MFA Disabled"
                                      )}`}
                                    >
                                      <Switch checked={false} disabled={true} />
                                    </div>
                                  )}
                                </span>
                              ) : (
                                <span className="btnBlocks">
                                  <div
                                    className="switchButton"
                                    title={
                                      checkMFAEnabled
                                        ? `${trans.fetchLabelKeyTranslation(
                                            "MFAEnabledText",
                                            "MFA Enabled"
                                          )}`
                                        : `${trans.fetchLabelKeyTranslation(
                                            "MFADisabledText",
                                            "MFA Disabled"
                                          )}`
                                    }
                                  >
                                    <Field
                                      id={"mfaEnabled"}
                                      name={"mfaEnabled"}
                                      value={formRenderProps.valueGetter(
                                        "mfaEnabled"
                                      )}
                                      component={CustomSwitch}
                                      onChange={compareState}
                                    />
                                  </div>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        {editing && (
                          <div className="row p-t-8">
                            <div className="col-md-12 text-right">
                              <Button
                                className="btn bg-black-5 m-r-5"
                                onClick={onUserEditCancelHandler}
                              >
                                {trans.fetchLabelKeyTranslation(
                                  "AccountSettingsBtnCancel",
                                  "Cancel"
                                )}
                              </Button>
                              <Button
                                className={`btn bg-primary text-white ${
                                  !formRenderProps.allowSubmit ||
                                  !formRenderProps.modified ||
                                  !formRenderProps.valid ||
                                  buttonStateTracker ||
                                  masterData?.telepoDisableConfig?.systemSetting
                                    .account.disableChanges
                                    ? "disabledBtn"
                                    : ""
                                }`}
                                disabled={
                                  !formRenderProps.allowSubmit ||
                                  !formRenderProps.modified ||
                                  !formRenderProps.valid ||
                                  buttonStateTracker ||
                                  masterData?.telepoDisableConfig?.systemSetting
                                    .account.disableChanges
                                }
                              >
                                {updating ? (
                                  <Loader
                                    size={"small"}
                                    type={"infinite-spinner"}
                                    themeColor={"light"}
                                  />
                                ) : (
                                  <>
                                    {trans.fetchLabelKeyTranslation(
                                      "AccountSettingsBtnSave",
                                      "Save"
                                    )}
                                  </>
                                )}
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </FormElement>
                  )}
                />
              )}
              <div>
                <Link
                  to="/customer-platform"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`btn btn-link text-primary p-0 m-t-20 fs-14 iconBtn`}
                >
                  <i className="bi bi-link-45deg m-r-5"></i>
                  {trans.fetchLabelKeyTranslation(
                    "CustomerPlatformDetailLink",
                    "Platform Details"
                  )}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
