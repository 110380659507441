import useSwal from "../../../../hooks/useSwal";
import useLocale from "../../../../hooks/useLocale";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import { Dictionary } from "../../../../types/Dictionary";
import { SweetAlertOptions } from "sweetalert2";
import { AuditLogs } from "../../../../types/audit-logs/AuditLogs";
import { ExcludedColumnsEnum } from "../../../../enums/excludedColumnsEnum";
import { Loader } from "@progress/kendo-react-indicators";
import React from "react";
import useTranslation from "../../../../hooks/useTranslation";

interface AuditLogSettingsForMobileProps extends GridCellProps {
    dataItem: AuditLogs;
    excludedColumns: string[];
    loading: boolean;
    error: string | undefined;
}

const AuditLogSettingsForMobile = (props: AuditLogSettingsForMobileProps) => {
    const trans = useTranslation("AuditLogsSettings")
    const swal = useSwal();
    const localeCtx = useLocale();
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (!localeCtx?.selectedLocale?.current.componentTranslations["AuditLogsSettings"]) {
            trans.fetchTranslations("AuditLogsSettings");
        }
    }, [localeCtx?.selectedLocale]);

    const entityName = (props.dataItem as any)?.entityFriendlyName ?? "";
    const entityType = props.dataItem.entityType?.name ?? "";
    const changedBy = props.dataItem.changedBy?.email ?? "";
    const changedAt = props.dataItem.changedAt ?? "";

    const action = props.dataItem.entityActionType?.name ?? "";
    const property = props.dataItem.fieldName ?? "";
    const oldFieldValue = props.dataItem.oldFieldValue ?? "";
    const newFieldValue = props.dataItem.newFieldValue ?? "";
    const excludedColumns = props.excludedColumns ?? [];
    const loading = props.loading;
    const error = props.error;

    return (
        <td className="listMobile">
            <div className="listRowMobile float-left p-0">
                {loading && <Loader type={"infinite-spinner"} />}
                {!loading && error && (
                    <span className="fs-15">
                        <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                        {`${trans.fetchLabelKeyTranslation(
                                "ErrMsg",
                                "Uh Oh! Something Went Wrong. Please Try Again!"
                            )
                            }`}
                    </span>
                )}

                {!loading && !error
                    &&
                    <React.Fragment>
                        <div className="float-left w-100">
                            {!excludedColumns.includes("entityFriendlyName") && (
                                <div className="text-primary font-weight-semi line-height-2">
                                    <span>
                                        {entityName}
                                    </span>
                                </div>
                            )}
                            {!excludedColumns.includes(ExcludedColumnsEnum.EntityType) && (
                                <div className="text-muted line-height-2 mx-td-spn cursor-default">
                                    <span>
                                        {entityType}
                                    </span>
                                </div>
                            )}
                            {!excludedColumns.includes(ExcludedColumnsEnum.ChangedBy) && (

                                <div className="miniTextTime float-left w-100 p-t-6">
                                    <div className="listLabelRow d-flex justify-content-start w-100 float-left line-height-1">
                                        <div className="listLabelCol">
                                            <span className="fs-11 font-weight-semi p-r-3 text-black-12">
                                                {trans.fetchLabelKeyTranslation("ChangedByColumnText", "Changed By")
                                                }:
                                            </span>
                                        </div>
                                        <div className="listLabelCol">
                                            <span className="fs-11 text-black-10">
                                                {changedBy}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="miniTextTime float-left w-100 p-t-6">
                                {!excludedColumns.includes(ExcludedColumnsEnum.ChangedAt) && (
                                    <div className="listLabelRow d-flex justify-content-start w-100 float-left line-height-1">
                                        <div className="listLabelCol">
                                            <span className="fs-11 font-weight-semi p-r-3 text-black-12">
                                                {trans.fetchLabelKeyTranslation("ChangedAtColumnText", "Changed At")
                                                }:
                                            </span>
                                        </div>
                                        <div className="listLabelCol">
                                            <span className="fs-11 text-black-10">
                                                {changedAt.toString()}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {
                                    (
                                        excludedColumns.includes(ExcludedColumnsEnum.Action) &&
                                        excludedColumns.includes(ExcludedColumnsEnum.Property) &&
                                        excludedColumns.includes(ExcludedColumnsEnum.OldValue) &&
                                        excludedColumns.includes(ExcludedColumnsEnum.NewValue)
                                    ) ?
                                        <></>
                                        :
                                        <div className="hideShowLabel fs-12 text-primary text-decoration-underline">
                                            <a
                                                onClick={() => {
                                                    setExpanded(!expanded);
                                                }}
                                                className="hideShowLink"
                                            >
                                                {expanded ? "Less" : "More"} Details
                                            </a>
                                        </div>
                                }
                            </div>
                        </div>

                        {expanded && (
                            <div className="viewMoreMobileData float-left w-100 p-r-10">
                                <div className="viewMoreDataInr float-left w-100">
                                    {!excludedColumns.includes(ExcludedColumnsEnum.Action) && (
                                        <div className="iconlabel float-left w-100 p-b-10 p-t-10">
                                            <span className="spanLabel d-flex align-items-baseline w-100">
                                                <span
                                                    className="fs-13 font-weight-semi p-r-3"
                                                    style={{ minWidth: "35px" }}
                                                >
                                                    {trans.fetchLabelKeyTranslation("ActionColumnText", "Action")
                                                    }:
                                                </span>
                                                <span className="fs-13 text-default line-height-1">
                                                    <span
                                                        className="mx-th-tag"
                                                        style={{ minWidth: "80px", maxWidth: "160px" }}
                                                    >
                                                        <div className="mx-td-spn" style={{ maxWidth: "55vw" }}>
                                                            {action}
                                                        </div>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    )}

                                    {!excludedColumns.includes(ExcludedColumnsEnum.Property) && (
                                        <div className="iconlabel float-left w-100 p-b-10 p-t-10">
                                            <span className="spanLabel d-flex align-items-baseline w-100">
                                                <span
                                                    className="fs-13 font-weight-semi p-r-3"
                                                    style={{ minWidth: "35px" }}
                                                >
                                                    {trans.fetchLabelKeyTranslation("PropertyColumnText", "Property")
                                                    }:
                                                </span>
                                                <span className="fs-13 text-default line-height-1">
                                                    <span
                                                        className="mx-th-tag"
                                                        style={{ minWidth: "80px", maxWidth: "160px" }}
                                                    >
                                                        <div className="mx-td-spn" style={{ maxWidth: "55vw" }}>
                                                            {property}
                                                        </div>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    )}

                                    {!excludedColumns.includes(ExcludedColumnsEnum.OldValue) && (
                                        <div className="iconlabel float-left w-100 p-b-10 p-t-10">
                                            <span className="spanLabel d-flex align-items-baseline w-100">
                                                <span
                                                    className="fs-13 font-weight-semi p-r-3"
                                                    style={{ minWidth: "72px" }}
                                                >
                                                    {trans.fetchLabelKeyTranslation("OldValueColumnText", "Old Value")
                                                    }:
                                                </span>
                                                <span className="fs-13 text-default line-height-1">
                                                    <span
                                                        className="mx-th-tag"
                                                        style={{ minWidth: "80px", maxWidth: "160px" }}
                                                    >
                                                        <div className="mx-td-spn" style={{ maxWidth: "55vw" }}>
                                                            {oldFieldValue}
                                                        </div>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    )}
                                    {!excludedColumns.includes(ExcludedColumnsEnum.NewValue) && (
                                        <div className="iconlabel float-left w-100 p-b-10 p-t-10">
                                            <span className="spanLabel d-flex align-items-baseline w-100">
                                                <span
                                                    className="fs-13 font-weight-semi p-r-3"
                                                    style={{ minWidth: "78px" }}
                                                >
                                                    {trans.fetchLabelKeyTranslation("NewValueColumnText", "New Value")
                                                    }:
                                                </span>
                                                <span className="fs-13 text-default line-height-1">
                                                    <span
                                                        className="mx-th-tag"
                                                        style={{ minWidth: "80px", maxWidth: "160px" }}
                                                    >
                                                        <div className="mx-td-spn" style={{ maxWidth: "55vw" }}>
                                                            {newFieldValue}
                                                        </div>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                }
            </div>
        </td>
    );
};

export default AuditLogSettingsForMobile;
