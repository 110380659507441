import React, { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { TinyUser } from "../../types/user";

type ParticipantPopupProps = {
  toggleDialog?: () => void;
  participants: TinyUser[];
};

const ParticipantPopup: React.FC<ParticipantPopupProps> = ({
  toggleDialog,
  participants,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const updateSearchText = (e: InputChangeEvent) => {
    setSearchTerm(e.value);
  };

  const searchUser = (user: TinyUser) => {
    const searchVal = searchTerm.trim().toLocaleLowerCase();

    if (user.firstName || user.lastName) {
      const name = user.firstName + " " + user.lastName;
      if (name.toLocaleLowerCase().includes(searchVal)) {
        return true;
      }
    } else {
      if (user.phoneNo && user.phoneNo.includes(searchVal)) {
        return true;
      }
    }

    if (user.email && user.email.toLocaleLowerCase().includes(searchVal)) {
      return true;
    }

    return false;
  };

  return (
    <Dialog
      className="dialogShareList"
      title={"Participant"}
      onClose={toggleDialog}
    >
      <div
        className="shareList"
        style={{ minWidth: "320px", maxWidth: "320px" }}
      >
        <div className="detailColBox">
          <div className="UserLiSearch p-b-5">
            <div className="formInput">
              <Input
                style={{ height: "32px" }}
                placeholder="Search..."
                value={searchTerm}
                onChange={updateSearchText}
              />
            </div>
          </div>
          <div
            style={{
              border: "1px solid rgba(0, 0, 0, 0.1)",
              padding: "10px 0 10px 10px",
              float: "left",
              width: "100%",
              borderRadius: "2px",
            }}
          >
            <div
              className="uLiBox"
              style={{
                maxHeight: "300px",
                minHeight: "300px",
                overflowY: "auto",
                overflowX: "hidden",
                padding: "0 10px 0 0",
              }}
            >
              {participants &&
                participants
                  .filter((u) => searchUser(u))
                  .map((s) => {
                    return (
                      <div className="uLiBoxRow" key={s.id}>
                        <div className="tblUsr">
                          <div className="topUsrAreaPic">
                            <div className="topUsrAreaPic-i">
                              <div className="contact-list-icon bg-darksteelblue">
                                <div className="contact-list-icon-txt">
                                  {s.firstName || s.lastName ? (
                                    (s.firstName == null
                                      ? ""
                                      : s.firstName.charAt(0)) +
                                    (s.lastName == null
                                      ? ""
                                      : s.lastName?.charAt(0))
                                  ) : (
                                    <span className="fs-16">
                                      <i className="bi bi-telephone"></i>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="topUsrAreaPic-tx hoverTextFull">
                              {s.firstName || s.lastName ? (
                                <span
                                  className="text-primary font-weight-semi cursor-default
                                text-decoration-none float-left w-100 line-height-2 
                                mx-td-spn viewFullWordHover"
                                  title={
                                    (s.firstName == null ? "" : s.firstName) +
                                    " " +
                                    (s.lastName == null ? "" : s.lastName)
                                  }
                                >
                                  {(s.firstName == null ? "" : s.firstName) +
                                    " " +
                                    (s.lastName == null ? "" : s.lastName)}
                                </span>
                              ) : (
                                <span
                                  className="text-primary font-weight-semi cursor-default
                                text-decoration-none float-left w-100 line-height-2 
                                mx-td-spn viewFullWordHover"
                                  title={s.phoneNo}
                                >
                                  {s.phoneNo}
                                </span>
                              )}
                              <span
                                className="text-muted fs-13 line-height-2 
                               mx-td-spn viewFullWordHover"
                                title={s.email}
                              >
                                {s.email}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ParticipantPopup;
