import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import useLocale from "../../../hooks/useLocale";
import useAuth from "../../../hooks/useAuth";
import useSwal from "../../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import customerService from "../../../services/customer.service";
import {
  CRMJobStatus,
  CRMSystemDetail,
  UpsertCRMSystemDetail,
  maskedFields,
  ZohoSessionStore,
} from "../../../types/customer/CRMSystemDetail";
import { Dictionary } from "../../../types/Dictionary";
import { configInfo } from "../../../types/customer/CRMSystemDetail";
import CustomInput from "../../../components/custom/form/CustomInput";
import useMasterData from "../../../hooks/useMasterData";
import { removeAllSpacesAndConvertToLowercase } from "../../../utils/stringUtils";
import useTranslation from "../../../hooks/useTranslation";
import { useSearchParams } from "react-router-dom";
import { ZohoCredentials } from "../../../types/customer/CRMSystemDetail";
import axios from "axios";
import { json } from "stream/consumers";

interface InitialValues {
  name: string;
  systemType: string | undefined;
  configValues: Dictionary<string> | undefined;
}

const CRMSetting: React.FC = () => {
  const trans = useTranslation("CMSSettings");
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal();
  const masterData = useMasterData();

  const formRef = useRef<any>();
  const isZohoReturned = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deletingCRMDetails, setDeletingCRMDetails] = useState<boolean>(false);
  const [upsertingCRMDetails, setUpsertingCRMDetails] =
    useState<boolean>(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState<boolean>(true);

  const [error, setError] = useState<string>();
  const [configuring, setConfiguring] = useState<boolean>(false);
  const [crmSystemDetails, setCRMSystemDetails] = useState<CRMSystemDetail>();
  const [crmJobStatus, setCrmJobStatus] = useState<CRMJobStatus>();

  const [systemTypes, setSystemTypes] = useState<String[] | undefined>();
  const [currentSystemType, setCurrentSystemType] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState<InitialValues>();
  const [refreshToken, setRefreshToken] = useState<string>();
  const [tokenRefreshed, setTokenRefreshed] = useState<boolean>();

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["CMSSettings"]
    ) {
      trans.fetchTranslations("CMSSettings");
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    const fetchCRMDetails = async () => {
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId && masterData?.data) {
        try {
          setLoading(true);
          const resp = await customerService.getCustomerCRMSystemDetails(
            customerId
          );
          setCRMSystemDetails(resp);

          setSystemTypes(
            masterData?.data?.crmSystemTypes.map((val, key) => val.name)
          );
          setCurrentSystemType(resp?.crmSystemType?.name);

          setInitialValues({
            name: resp?.name,
            systemType: resp?.crmSystemType?.name,
            configValues: resp?.crmSystemConfig,
          });
        } catch (err) {
          if (err instanceof AxiosError) {
            setSystemTypes(
              masterData?.data?.crmSystemTypes.map((val, key) => val.name)
            );
            setCurrentSystemType(
              masterData?.data?.crmSystemTypes.map((val, key) => val.name)[0]
            );

            setInitialValues({
              name: "",
              systemType: masterData?.data?.crmSystemTypes
                .map((val, key) => val.name)[0]
                .toString(),
              configValues: undefined,
            });
          }
        } finally {
          setLoading(false);
        }
      }
    };
    var url = window.location.href;
    if (url.indexOf("code") == -1 && isZohoReturned.current === false) {
      fetchCRMDetails();
    }
  }, [auth?.tokenPayload?.CustomerId, masterData?.data]);

  useEffect(() => {
    const getCRMJobStatus = async () => {
      try {
        if (auth?.tokenPayload?.CustomerId) {
          const crmJobStatus = await customerService.getCrmJobStatus(
            auth?.tokenPayload?.CustomerId
          );
          setCrmJobStatus(crmJobStatus);
        }
      } catch (err) {
        console.log(err);
      }
    };

    getCRMJobStatus();
  }, [auth?.tokenPayload?.CustomerId]);

  const toggleConfiguring = () => {
    setIsUpdateDisabled(true);
    setTokenRefreshed(false);
    setConfiguring((prevState) => !prevState);
    if (crmSystemDetails !== undefined) {
      setInitialValues({
        name: crmSystemDetails.name,
        systemType: crmSystemDetails?.crmSystemType?.name,
        configValues: crmSystemDetails?.crmSystemConfig,
      });

      const form = formRef.current as Form;
      form.values["configValues"] = { ...crmSystemDetails?.crmSystemConfig };
      form.values["name"] = crmSystemDetails?.name;
      form.values["systemType"] = crmSystemDetails?.crmSystemType?.name;
    } else {
      // When CRM details don't already exisit for the customer and we click 'Configure'. In that case, we load the configFields for the first SystemType.
      console.log(initialValues?.systemType);
      const fieldsToMatch: string[] =
        configInfo[initialValues?.systemType ? initialValues?.systemType : ""];
      const newConfigValues: Dictionary<string> = fieldsToMatch.reduce(
        (acc, field) => {
          acc[field] = ""; // Default value is an empty string
          return acc;
        },
        {} as Dictionary<string>
      );
      setCurrentSystemType(initialValues?.systemType);
      setInitialValues({
        name: "",
        systemType: masterData?.data?.crmSystemTypes
          .map((val, key) => val.name)[0]
          .toString(),
        configValues: newConfigValues,
      });
    }
  };

  const onUserConfiguringCancelHandler = () => {
    setConfiguring((prevState) => !prevState);
    const form = formRef.current as Form;

    isZohoReturned.current = false;

    const zohoSessionStore: ZohoSessionStore = JSON.parse(
      sessionStorage.getItem("zohoSessionStore") ?? ""
    ) as ZohoSessionStore;

    var prevCRM = zohoSessionStore.previousCRM;

    if (prevCRM != null) {
      const systemDetail: CRMSystemDetail = prevCRM;
      setCRMSystemDetails(systemDetail);
      setInitialValues({
        name: systemDetail.name,
        systemType: systemDetail?.crmSystemType?.name,
        configValues: systemDetail?.crmSystemConfig,
      });
      form.values["configValues"] = { ...systemDetail?.crmSystemConfig };
      setCurrentSystemType(systemDetail.crmSystemType.name);
      sessionStorage.removeItem("zohoSessionStore");
    } else if (crmSystemDetails?.crmSystemType?.name !== undefined) {
      setCurrentSystemType(crmSystemDetails?.crmSystemType?.name);
    }

    form.resetForm(); // If user enters some info and presses cancel, we want to clear the form.
  };

  useEffect(() => {
    if (tokenRefreshed == true && configuring == true) {
      const form = formRef.current as Form;
      if (form) {
        const fieldsToMatch: string[] = configInfo["Zoho"];

        const zohoSessionStore: ZohoSessionStore = JSON.parse(
          sessionStorage.getItem("zohoSessionStore") ?? ""
        ) as ZohoSessionStore;

        const newConfigValues: Dictionary<string> = fieldsToMatch.reduce(
          (acc, field) => {
            acc[field] = zohoSessionStore.formConfigValues[field];
            return acc;
          },
          {} as Dictionary<string>
        );

        /*setInitialValues((prevState) => {
          if (prevState !== undefined) {
            return {
              ...prevState,
              configValues: { ...newConfigValues },
            };
          }
        });*/

        setInitialValues({
          name: zohoSessionStore.name ?? "Zoho",
          systemType: "Zoho",
          configValues: newConfigValues,
        });

        form.values["name"] = zohoSessionStore.name;
        form.values["configValues"] = { ...newConfigValues };

        fieldsToMatch.forEach((value, index) => {
          form.values["configValues"][value] =
            zohoSessionStore.formConfigValues[value];
        });
        if (refreshToken != "invalidcode") {
          form.values["configValues"]["Refresh Token"] = refreshToken;
        }
        compareState();
        setIsUpdateDisabled(false);
      }
    }
  }, [refreshToken, configuring, tokenRefreshed]);

  useEffect(() => {
    const controller = new AbortController();

    const fetchRefreshToken = async () => {
      setLoading(true);
      var url = window.location.href;
      if (url.indexOf("code") != -1) {
        isZohoReturned.current = true;

        const fieldsToMatch: string[] = configInfo["Zoho"];
        const zohoSessionStore: ZohoSessionStore = JSON.parse(
          sessionStorage.getItem("zohoSessionStore") ?? ""
        ) as ZohoSessionStore;

        const newConfigValues: Dictionary<string> = fieldsToMatch.reduce(
          (acc, field) => {
            acc[field] = zohoSessionStore.formConfigValues[field];
            return acc;
          },
          {} as Dictionary<string>
        );

        var grantToken = searchParams.get("code");

        let zohoCredentials: ZohoCredentials = {
          domain: newConfigValues["Domain Suffix"],
          client_id: newConfigValues["Client Id"],
          client_secret: newConfigValues["Client Secret"],
          redirect_uri: zohoSessionStore.redirectUri ?? "",
          grantToken: grantToken ?? "",
        };

        var reToken = await customerService
          .fetchZohoRefreshToken(zohoCredentials, controller)
          .then((value) => {
            if (value == "invalidcode") {
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "error",
                title: `${trans.fetchLabelKeyTranslation(
                  "SwtAltErrorTitle",
                  "Error"
                )}`,
                text: `${trans.fetchLabelKeyTranslation(
                  "SwtInvalidZohoCodeText",
                  "Could not fetch refresh token, please try again"
                )}`,
              };
              swal.fire(swalOptions);
            } else {
              setRefreshToken(value);
            }

            var prevCRM = zohoSessionStore.previousCRM;
            if (prevCRM) setCRMSystemDetails(prevCRM);
            else setCRMSystemDetails(undefined);
            setConfiguring(true);
            setTokenRefreshed(true);
            setCurrentSystemType("Zoho");
            setSystemTypes(zohoSessionStore.systemTypes);
          })
          .catch((err) => {
            console.log(err);
            if (axios.isCancel(err)) {
              console.log("successfully aborted");
            } else if (err instanceof AxiosError) {
              console.error(err);
              swal.fire({
                icon: "error",
                title: `${trans.fetchLabelKeyTranslation(
                  "SwtAltErrorTitle",
                  "Error"
                )}`,
                text: `${err.message}`,
              });
            }
          })
          .finally(() => {
            setLoading(false);
            window.history.replaceState(
              null,
              "",
              window.location.href.split("?")[0] + "?selectedtab=7"
            );
          });
      }
    };

    fetchRefreshToken();

    return () => {
      controller.abort();
    };
  }, []);

  const generateGrantTokenZoho = () => {
    const form = formRef.current as Form;
    const fieldsToMatch: string[] = configInfo["Zoho"];
    const formConfigValues: Dictionary<string> = {};

    const zohoSessionStore: ZohoSessionStore = {
      formConfigValues: formConfigValues,
    };
    zohoSessionStore.formConfigValues = {};

    fieldsToMatch.forEach((value, index) => {
      zohoSessionStore.formConfigValues[value] =
        form.values.configValues[value];
    });
    zohoSessionStore.name = form.values["name"];
    zohoSessionStore.systemTypes = masterData?.data?.crmSystemTypes.map(
      (val, key) => val.name
    );

    var redirectUri = window.location.href.split("?")[0] + "?selectedtab=7";
    zohoSessionStore.redirectUri = redirectUri;
    zohoSessionStore.previousCRM = crmSystemDetails;

    sessionStorage.setItem(
      "zohoSessionStore",
      JSON.stringify(zohoSessionStore)
    );

    let grantTokenURL: string =
      "https://accounts.zoho." +
      form.values.configValues["Domain Suffix"] +
      "/oauth/v2/auth?scope=ZohoCRM.modules.all,ZohoCRM.bulk.all,ZohoCRM.users.all,ZohoCRM.org.READ,AAAserver.profile.all&client_id=" +
      form.values.configValues["Client Id"] +
      "&response_type=code&access_type=offline&redirect_uri=" +
      redirectUri;
    window.location.replace(grantTokenURL);
  };

  const onUserDeleteCRMDetails = async () => {
    try {
      setDeletingCRMDetails(true);
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId && crmSystemDetails) {
        await customerService.deleteCustomerCRMSystemDetails(customerId);
        setCRMSystemDetails(undefined);
        setInitialValues({
          name: "",
          systemType: masterData?.data?.crmSystemTypes.map(
            (val, key) => val.name
          )[0],
          configValues: undefined,
        });
        swal.fire({
          icon: "success",
          confirmButtonText: `${trans.fetchLabelKeyTranslation(
            "SwtAltOkText",
            "Ok"
          )}`,
          title: `${trans.fetchLabelKeyTranslation(
            "SwtAltDeleteSuccessTitle",
            "Customer CRM System Details deleted"
          )}`,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err);
        swal.fire({
          icon: "error",
          title: `${trans.fetchLabelKeyTranslation(
            "SwtAltErrorTitle",
            "Error"
          )}`,
          text: `${err.message}`,
        });
      }
    } finally {
      setDeletingCRMDetails(false);
    }
  };

  const crmConfigureSubmitHandler = async (dataItem: {
    [name: string]: any;
  }) => {
    try {
      setUpsertingCRMDetails(true);
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId) {
        const fieldsToMatch: string[] =
          configInfo[currentSystemType ? currentSystemType : ""];

        const filteredConfigValues: Dictionary<string> = Object.fromEntries(
          Object.entries(dataItem?.configValues).filter(([key]) =>
            fieldsToMatch.includes(key)
          )
        ) as Dictionary<string>;

        let updatedCRMDetails: UpsertCRMSystemDetail = {
          id: crmSystemDetails ? crmSystemDetails.id : 0,
          name: dataItem?.name,
          crmSystemType: currentSystemType ? currentSystemType : "",
          crmSystemConfig: filteredConfigValues,
        };

        let updatedCRM: CRMSystemDetail =
          await customerService.upsertCustomerCRMSystemDetails(
            customerId,
            updatedCRMDetails
          );
        setCRMSystemDetails(updatedCRM);
        setInitialValues({
          name: updatedCRM?.name,
          systemType: updatedCRM?.crmSystemType?.name,
          configValues: updatedCRM?.crmSystemConfig,
        });
        setConfiguring(false);
        sessionStorage.removeItem("zohoSessionStore");
        isZohoReturned.current = false;
        // setManagedBySystem(false);
        swal.fire({
          icon: "success",
          confirmButtonText: `${trans.fetchLabelKeyTranslation(
            "SwtAltOkText",
            "Ok"
          )}`,
          title: `${trans.fetchLabelKeyTranslation(
            "SwtAltConfigureSuccessTitle",
            "CRM System Details configured"
          )}`,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err);
        swal.fire({
          icon: "error",
          title: `${trans.fetchLabelKeyTranslation(
            "SwtAltErrorTitle",
            "Error"
          )}`,
          text: `${err.message}`,
        });
      }
    } finally {
      setUpsertingCRMDetails(false);
    }
  };

  const onSystemTypeChangeHandler = (e: ComboBoxChangeEvent) => {
    setCurrentSystemType(e.value);
    if (e.value) {
      const fieldsToMatch: string[] = configInfo[e.value];
      const newConfigValues: Dictionary<string> = fieldsToMatch.reduce(
        (acc, field) => {
          acc[field] = ""; // Default value is an empty string
          return acc;
        },
        {} as Dictionary<string>
      );

      setInitialValues((prevState) => {
        if (prevState !== undefined) {
          return {
            ...prevState,
            configValues: { ...newConfigValues },
          };
        }
      });
      const form = formRef.current as Form;
      form.values["configValues"] = { ...newConfigValues };
    }
  };

  const inputFieldValidator = (value: string) => {
    if (!value || value.trim().length === 0) {
      return `${trans.fetchLabelKeyTranslation(
        "ValidationMssg",
        "This field is mandatory."
      )}`;
    }
    return "";
  };

  const compareState = () => {
    const form = formRef.current as Form;
    const fieldsToMatch: string[] =
      configInfo[currentSystemType ? currentSystemType : ""];

    const isConfigsDifferent = (): boolean => {
      const hasIncorrectValue: boolean = fieldsToMatch.some((key) => {
        const val = form?.values["configValues"][key];
        if (initialValues && initialValues["configValues"]) {
          if (initialValues["configValues"][key] !== val) {
            return true;
          }
        }
      });
      return hasIncorrectValue;
    };

    const isConfigEmpty = (): boolean => {
      const hasEmptyValue: boolean = fieldsToMatch.some((key) => {
        const val = form?.values["configValues"][key];
        if (val == "") {
          return true;
        }
      });
      return hasEmptyValue;
    };

    if (
      (isConfigsDifferent() && isConfigEmpty() == false) ||
      (initialValues?.name !== form?.values?.name) == true ||
      (initialValues?.systemType !== currentSystemType) == true
    ) {
      setIsUpdateDisabled(false);
    } else {
      setIsUpdateDisabled(true);
    }
  };

  // const configField = (fieldName: string, formRenderProps: FormRenderProps) => {
  //   const form = formRef.current as Form;
  //   console.log(initialValues)
  //   if (configValues !== undefined) {
  //     const currVal = configValues[fieldName];
  //     //formRenderProps.valueGetter("name")
  //     switch (fieldName) {
  //       default: return (
  //         <Field
  //           // className="textInput"
  //           // id={fieldName}
  //           name={fieldName}
  //           // required={true}
  //           placeholder={fieldName + "..."}
  //           // value={formRenderProps.valueGetter("configValues")[fieldName].toString()}
  //           value={formRenderProps.valueGetter("name")}
  //           component={CustomInput}
  //         // validator={inputFieldValidator}
  //         // onChange={(e) => updateConfigValues(fieldName, e.value)}
  //         />
  //       )
  //     }
  //   }
  // }

  return (
    <div className="m-b-10">
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container viewBoxForm">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">
                  {trans.fetchLabelKeyTranslation("DetailsText", "CRM Details")}
                </span>
                <span className="float-right">
                  {crmSystemDetails && !configuring && (
                    <Button
                      className={`btn bg-transparent text-black-12 text-primary m-r-5 p-r-7 p-l-7 ${
                        masterData?.telepoDisableConfig?.systemSetting.crm
                          .disableChanges
                          ? "disabledBtn"
                          : "border-primary"
                      }`}
                      style={{ height: "26px" }}
                      onClick={toggleConfiguring}
                      disabled={
                        masterData?.telepoDisableConfig?.systemSetting.crm
                          .disableChanges
                      }
                    >
                      <span className="p-r-5">
                        {trans.fetchLabelKeyTranslation("EditBtnText", "Edit")}
                      </span>
                      <i className="bi bi-pencil"></i>
                    </Button>
                  )}
                  {crmSystemDetails && !configuring && (
                    <Button
                      className={`btn bg-transparent text-black-12 text-primary m-r-5 p-r-7 p-l-7 ${
                        masterData?.telepoDisableConfig?.systemSetting.crm
                          .disableChanges
                          ? "disabledBtn"
                          : "border-primary"
                      }`}
                      style={{ height: "26px" }}
                      onClick={onUserDeleteCRMDetails}
                      disabled={
                        masterData?.telepoDisableConfig?.systemSetting.crm
                          .disableChanges
                      }
                    >
                      <span className="p-r-5">
                        {trans.fetchLabelKeyTranslation(
                          "DeleteBtnText",
                          "Delete"
                        )}
                      </span>
                      {deletingCRMDetails ? (
                        <Loader
                          type={"infinite-spinner"}
                          size={"small"}
                          themeColor={"primary"}
                        />
                      ) : (
                        <i className="bi bi-trash3"></i>
                      )}
                    </Button>
                  )}
                </span>
              </div>
              {loading && (
                <div className="m-t-30 text-center">
                  <Loader type={"infinite-spinner"} />
                </div>
              )}

              <div className="row">
                <div className="col-md-12">
                  {!crmSystemDetails && !error && !configuring && !loading && (
                    <div className="float-left w-100 p-t-20 p-b-20">
                      <div className="border-w-2 border-c-2 border-black-4 border-dashed radius-5 p-15 p-l-20 d-flex align-items-center">
                        <div className="itmeBoxBtn float-left w-100">
                          <div className="storageListPlaceholder p-20">
                            <div className="text-center p-b-5">
                              <span className="tx-red">
                                {trans.fetchLabelKeyTranslation(
                                  "InfoText",
                                  "You have not set up CRM Service. Please configure your CRM Service details."
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <Button
                                onClick={toggleConfiguring}
                                className={`btn bg-primary text-white ${
                                  masterData?.telepoDisableConfig?.systemSetting
                                    .crm.disableChanges
                                    ? "disabledBtn"
                                    : ""
                                }`}
                                style={{ height: "40px", width: "150px" }}
                                disabled={
                                  masterData?.telepoDisableConfig?.systemSetting
                                    .crm.disableChanges
                                }
                              >
                                {trans.fetchLabelKeyTranslation(
                                  "ConfigBtnText",
                                  "Configure"
                                )}
                                <i className="bi bi-gear-wide-connected fs-20 m-l-8"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {!loading && !error && (crmSystemDetails || configuring) && (
                <Form
                  onSubmit={crmConfigureSubmitHandler}
                  ref={formRef}
                  initialValues={initialValues}
                  ignoreModified={true}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                      <div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {trans.fetchLabelKeyTranslation(
                                "NameText",
                                "Name"
                              )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            {configuring ? (
                              <Field
                                className="textInput"
                                id={"name"}
                                name={"name"}
                                required={true}
                                placeholder={"Name..."}
                                value={formRenderProps.valueGetter("name")}
                                component={CustomInput}
                                validator={inputFieldValidator}
                                onChange={compareState}
                              />
                            ) : (
                              <>
                                <span>{crmSystemDetails?.name}</span>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi d-flex align-items-center">
                              {trans.fetchLabelKeyTranslation(
                                "SystemTypeText",
                                "System Type"
                              )}
                            </div>
                            <div className="trkCol-dot d-flex align-items-center">
                              :
                            </div>
                            {/* <div className="trkCol-p"> */}
                            {configuring ? (
                              <ComboBox
                                className="comboBoxInput"
                                name="systemType"
                                data={systemTypes}
                                defaultValue={initialValues?.systemType}
                                onChange={onSystemTypeChangeHandler}
                              />
                            ) : (
                              crmSystemDetails?.crmSystemType?.name
                            )}
                            {/* </div> */}
                          </div>
                        </div>

                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi text-decoration-underline">
                              {trans.fetchLabelKeyTranslation(
                                "ConfigurationText",
                                "Configuration"
                              )}
                            </div>
                            {/* <div className="trkCol-dot">:</div> */}
                          </div>
                        </div>
                        {Object.entries(configInfo).map(
                          (val: [string, string[]], key: number) => {
                            return (
                              <div key={key}>
                                {val[0].toString() ===
                                  (configuring
                                    ? currentSystemType
                                    : initialValues?.systemType) && (
                                  <>
                                    {val[1].map(
                                      (value: string, index: number) => {
                                        return (
                                          <div
                                            className="trk-row border-bottom-solid border-w-1 border-black-1"
                                            key={index}
                                          >
                                            <div className="trkCol">
                                              <div className="trkCol-h font-weight-semi">
                                                {trans.fetchLabelKeyTranslation(
                                                  `Configs_${removeAllSpacesAndConvertToLowercase(
                                                    val[0] + "_" + value
                                                  )}_Text`,
                                                  `${value}`
                                                )}
                                              </div>
                                              {/* The first parameter of the above fetchLabelKeyTranslation will give us text like - Configs_agile_apiurl_Text */}
                                              <div className="trkCol-dot">
                                                :
                                              </div>
                                              <div
                                                className="trkCol-p"
                                                key={index}
                                              >
                                                {configuring &&
                                                initialValues &&
                                                initialValues[
                                                  "configValues"
                                                ] ? (
                                                  <Field
                                                    className="textInput"
                                                    id={value.toString()}
                                                    name={`configValues['${value}']`} // This represents the configValues inside initialValues
                                                    required={true}
                                                    type={
                                                      maskedFields.has(
                                                        removeAllSpacesAndConvertToLowercase(
                                                          value
                                                        )
                                                      )
                                                        ? "password"
                                                        : undefined
                                                    }
                                                    placeholder={
                                                      trans.fetchLabelKeyTranslation(
                                                        `Configs_${removeAllSpacesAndConvertToLowercase(
                                                          val[0] + "_" + value
                                                        )}_Text`,
                                                        `${value}`
                                                      ) + "..."
                                                    }
                                                    value={
                                                      initialValues[
                                                        "configValues"
                                                      ][value]
                                                    }
                                                    component={CustomInput}
                                                    validator={
                                                      inputFieldValidator
                                                    }
                                                    onChange={compareState}
                                                  />
                                                ) : (
                                                  // value={formRenderProps.valueGetter("configValues") ? formRenderProps.valueGetter("configValues")[value] : ''}
                                                  // configField(value, formRenderProps)
                                                  <>
                                                    {maskedFields.has(
                                                      removeAllSpacesAndConvertToLowercase(
                                                        value
                                                      )
                                                    ) ? (
                                                      <span>
                                                        {`\u25CF`.repeat(20)}
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        {crmSystemDetails !==
                                                          undefined &&
                                                        crmSystemDetails.crmSystemConfig !==
                                                          undefined
                                                          ? crmSystemDetails
                                                              .crmSystemConfig[
                                                              value
                                                            ]
                                                          : ""}
                                                      </span>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </div>
                            );
                          }
                        )}
                        {configuring && (
                          <div className="row p-t-8">
                            <div className="col-md-12 text-right">
                              <Button
                                onClick={onUserConfiguringCancelHandler}
                                className="btn bg-black-5 m-r-5"
                              >
                                {trans.fetchLabelKeyTranslation(
                                  "CancelBtnText",
                                  "Cancel"
                                )}
                              </Button>

                              {(configuring
                                ? currentSystemType
                                : initialValues?.systemType) == "Zoho" && (
                                <Button
                                  onClick={generateGrantTokenZoho}
                                  type={"button"}
                                  className="btn bg-black-5 m-r-5"
                                >
                                  {trans.fetchLabelKeyTranslation(
                                    "GenerateTokenBtnText",
                                    "Generate Token"
                                  )}
                                </Button>
                              )}

                              <Button
                                type={"submit"}
                                className={`btn bg-primary text-white ${
                                  isUpdateDisabled ||
                                  masterData?.telepoDisableConfig?.systemSetting
                                    .crm.disableChanges
                                    ? "disabledBtn"
                                    : ""
                                }`}
                                disabled={
                                  isUpdateDisabled ||
                                  masterData?.telepoDisableConfig?.systemSetting
                                    .crm.disableChanges
                                }
                              >
                                {upsertingCRMDetails ? (
                                  <Loader
                                    size={"small"}
                                    type={"infinite-spinner"}
                                    themeColor={"light"}
                                  />
                                ) : (
                                  <>
                                    {trans.fetchLabelKeyTranslation(
                                      "UpdateBtnText",
                                      "Update"
                                    )}
                                  </>
                                )}
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </FormElement>
                  )}
                />
              )}
            </div>
            {crmSystemDetails &&
              !configuring &&
              crmJobStatus &&
              !loading &&
              !error && (
                <p className="text-muted m-0">
                  <b>
                    <i className="bi bi-arrow-repeat mr-1"></i>
                    {trans.fetchLabelKeyTranslation(
                      "LastSuccessfulSyncText",
                      "Last successful sync"
                    )}
                    :
                  </b>{" "}
                  {crmJobStatus.lastSyncDatetime} |{" "}
                  <b>
                    <i className="bi bi-person-rolodex mr-1"></i>
                    {trans.fetchLabelKeyTranslation(
                      "TotalContactsSyncedText",
                      "Total Contacts Synced"
                    )}
                    :
                  </b>{" "}
                  {crmJobStatus.contactsSynced}
                </p>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CRMSetting;
