import { useState } from "react";
import { TinyUser } from "../../types/user";
import {
  concatUsername,
  getRandomProfileColor,
  getUserInitials,
} from "../../utils/profileUtils";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import CustomUserGridInfo from "./grid/CustomUserGridInfo";
import { apLocaleKeyName } from "../../pages/settings/user-control/authorization-profile/AuthorizationProfiles";

interface ICustomUserList {
  userList: TinyUser[];
  maxDisplay?: number | undefined;
  title:string;
  fetchLabelKeyTranslation: (key: string, defaultValue: string) => string
}

const CustomUserList: React.FC<ICustomUserList> = ({
  userList,
  maxDisplay,
  title,
  fetchLabelKeyTranslation
}) => {
  const [show, setShow] = useState(false);
  const [userSearch, setUserSearch] = useState<string>("");
  const endIndex = maxDisplay !== undefined && maxDisplay > 0 ? maxDisplay : 4;

  const handleExpandClick = () => {
    setShow(true);
  };

  const onCloseDialog=()=>{
    setShow(false);
  }

  const handleAssignedUserSearchChange = (event: any) => {
    setUserSearch(event.value);
  };

  const searchUser = (userDetails: TinyUser, searchTerm: string) => {
    var searchText = searchTerm.trim().toLocaleLowerCase();
    var firstName = userDetails.firstName ? userDetails?.firstName : "";
    var lastName = userDetails.lastName ? userDetails.lastName : "";
    var userName = `${firstName} ${lastName}`.trim();
    if (
      userDetails?.email?.toLocaleLowerCase().includes(searchText) ||
      userName?.toLocaleLowerCase().includes(searchText)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="tblUsr moreUsr users-small mb-2">
      <div className="tblUserRow">
        {userList &&
          userList.length > 0 &&
          userList?.slice(0, endIndex).map((user) => {
            const profileColor = getRandomProfileColor(user?.id);
            const userName = concatUsername(
              user?.firstName,
              user?.lastName
            ).trim();
            let initials = getUserInitials(user?.firstName, user?.lastName);
            initials =
              initials !== "" ? initials : getUserInitials(user?.email, "");
            return (
              <div className="topUsrAreaPic zoomPic" key={user?.id}>
                <div className="topUsrAreaPic-i">
                  <div
                    className={`contact-list-icon cursor-pointer ${profileColor}`}
                    title={userName === "" ? user.email : userName}
                  >
                    <div className="contact-list-icon-txt">{initials}</div>
                  </div>
                </div>
              </div>
            );
          })}
        {userList && userList.length > 0 && userList.length > endIndex && (
          <div
            className="topUsrAreaPic zoomPic"
            onClick={handleExpandClick}
          >
            <div className="topUsrAreaPic-i">
              <div className="contact-list-icon cursor-pointer bg-darksteelblue">
                <div className="contact-list-icon-txt">
                  +{userList.length - endIndex}
                </div>
              </div>
            </div>
          </div>
        )}
        {show && (
          <Dialog
            title={fetchLabelKeyTranslation(
              apLocaleKeyName[
                title
              ] ?? "",
              title
            )}
            onClose={onCloseDialog}
            className="dialogAddUserAuth"
          >
            <div className="userListingData userListingAllUsers mh-100" style={{ maxWidth: "450px" }}>
              <div className="UserLiSearch p-b-5">
                <div className="formInput">
                  <Input
                    className="form-control"
                    style={{
                      height: "32px",
                      background: "rgba(255,255,255,.6)",
                    }}
                    placeholder={"Search..."}
                    value={userSearch}
                    onChange={handleAssignedUserSearchChange}
                  />
                </div>
              </div>
              <div
                className="detailColBox scroller"
                style={{ height: "290px", overflow: "hidden auto" }}
              >
              {userList
                  .filter((el) => searchUser(el, userSearch)).length>0 ? (
                  <>
                    {userList
                    .filter((el) => searchUser(el, userSearch))
                    .map((user) => {
                      var _user: TinyUser = {
                        id: user.id,
                        loginUserId: user.loginUserId,
                        firstName: user.firstName ?? "",
                        lastName: user.lastName ?? "",
                        email: user.email ?? "",
                        phoneNo: "",
                        image: user.image ?? "",
                        isArchived: user.isArchived
                      };
                      return (
                        <div className="UserRow w-100 float-left p-t-10 p-b-10 border-bottom-solid border-w-1 border-black-1">
                          <div className="tblUsr d-flex justify-content-between w-100">
                            <CustomUserGridInfo userInfo={_user} />
                          </div>
                        </div>
                      );
                    })}
                  </>
                  ) : (
                  <>
                    <p className="text-center m-t-10">No user found</p>
                  </>
                )}
              </div>
            </div>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default CustomUserList;
