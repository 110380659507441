import React, { useEffect } from "react";
import useLocale from "../../hooks/useLocale";
import useTranslation from "../../hooks/useTranslation";

type ICallDirectionProps = {
  callDirection: string;
  callType: string;
  iconSize?: number | undefined;
  isMobileView?: boolean
};

const CallDirection: React.FC<ICallDirectionProps> = ({ callDirection, callType, iconSize, isMobileView = false }) => {
  const localeCtx = useLocale();
  const trans = useTranslation("CallDirection");

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["CallDirection"]) {
      trans.fetchTranslations("CallDirection");
    }
  }, [localeCtx?.selectedLocale]);

  var fontSize = iconSize !== undefined && iconSize > 0 ? iconSize : 18;
  return (
    <>
      {callDirection === "Received" && callType === "External" && (
        <span className={`fs-${fontSize} tx-green`}
          title={trans.fetchLabelKeyTranslation("ReceivedText", "Received")}
        >
          {isMobileView ? trans.fetchLabelKeyTranslation("ReceivedText", "Received")
            : <i className="bi bi-arrow-left"></i>}
        </span>
      )}
      {callDirection === "Dialled" && callType === "External" && (
        <span className={`fs-${fontSize} text-info`}
          title={trans.fetchLabelKeyTranslation("DialledText", "Dialled")}
        >
          {isMobileView ? trans.fetchLabelKeyTranslation("DialledText", "Dialled")
            : <i className="bi bi-arrow-right"></i>}
        </span>
      )}
      {callDirection === "Received" && callType === "Internal" && (
        <span className={`fs-${fontSize} text-muted`}
          title={trans.fetchLabelKeyTranslation("InternalReceivedText", "Internal Received")}
        >
          {isMobileView ? trans.fetchLabelKeyTranslation("InternalReceivedText", "Internal Received")
            : <i className="bi bi-arrow-left"></i>}
        </span>
      )}
      {callDirection === "Dialled" && callType === "Internal" && (
        <span className={`fs-${fontSize} text-muted`}
          title={trans.fetchLabelKeyTranslation("InternalDialledText", "Internal Dialled")}
        >
          {isMobileView ? trans.fetchLabelKeyTranslation("InternalDialledText", "Internal Dialled")
            : <i className="bi bi-arrow-right"></i>}
        </span>
      )}
    </>
  );
};

export default CallDirection;
