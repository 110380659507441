import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import React, { useEffect, useRef, useState } from "react";
import useLocale from "../../../../hooks/useLocale";
import useSwal from "../../../../hooks/useSwal";
import { Dictionary } from "../../../../types/Dictionary";
import CustomInput from "../../../../components/custom/form/CustomInput";
import useAuth from "../../../../hooks/useAuth";
import { SweetAlertOptions } from "sweetalert2";
import {
  ParticipantNumber,
  UpdateParticipantNumber,
} from "./RecordingPolicySettings";
import recordingPolicySettingsService from "../../../../services/recordingPolicySettings.service";
import useTranslation from "../../../../hooks/useTranslation";

interface RecordingPolicyDialogProps {
  toggleDialog: () => void;
  participantNumberEdit?: ParticipantNumber;
  upsertRecordingPolicyData: (
    updatedParticipantNumber?: ParticipantNumber,
    insertedParticipantNumber?: ParticipantNumber[]
  ) => void;
  participantNumbers: ParticipantNumber[];
  setPageFreezeLoader: React.Dispatch<React.SetStateAction<boolean>>;
  disabledTelepoConfig: boolean;
}

const UpsertRecordingPolicyDialog: React.FC<RecordingPolicyDialogProps> = ({
  toggleDialog,
  participantNumberEdit,
  upsertRecordingPolicyData,
  participantNumbers,
  setPageFreezeLoader,
  disabledTelepoConfig,
}) => {
  const trans = useTranslation("UpsertRecordingPolicyDialog");
  const auth = useAuth();
  const localeCtx = useLocale();
  const swal = useSwal();
  const formRef = useRef<any>();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "UpsertRecordingPolicyDialog"
      ]
    ) {
      trans.fetchTranslations("UpsertRecordingPolicyDialog");
    }
  }, [localeCtx?.selectedLocale]);

  const participantNumberValidator = (value: string) => {
    const re = /^[a-zA-Z0-9+@._éàèùçâêîôûëïüåß-]*$/;
    if (value.length > 50) {
      return trans.fetchLabelKeyTranslation(
            "PNNameValidationText2",
            "Participant number length cannot be more than 50!"
          );
    }
    if (!re.test(value)) {
      return trans.fetchLabelKeyTranslation(
            "PNNameValidationText3",
            "Participant Number invalid"
          );
    }

    return "";
  };

  const handleAdd = (insertRecordingPolicyVal: string[]) => {
    setLoading(true);
    setPageFreezeLoader(true);
    recordingPolicySettingsService
      .addParticipantNumber(
        insertRecordingPolicyVal,
        auth?.tokenPayload?.CustomerId
      )
      .then(async (response) => {
        setPageFreezeLoader(false);
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: trans.fetchLabelKeyTranslation(
                "SuccessTitleAdded",
                `Participant Number Excluded successfully`
              ),
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: trans.fetchLabelKeyTranslation("SuccessConfirmBtnText", `OK`),
        };
        toggleDialog();
        await swal.fire(swalOptions);
        upsertRecordingPolicyData(undefined, response);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setError(
            `${
              trans.fetchLabelKeyTranslation(
                    "SwtPNCustomerNotFound",
                    "Customer not found."
                  )
            }`
          );
        } else {
          setError(
            `${
              trans.fetchLabelKeyTranslation(
                    "SwtPNCreatedFailedText",
                    "Something went wrong."
                  )
            }`
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setPageFreezeLoader(false);
      });
  };

  const handleUpdate = (updateRecordingPolicyVal: UpdateParticipantNumber) => {
    setLoading(true);
    setPageFreezeLoader(true);
    recordingPolicySettingsService
      .updateParticipantNumber(
        updateRecordingPolicyVal,
        participantNumberEdit?.id,
        auth?.tokenPayload?.CustomerId
      )
      .then(async (response) => {
        setPageFreezeLoader(false);
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: trans.fetchLabelKeyTranslation(
                "SuccessTitleUpdated",
                `Excluded Number Updated`
              ),
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: trans.fetchLabelKeyTranslation("SuccessConfirmBtnText", `Okay`),
        };
        toggleDialog();
        await swal.fire(swalOptions);
        upsertRecordingPolicyData(response, undefined);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setError(
            `${
              trans.fetchLabelKeyTranslation(
                    "PNErrorParticipantNotFound",
                    "Participant number not found."
                  )
            }`
          );
        } else {
          setError(
            `${
              trans.fetchLabelKeyTranslation(
                    "SwtPNCreatedFailedText",
                    "Something went wrong."
                  )
            }`
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setPageFreezeLoader(false);
      });
  };

  const submitHandler = () => {
    setError(undefined);
    const form = formRef.current as Form;
    if (
      participantNumbers.find(
        (number: ParticipantNumber) =>
          number.participantNumber === form.values.participantNumber
      )
    ) {
      setError(
        trans.fetchLabelKeyTranslation(
              "PNErrorParticipantNumberSame",
              "Participant number already exist."
            )
      );
      return;
    }
    const recordingPolicyCreateVal: string[] = participantNumbers.map(
      (number: ParticipantNumber) => {
        return number.participantNumber;
      }
    );
    recordingPolicyCreateVal.push(form.values.participantNumber);
    const recordingPolicyUpdateVal: UpdateParticipantNumber = {
      excludedNumber: form.values.participantNumber,
    };
    !participantNumberEdit
      ? handleAdd(recordingPolicyCreateVal)
      : handleUpdate(recordingPolicyUpdateVal);
  };

  const compareState = () => {
    const form = formRef.current as Form;
    if (
      form.values.participantNumber == participantNumberEdit?.participantNumber
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  return (
    <Form
      ref={formRef}
      initialValues={{
        participantNumber: participantNumberEdit?.participantNumber ?? "",
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              participantNumberEdit
                ? `${
                    trans.fetchLabelKeyTranslation(
                          "UpsertRPTitleEdit",
                          "Edit Participant Number"
                        )
                  }`
                : `${
                    trans.fetchLabelKeyTranslation(
                          "UpsertRPTitleAdd",
                          "Exclude Participant"
                        )
                  }`
            }
            onClose={toggleDialog}
          >
            <div className="formAdd" style={{ marginBottom: "0px", minWidth: "250px" }}>
              {error && <span className="tx-red">{error}</span>}
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="participantNumber"
                      name="participantNumber"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("participantNumber")}
                      placeholder={`${
                        trans.fetchLabelKeyTranslation(
                              "UpsertRPNamePlaceHolderText",
                              "Enter number to exclude"
                            )
                      }`}
                      component={CustomInput}
                      validator={participantNumberValidator}
                      onChange={compareState}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button disabled={loading} onClick={toggleDialog}>
                {`${
                  trans.fetchLabelKeyTranslation(
                        "UpsertCancelButtonText",
                        "Cancel"
                      )
                }`}
              </Button>
              <Button
                onClick={submitHandler}
                className={`k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-primary text-white ${
                  !formRenderProps.valid ||
                  buttonStateTracker ||
                  loading ||
                  disabledTelepoConfig
                    ? "disabledBtn"
                    : ""
                }`}
                disabled={
                  !formRenderProps.valid ||
                  buttonStateTracker ||
                  loading ||
                  disabledTelepoConfig
                    ? true
                    : false
                }
              >
                {loading ? (
                  <Loader
                    themeColor={"primary"}
                    size={"small"}
                    type={"infinite-spinner"}
                  />
                ) : (
                  `${
                    participantNumberEdit
                      ? `${
                          trans.fetchLabelKeyTranslation("UpdateButton", "Update")
                        }`
                      : `${
                          trans.fetchLabelKeyTranslation("AddButton", "Add")
                        }`
                  }`
                )}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default UpsertRecordingPolicyDialog;
