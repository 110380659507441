export const titleMap : any = {
    "/settings/user-control/user": ["UserProfileDocTitle", "User Profile"],
    "/login": ["LoginDocTitle", "Login"],
    "/share": ["ShareDocTitle", "Share"],
    "/not-found": ["NotFoundDocTitle", "Not Found"],
    "/error": ["ErrorDocTitle", "Error"],
    "/unauthorized": ["UnauthorizedDocTitle", "Unauthorized"],
    "/unauthorized-calldetail": ["UnauthorizedCallDetailDocTitle", "Unauthorized Call Detail"],
    "/calldetail": ["CallDetailDocTitle", "Call Detail"],
    "/shared/calldetail": ["CallDetailDocTitle", "Call Detail"],
    "/service-unreachable": ["ServiceUnreachableDocTitle", "Service Unreachable"],
    "/customer-platform": ["PlatformDocTitle", "Platform"]
  };