const UnauthorizedAccess = () => {
  return (
    <div className="TemplateMessageOuter">
      <div className="TemplateMessageInner">
        <div className="TemplateMessageBox">
          <div className="errorContainer float-left w-100 text-center">
            <div className="errorImg">
              <div
                className="notification-msg-i"
                style={{
                  fontSize: "60px",
                  opacity: ".7",
                  padding: "0 0 30px 0",
                }}
              >
                <i className="bi bi-exclamation-triangle text-primary"></i>
              </div>
            </div>
            <div className="errorTxt">
              <div className="errorTxt-h fs-22 font-weight-semi p-b-20">
                Unauthorized Access
              </div>
              <div className="errorTxt-p fs-16 p-b-20">
                You are not authorized to view this page
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedAccess;
