import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useState } from "react";
import { Customer } from "../../../types/customer/Customer";
import useLocale from "../../../hooks/useLocale";
import useMasterData from "../../../hooks/useMasterData";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import useTranslation from "../../../hooks/useTranslation";

interface PlatformLicensesProps {
  customerInfo: Customer | undefined;
  customerError: string | undefined;
  pageFreezeLoader: boolean;
}

const PlatformLicenses: React.FC<PlatformLicensesProps> = ({
  customerInfo,
  customerError,
  pageFreezeLoader,
}) => {
  const trans = useTranslation("PlatformLicenses");
  const masterData = useMasterData();
  const localeCtx = useLocale();
  const [loading, setLoading] = useState<boolean>(pageFreezeLoader);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "PlatformLicenses"
      ]
    ) {
      trans.fetchTranslations("PlatformLicenses");
    }
  }, [localeCtx?.selectedLocale]);

  const manageRecordedUserHandler = () => {
    window.open(
      "/customer-platform?actionType=assign-license",
      "_blank",
      "noopener noreferrer"
    );
  };

  return (
    <div className="m-b-10">
      <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
        <div className="row">
          <div className="col-md-12">
            <div className="trk-container lcsBox">
              <div className="trk-inr p-b-30">
                <div className="trk-t text-black-14">
                  <span className="text-primary">
                    {" "}
                    {`${trans.fetchLabelKeyTranslation(
                      "PlatformLicensesTitle",
                      "Licence Details"
                    )}`}
                  </span>
                </div>
                <div className="accordionData float-left w-100">
                  {(loading || !masterData?.data) && (
                    <div className="d-flex align-items-center justify-content-center m-t-30">
                      <Loader type={"infinite-spinner"} />
                    </div>
                  )}
                  {!loading && customerError && (
                    <div className="float-left w-100 m-t-10">
                      <div className="selectScorecard">
                        <div>
                          <span className="tx-red fs-14">{customerError}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {!loading && !customerError && masterData?.data && (
                    <div
                      className="scroller float-left w-100"
                      style={{
                        overflow: "hidden auto",
                      }}
                    >
                      <div className="row pb-1 border-bottom-solid border-w-2 border-black-1 d-none d-lg-flex">
                        <div className="col-md-12 col-lg-5">
                          <div className="trkCol-h font-weight-semi">
                            {trans.fetchLabelKeyTranslation(
                              "LicenseName",
                              "Licence Name"
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-3 text-center">
                          <div className="trkCol-h font-weight-semi">
                            {trans.fetchLabelKeyTranslation(
                              "PurchaseAutoLearn",
                              "Purchased/Auto Learn"
                            )}
                            <i
                              title={trans.fetchLabelKeyTranslation(
                                "AutoLearnMessage",
                                "Auto Learn means no maximum usage limit for this licences. Billing will be based on your actual usage."
                              )}
                              className="bi bi-info-circle fs-10 cursor-pointer ml-1"
                            ></i>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-2 text-center">
                          <div className="trkCol-h font-weight-semi">
                            {trans.fetchLabelKeyTranslation(
                              "Consumed",
                              "Consumed"
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-2 text-center">
                          <div className="trkCol-h font-weight-semi">
                            {trans.fetchLabelKeyTranslation(
                              "Remaining",
                              "Remaining"
                            )}
                            <i
                              title={trans.fetchLabelKeyTranslation(
                                "RemainingMessage",
                                "You can assign an unlimited number of Auto Learn licences."
                              )}
                              className="bi bi-info-circle fs-10 cursor-pointer ml-1"
                            ></i>
                          </div>
                        </div>
                      </div>
                      {customerInfo?.licensePackages
                        ?.sort(
                          (a, b) => a.license.id - b.license.id || a.id - b.id
                        )
                        .map((l) => {
                          return (
                            <>
                              <div className="row border-bottom-solid border-w-1 border-black-1 mt-1 pb-1">
                                <div className="col-md-12 col-lg-5 d-flex d-sm-flex d-md-flex d-lg-block">
                                  <div className="trkCol-h font-weight-semi d-inline-block d-lg-none">
                                    {trans.fetchLabelKeyTranslation(
                                      "LicenseName",
                                      "Licence Name"
                                    )}{" "}
                                    :
                                  </div>
                                  <div className="trkCol-fs-18">
                                    {
                                      masterData?.data?.licensePackages.find(
                                        (lc) => lc.id === l.id
                                      )?.localizationValue
                                    }
                                  </div>
                                </div>
                                <div className="col-md-12 col-lg-3 d-flex d-sm-flex d-md-flex d-lg-block justify-content-lg-center">
                                  <div className="trkCol-h font-weight-semi d-inline-block d-lg-none">
                                    {trans.fetchLabelKeyTranslation(
                                      "PurchaseAutoLearn",
                                      "Purchased/Auto Learn"
                                    )}
                                    <i
                                      title={trans.fetchLabelKeyTranslation(
                                        "AutoLearnMessage",
                                        "Auto Learn means no maximum usage limit for this licences. Billing will be based on your actual usage."
                                      )}
                                      className="bi bi-info-circle fs-10 cursor-pointer ml-1"
                                    ></i>{" "}
                                    :
                                  </div>
                                  <div className="trkCol-fs-18 text-start text-lg-center">
                                    {l.isAutoLearnEnabled
                                      ? "Auto Learn Enable"
                                      : l.purchased}
                                  </div>
                                </div>
                                <div className="col-md-12 col-lg-2 d-flex d-sm-flex d-md-flex d-lg-block justify-content-lg-center">
                                  <div className="trkCol-h font-weight-semi d-inline-block d-lg-none">
                                    {trans.fetchLabelKeyTranslation(
                                      "Consumed",
                                      "Consumed"
                                    )}{" "}
                                    :
                                  </div>
                                  <div className="trkCol-fs-18 text-start text-lg-center">
                                    {l.consumed}
                                  </div>
                                </div>
                                <div className="col-md-12 col-lg-2 d-flex d-sm-flex d-md-flex d-lg-block justify-content-lg-center">
                                  <div className="trkCol-h font-weight-semi d-inline-block d-lg-none">
                                    {trans.fetchLabelKeyTranslation(
                                      "Remaining",
                                      "Remaining"
                                    )}
                                    <i
                                      title={trans.fetchLabelKeyTranslation(
                                        "RemainingMessage",
                                        "You can assign an unlimited number of Auto Learn licences."
                                      )}
                                      className="bi bi-info-circle fs-10 cursor-pointer ml-1"
                                    ></i>{" "}
                                    :
                                  </div>
                                  <div className="trkCol-fs-18 text-start text-lg-center">
                                    {!l.isAutoLearnEnabled
                                      ? l.purchased - l.consumed
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      {customerInfo?.platformDetails.length === 1 &&
                        customerInfo.platformDetails[0].platform ===
                          "MsTeams" && (
                          <Button
                            className={
                              "btn bg-primary text-white fs-13 fw-normal m-t-10"
                            }
                            onClick={manageRecordedUserHandler}
                          >
                            {trans.fetchLabelKeyTranslation(
                              "ManageRecordedUser",
                              "Manage Recorded User"
                            )}
                          </Button>
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default PlatformLicenses;
