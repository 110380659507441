import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomUserGridInfo from "../../../../components/custom/grid/CustomUserGridInfo";
import { TinyUser, User } from "../../../../types/user";
import { AccessPermissionEnum } from "../../../../enums/accessPermissionEnum";
import AddUserDialog from "./AddUserDialog";
import useLocale from "../../../../hooks/useLocale";
import { Dictionary } from "../../../../types/Dictionary";
import usersService from "../../../../services/users.service";
import useAuth from "../../../../hooks/useAuth";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { navData } from "../../../../data/NavigationData";
import NotFoundError from "../../../error/NotFoundError";
import CustomSearchFieldWithButton from "../../../../components/custom/form/CustomSearchFieldWithButton";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { CardsPageFilter } from "../../../../types/localstorage-filters/CardsPageFilter";
import React from "react";
import UnauthorizedAccess from "../../../error/UnauthorizedAccess";
import useSwal from "../../../../hooks/useSwal";
import useMasterData from "../../../../hooks/useMasterData";
import CardWordFilter from "./CardWordFilter";
import { ScrollElements } from "../../../../types/user/ScrollElements";
import useTranslation from "../../../../hooks/useTranslation";

const apLocaleKeyName: { [key: string]: string } = {
  "Super Admin": "SuperAdmin",
  "Recording Admin": "RecordingAdmin",
  "Playback Supervisor": "PlaybackSupervisor",
  "Recording Agent": "RecordingAgent",
};

const initialDataState: CardsPageFilter = {
  searchTerm: "",
  selectedTab: 0,
  selectedAlphabet: "a",
};

interface CustomTabTitleProp {
  name: string;
  count: number;
}

interface CustomTabStripTabProps {
  name: string;
}

interface CustomUserCardDetailProps {
  user: User;
}

interface UserManagementProps {}

const CustomTabTitle = (props: CustomTabTitleProp) => {
  return (
    <span>
      <span>{props.name}</span>
      <span className="tabBadge m-l-2">{props.count}</span>
    </span>
  );
};

const UserManagement: React.FC<UserManagementProps> = () => {
  const trans = useTranslation("UserManagement");
  const master = useMasterData();
  const auth = useAuth();
  const Swal = useSwal();
  const navigate = useNavigate();
  const localeCtx = useLocale();
  const location = useLocation();
  const backActive = location?.state?.backActive === true;
  const [localStorageFilter, setLocalStorageFilter] =
    useLocalStorage<CardsPageFilter>(
      "localStorageUserFilterData",
      initialDataState
    );
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [selectedAlphabet, setSelectedAlphabet] = useState<string>(
    backActive ? localStorageFilter.selectedAlphabet : "A"
  );
  const [selectedTab, setSelectedTab] = useState<number>(
    backActive ? localStorageFilter.selectedTab : 0
  );
  const [searchTerm, setSearchTerm] = useState<string>(
    backActive ? localStorageFilter.searchTerm : ""
  );
  const [addUserDialogVisible, setAddUserDialogVisible] =
    useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User>();

  const [cardScrollLocations, setCardScrollLocations] =
    useState<ScrollElements[]>();
  const [scrollAlphabet, setScrollAlphabet] = useState<string>("A");

  let isClickedAlphabet = false;

  useEffect(() => {
    const sendRequest = async () => {
      try {
        setLoading(true);
        const data = await usersService.fetchUsers();
        master?.setStandardUsers(data);
        setUsers(data);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };
    sendRequest();
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "UserManagement"
      ]
    ) {
      trans.fetchTranslations("UserManagement");
    }
  }, [localeCtx?.selectedLocale]);

  const getCurrentUser = async () => {
    try {
      const user = await usersService.getCurrentUser();
      setCurrentUser(user);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    }
  };

  const handleTabSelection = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };

  const handleFilterData = () => {
    const data: CardsPageFilter = {
      searchTerm: searchTerm,
      selectedTab: selectedTab,
      selectedAlphabet: selectedAlphabet,
    };
    setLocalStorageFilter(data);
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  useEffect(() => {
    let tempDictionary: ScrollElements[] = [];
    let tempValue = "A";

    if (cardScrollLocations?.length) {
      for (var j = 0; j < cardScrollLocations?.length; j++) {
        let inxRemoveClass = cardScrollLocations[j];
        if (inxRemoveClass.isActive) {
          inxRemoveClass.sideElement?.classList.remove("activeLetter");
        }
      }
    }

    let cardArea = document.getElementsByClassName("cardListArea")[0];

    for (var i = 0; i < 26; i++) {
      let idValue = "userCardId_" + tempValue.trim().toLocaleLowerCase();
      let eleCard = document.getElementById(idValue);
      let sideelement = document.getElementById(tempValue);

      let doesExist = cardArea.querySelector("#" + idValue);

      if (doesExist && eleCard) {
        let eleLocation = eleCard?.offsetTop;
        let elements: ScrollElements = {
          eleLocation: eleLocation,
          sideElement: sideelement,
          isActive: false,
        };
        tempDictionary.push(elements);
      }

      tempValue = String.fromCharCode(
        tempValue.trim().toLocaleUpperCase().charCodeAt(0) + 1
      );
    }
    setCardScrollLocations(tempDictionary);
  }, [selectedTab, users]);

  const handleAlphabetFilterChange = (value: string) => {
    scrollToUserCard(value);
    //scrollToUserCard(value);
  };

  const handleScrollChange = () => {
    if (!isClickedAlphabet) {
      let cardTab: string = "";
      if (selectedTab == 0) {
        cardTab = "cardId_All";
      } else if (selectedTab == 1) {
        cardTab = "cardId_Super Admin";
      } else if (selectedTab == 2) {
        cardTab = "cardId_Recording Admin";
      } else if (selectedTab == 3) {
        cardTab = "cardId_Playback Supervisor";
      } else if (selectedTab == 4) {
        cardTab = "cardId_Recording Agent";
      } else if (selectedTab == 5) {
        cardTab = "cardId_Other";
      }

      let ele = document.getElementById(cardTab);
      let eleLocation = 0;
      let eleLocationPrev = 0;

      if (cardScrollLocations?.length) {
        for (var j = 0; j < cardScrollLocations?.length; j++) {
          let inxRemoveClass = cardScrollLocations[j];
          if (inxRemoveClass.isActive) {
            inxRemoveClass.sideElement?.classList.remove("activeLetter");
          }
        }

        for (var i = 0; i < cardScrollLocations?.length; i++) {
          let inxValue = cardScrollLocations[i];
          let inxPrev = cardScrollLocations[i - 1];
          eleLocation = inxValue.eleLocation;
          eleLocationPrev = inxPrev?.eleLocation;

          if (ele?.scrollTop) {
            if (eleLocation == Math.floor(ele?.scrollTop)) {
              inxValue.sideElement?.classList.add("activeLetter");
              inxValue.isActive = true;
              break;
            } else if (
              (eleLocationPrev < Math.floor(ele?.scrollTop) &&
                eleLocation >= Math.floor(ele?.scrollTop)) ||
              ele?.scrollTop == 0
            ) {
              inxPrev.sideElement?.classList.add("activeLetter");
              inxPrev.isActive = true;
              break;
            }
          }
        }
      }
    }
  };

  const scrollToUserCard = (value: string) => {
    if (value.trim() !== "") {
      let idValue = "userCardId_" + value.trim().toLocaleLowerCase();
      var element = document.getElementById(idValue);
      if (element) {
        if (cardScrollLocations) {
          for (var j = 0; j < cardScrollLocations?.length; j++) {
            let inxRemoveClass = cardScrollLocations[j];
            if (inxRemoveClass.isActive) {
              inxRemoveClass.sideElement?.classList.remove("activeLetter");
            }

            if (inxRemoveClass.sideElement?.id == value) {
              inxRemoveClass.sideElement?.classList.add("activeLetter");
              inxRemoveClass.isActive = true;
            }
          }
        }

        isClickedAlphabet = true;
        element.scrollIntoView();
        setTimeout(() => {
          isClickedAlphabet = false;
        }, 200);
      } else {
        let tempValue = String.fromCharCode(
          value.trim().toLocaleLowerCase().charCodeAt(0) - 1
        );
        while (!element) {
          if (tempValue == "`" || tempValue == "") break;
          idValue = "userCardId_" + tempValue.trim().toLocaleLowerCase();
          element = document.getElementById(idValue);
          if (element) {
            //setSelectedAlphabet(tempValue.trim().toLocaleLowerCase());
            element.scrollIntoView({ behavior: "smooth" });
            break;
          } else if (tempValue.trim().toLocaleLowerCase() == "a") break;

          tempValue = String.fromCharCode(
            tempValue.trim().toLocaleLowerCase().charCodeAt(0) - 1
          );
        }
      }
    }
  };

  const searchUsers = (user: User) => {
    const searchVal = searchTerm.trim().toLocaleLowerCase();
    const userName = user.firstName + " " + user.lastName;
    if (
      user.firstName &&
      user.firstName.toLocaleLowerCase().includes(searchVal)
    )
      return true;
    if (user.lastName && user.lastName.toLocaleLowerCase().includes(searchVal))
      return true;
    if (user.email?.toLocaleLowerCase().includes(searchVal)) return true;
    if (userName.toLocaleLowerCase().includes(searchVal)) return true;

    return false;
  };

  const toggleAddUserDialog = () => {
    setAddUserDialogVisible(!addUserDialogVisible);
  };

  const addNewUser = async (newUser: User) => {
    await getCurrentUser();
    const updatedUsers = [...users, newUser];
    setUsers(updatedUsers);
    master?.fetchUpdatedUsers();
  };

  const deleteUser = (user: User) => {
    if (user.id === auth?.user?.id) {
      return;
    }
    Swal.fire({
      title: trans.fetchLabelKeyTranslation(
        "SwtAltChangeTitle",
        "Are you sure?"
      ),
      text: trans.fetchLabelKeyTranslation(
        "SwtAltDeleteUserText",
        "You want to delete this User."
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: trans.fetchLabelKeyTranslation(
        "ContinueButton",
        "Continue"
      ),
      cancelButtonText: trans.fetchLabelKeyTranslation(
        "CancelButton",
        "Cancel"
      ),
    }).then(async (result) => {
      if (result.isConfirmed) {
        await usersService
          .deleteUser(user.id)
          .then(async () => {
            setUsers([...users.filter((ele) => ele.id !== user.id)]);
            master?.fetchUpdatedUsers();
            Swal.fire({
              icon: "success",
              title: trans.fetchLabelKeyTranslation(
                "SwtAltUserAdded",
                "User Deleted"
              ),
              confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
            });
          })
          .catch((err) => {
            if (err instanceof Error) {
              Swal.fire({
                icon: "error",
                title: trans.fetchLabelKeyTranslation("SwtAltTitle", "Oops…"),
                text: trans.fetchLabelKeyTranslation(
                  "SwtAltWrong",
                  "Something went wrong! Please Try again…"
                ),
                confirmButtonText: trans.fetchLabelKeyTranslation(
                  "OKText",
                  "OK"
                ),
              });
            }
          });
      }
    });
  };

  const updateUserLogin = async (user: User, loginEnabled: boolean) => {
    await usersService
      .updateUserLogin(user.id, loginEnabled)
      .then(async () => {
        let _users = users.map((u) => {
          if (u.id === user.id) {
            return { ...u, loginEnabled: loginEnabled };
          } else {
            return { ...u };
          }
        });
        setUsers(_users);
        Swal.fire({
          icon: "success",
          title: trans.fetchLabelKeyTranslation(
            "UserLoginSwitch",
            "User Login Updated"
          ),
          confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
        });
      })
      .catch((err) => {
        if (err instanceof Error) {
          Swal.fire({
            icon: "error",
            title: trans.fetchLabelKeyTranslation("SwtAltTitle", "Oops…"),
            text: trans.fetchLabelKeyTranslation(
              "SwtAltWrong",
              "Something went wrong! Please Try again…"
            ),
            confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
          });
        }
      });
  };

  const GetFilteredUsers = (profileName: string) => {
    let _filteredUsers = users
      ?.filter(
        (u) =>
          searchUsers(u) &&
          (profileName === "All" ||
            (profileName === "Other"
              ? u.authorizationProfile?.isSystemDefined === false
              : u.authorizationProfile?.name === profileName))
      )
      .sort((a, b) => {
        let a_name = (
          (a.firstName ? a.firstName : "") +
          " " +
          (a.lastName ? a.lastName : "")
        )
          .trim()
          .toLocaleLowerCase();
        let b_name = (
          (b.firstName ? b.firstName : "") +
          " " +
          (b.lastName ? b.lastName : "")
        )
          .trim()
          .toLocaleLowerCase();
        return a_name > b_name ? 1 : -1;
      });
    return _filteredUsers.filter((u) => !u.isArchived);
  };

  const CustomUserCardDetail = (
    userCardDetailProps: CustomUserCardDetailProps
  ) => {
    const user = userCardDetailProps.user;
    const [loginLoading, setLoginLoading] = useState<boolean>(false);
    const _userName = `${user.firstName ? user.firstName : ""} ${
      user.lastName ? user.lastName : ""
    }`.trim();
    let idValue;
    if (_userName !== "" && _userName.length > 0) {
      idValue = "userCardId_" + _userName.charAt(0).toLocaleLowerCase();
    }
    const handleLoginClick = async (event: SwitchChangeEvent) => {
      setLoginLoading(true);
      await updateUserLogin(user, event.value);
      setLoginLoading(false);
    };

    const navigateToProfile = () => {
      handleFilterData();
      navigate(`user/${user.id}`, {
        state: { ...navData, from: location.pathname },
      });
    };

    return (
      <div
        className="col col-xl-3 col-lg-4 col-md-6 col-sm-12"
        key={user.id}
        id={idValue}
      >
        <div className="cardListBlock">
          <div className="cardRows">
            <div className="cardColumn">
              <div className="colUser">
                <CustomUserGridInfo
                  userInfo={{
                    id: user.id,
                    loginUserId: user.loginUserId,
                    firstName: user.firstName ? user.firstName : "",
                    lastName: user.lastName ? user.lastName : "",
                    image: user.image ? user.image : "",
                    email: user.email,
                    phoneNo: "",
                    isArchived: user.isArchived,
                  }}
                  navigateToProfile={{
                    navigate: true,
                    navigateFunction: navigateToProfile,
                  }}
                  iconCls="bi bi-person"
                />
              </div>
              <div className="lblGroup float-left w-100">
                <div
                  onClick={navigateToProfile}
                  className="fs-12 font-weight-semi p-t-7 p-b-7 text-truncate cursor-pointer"
                >
                  <span>
                    {user.authorizationProfile?.name !== undefined
                      ? trans.fetchLabelKeyTranslation(
                          apLocaleKeyName[user.authorizationProfile?.name] ??
                            "",
                          user.authorizationProfile?.name
                        )
                      : ""}
                  </span>
                </div>
                <div
                  onClick={navigateToProfile}
                  className="fs-12 font-weight-semi p-t-7 p-b-7 text-truncate cursor-pointer"
                >
                  <span className="float-left text-black-9 p-b-2">
                    {trans.fetchLabelKeyTranslation(
                      "UserManagementColumnLicense",
                      "Licence"
                    )}
                    : &nbsp;
                  </span>
                  <span>
                    {user.licensePackage
                      ? master?.data?.licensePackages.find(
                          (x) => x.id === user?.licensePackage?.id
                        )?.localizationValue
                      : trans.fetchLabelKeyTranslation("NoneText", "None")}
                  </span>
                </div>
                <div
                  onClick={navigateToProfile}
                  className="minTextLabel p-t-1 cursor-pointer"
                >
                  <span
                    className="float-left text-black-9 p-b-2"
                    style={{ minWidth: "77px" }}
                  >
                    {trans.fetchLabelKeyTranslation(
                      "UserManagementColumnRecordedDevice",
                      "Recorded Device"
                    )}
                    :
                  </span>
                  <span className="p-l-3">
                    <span className="badgeList">
                      <div className="keywordRow">
                        {user.devices.length > 0 ? (
                          user.devices.map((device) => {
                            return (
                              <div
                                className="keywordTrk"
                                key={device.deviceIdentifier}
                              >
                                {device.deviceIdentifier}
                              </div>
                            );
                          })
                        ) : (
                          <div className="keywordTrk">
                            {trans.fetchLabelKeyTranslation("TextNone", "None")}
                          </div>
                        )}
                      </div>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="blockIconButton">
            {currentUser?.recordingNetwork?.find(
              (u: TinyUser) => u.id === userCardDetailProps.user.id
            ) &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups) ? (
              <>
                <span className="btnBlocks">
                  <div
                    className="switchButton"
                    title={
                      user.loginEnabled
                        ? trans.fetchLabelKeyTranslation(
                            "LoginEnabledText",
                            "Login Enabled"
                          )
                        : trans.fetchLabelKeyTranslation(
                            "LoginDisabledText",
                            "Login Disabled"
                          )
                    }
                  >
                    {loginLoading ? (
                      <Loader />
                    ) : (
                      <Switch
                        checked={user.loginEnabled}
                        onChange={(event) => handleLoginClick(event)}
                        disabled={
                          master?.telepoDisableConfig?.usermanagement.users
                            .profile.disableEnableLoginToggle
                        }
                      />
                    )}
                  </div>
                </span>
                <span className="btnBlocks">
                  <Link
                    to={`user/${user.id}`}
                    className="btnBlock-a"
                    onClick={navigateToProfile}
                  >
                    <i className="bi bi-pencil fs-17"></i>
                  </Link>
                </span>
                {user.id !== auth?.user?.id && (
                  <span className="btnBlocks">
                    <a
                      style={
                        master?.telepoDisableConfig?.usermanagement.users
                          .disableDeleteUserButton
                          ? { pointerEvents: "none" }
                          : {}
                      }
                      className={`btnBlock-a cursor-pointer ${
                        master?.telepoDisableConfig?.usermanagement.users
                          .disableDeleteUserButton
                          ? "disabledIconBtn"
                          : ""
                      }`}
                      title={trans.fetchLabelKeyTranslation(
                        "DeleteButtonText",
                        "Delete"
                      )}
                      onClick={() => deleteUser(user)}
                    >
                      <i className="bi bi-trash3 fs-17"></i>
                    </a>
                  </span>
                )}
              </>
            ) : !auth?.checkUserAccess(
                AccessPermissionEnum.ManageSecurityControl
              ) ? (
              <span className="btnBlocks">
                <a
                  className="btnBlock-a cursor-pointer"
                  title={trans.fetchLabelKeyTranslation(
                    "UserNotFoundMsg",
                    "View User Profile"
                  )}
                >
                  <i className="bi bi-eye fs-17"></i>
                </a>
              </span>
            ) : (
              <span className="btnBlocks">
                <Link
                  to={`user/${user.id}`}
                  className="btnBlock-a"
                  onClick={navigateToProfile}
                >
                  <i className="bi bi-pencil fs-17"></i>
                </Link>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const CustomTabStripTab = (tabStripProps: CustomTabStripTabProps) => {
    let _users = GetFilteredUsers(tabStripProps.name);
    let id_card = "cardId_" + tabStripProps.name;
    return (
      <div className="cardBrand" onScroll={handleScrollChange} id={id_card}>
        <div className="cardListArea">
          <div className="row" style={{ paddingBottom: "55px" }}>
            {loading ? (
              <div className="col-md-12 d-flex justify-content-center align-items-center">
                <Loader type={"infinite-spinner"} />
              </div>
            ) : _users && _users.length > 0 ? (
              _users.map((user) => {
                return <CustomUserCardDetail user={user} key={user.id} />;
              })
            ) : (
              <div className="col-md-12 p-l-5">
                <div className="dashedBox p-10 m-b-20 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed d-flex align-items-center justify-content-center">
                  <span className="fs-13 text-black-9 p-t-20 p-b-20">
                    {trans.fetchLabelKeyTranslation(
                      "UserManagementInfoMsgNoUsers",
                      "No Users Available"
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups) ? (
        <UnauthorizedAccess />
      ) : (
        <Tooltip anchorElement={"target"} parentTitle={true} position={"top"}>
          <div className="contentPushArea boxUsers h-100">
            <div className="contentHead h-100">
              <div className="contentHeadTop d-flex justify-content-between align-items-center">
                <div className="contentHeadTitle fs-18">
                  {trans.fetchLabelKeyTranslation(
                    "UserManagementTitle",
                    "Users"
                  )}
                </div>
                <div className="contentHeadAction">
                  <div className="buttons-container hov-transparent d-flex align-itmes-center p-0">
                    <div className="searchCol searchBox m-r-10">
                      <CustomSearchFieldWithButton
                        className="input-search"
                        placeholder={trans.fetchLabelKeyTranslation(
                          "UserManagementSearchBoxPlaceHolder",
                          "Search..."
                        )}
                        value={searchTerm}
                        onEscapeKeyFunc={true}
                        searchTextChangeHandler={handleSearchChange}
                        updateValue={setSearchTerm}
                        clearSearch={clearSearch}
                      />
                    </div>
                    <Button
                      onClick={toggleAddUserDialog}
                      className={`btn bg-primary text-white fs-13 fw-normal ${
                        !auth?.checkUserAccess(
                          AccessPermissionEnum.ManageUsersAndGroups
                        ) ||
                        master?.telepoDisableConfig?.usermanagement.users
                          .disableAddUserButton
                          ? "disabledBtn"
                          : ""
                      }`}
                      style={{ height: "29px", margin: "-1px 0 0 0" }}
                      disabled={
                        !auth?.checkUserAccess(
                          AccessPermissionEnum.ManageUsersAndGroups
                        ) ||
                        master?.telepoDisableConfig?.usermanagement.users
                          .disableAddUserButton
                      }
                      title={trans.fetchLabelKeyTranslation(
                        "UserManagementAddButton",
                        "Add"
                      )}
                    >
                      {trans.fetchLabelKeyTranslation(
                        "UserManagementAddButton",
                        "Add"
                      )}
                    </Button>
                    {addUserDialogVisible && (
                      <AddUserDialog
                        toggleDialog={toggleAddUserDialog}
                        addNewUser={addNewUser}
                        currentUser={currentUser}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="contentHeadTab h-100">
                <div className="tabLabel tabLeftPadding-0 h-100">
                  <TabStrip
                    selected={selectedTab}
                    onSelect={handleTabSelection}
                  >
                    <TabStripTab
                      title={
                        <CustomTabTitle
                          name={trans.fetchLabelKeyTranslation(
                            "AllTabTxt",
                            "All"
                          )}
                          count={loading ? 0 : GetFilteredUsers("All").length}
                        />
                      }
                    >
                      <CustomTabStripTab name="All" />
                    </TabStripTab>
                    <TabStripTab
                      title={
                        <CustomTabTitle
                          name={trans.fetchLabelKeyTranslation(
                            "SuperAdmin",
                            "Super Admin"
                          )}
                          count={
                            loading ? 0 : GetFilteredUsers("Super Admin").length
                          }
                        />
                      }
                    >
                      <CustomTabStripTab name="Super Admin" />
                    </TabStripTab>
                    <TabStripTab
                      title={
                        <CustomTabTitle
                          name={trans.fetchLabelKeyTranslation(
                            "RecordingAdmin",
                            "Recording Admin"
                          )}
                          count={
                            loading
                              ? 0
                              : GetFilteredUsers("Recording Admin").length
                          }
                        />
                      }
                    >
                      <CustomTabStripTab name="Recording Admin" />
                    </TabStripTab>
                    <TabStripTab
                      title={
                        <CustomTabTitle
                          name={trans.fetchLabelKeyTranslation(
                            "PlaybackSupervisor",
                            "Playback Supervisor"
                          )}
                          count={
                            loading
                              ? 0
                              : GetFilteredUsers("Playback Supervisor").length
                          }
                        />
                      }
                    >
                      <CustomTabStripTab name="Playback Supervisor" />
                    </TabStripTab>
                    <TabStripTab
                      title={
                        <CustomTabTitle
                          name={trans.fetchLabelKeyTranslation(
                            "RecordingAgent",
                            "Recording Agent"
                          )}
                          count={
                            loading
                              ? 0
                              : GetFilteredUsers("Recording Agent").length
                          }
                        />
                      }
                    >
                      <CustomTabStripTab name="Recording Agent" />
                    </TabStripTab>
                    <TabStripTab
                      title={
                        <CustomTabTitle
                          name={trans.fetchLabelKeyTranslation(
                            "OtherTabTxt",
                            "Other"
                          )}
                          count={loading ? 0 : GetFilteredUsers("Other").length}
                        />
                      }
                    >
                      <CustomTabStripTab name="Other" />
                    </TabStripTab>
                  </TabStrip>
                </div>
                <CardWordFilter
                  selectedAlphabet={selectedAlphabet}
                  handleAlphabetFilterChange={handleAlphabetFilterChange}
                ></CardWordFilter>
              </div>
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default UserManagement;
