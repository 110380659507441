import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import useMasterData from "../../hooks/useMasterData";
import { ColorCategory } from "../../types/master-data";
import { IsValidString } from "../../utils/stringUtils";
import { Popup } from "@progress/kendo-react-popup";
import useTranslation from "../../hooks/useTranslation";
import useLocale from "../../hooks/useLocale";

type CallFlagProps = {
    colorCategory: ColorCategory | undefined;
    handleFlagSelection: (selectedFlag: ColorCategory) => void;
};

const CallFlag: React.FC<CallFlagProps> = ({colorCategory, handleFlagSelection}) => {
    const trans = useTranslation("callflag");
    const masterData = useMasterData();
    const localeCtx=useLocale();
    const flagAnchor = useRef<HTMLButtonElement | null>(null);
    const [showFlagPopUp, setShowFlagPopUp] = useState(false);
    const flagColor = colorCategory ? IsValidString(colorCategory.hexCode) ? colorCategory.hexCode : "#ccc" : "#ccc";
    const flagName = colorCategory ?
     IsValidString(colorCategory.name) ? 
     masterData?.data?.colorCategories.find((clr:ColorCategory)=>clr.name===colorCategory.name)?.localizationValue ?? colorCategory.name : 
     trans.fetchLabelKeyTranslation("NoneFlagged","None") : 
     trans.fetchLabelKeyTranslation("NoneFlagged","None");

    useEffect(() => {
      if (!localeCtx?.selectedLocale?.current.componentTranslations["callflag"]) {
        trans.fetchTranslations("callflag");
      }
    }, [localeCtx?.selectedLocale]);
    
    useEffect(() => {
        if (showFlagPopUp) {
          const body = document.getElementById("root");
          const eventHandler = () => {
            setShowFlagPopUp(false);
          };
          if (body != null) {
            body.addEventListener("click", eventHandler);
          }
          return () => body?.removeEventListener("click", eventHandler);
        }
    }, [showFlagPopUp]);

    const handleFlagUpdate = (selectedFlag: ColorCategory | null) => {
        var _selectedFlag: ColorCategory = { id: 0, name: "", hexCode: "" }
        if (selectedFlag !== null) {
          _selectedFlag = selectedFlag;
        }
        handleFlagSelection(_selectedFlag)
        setShowFlagPopUp(false);
    }

  return (
    <>
      <Button
        className="radius-50 d-flex align-items-center justify-conter m-r-3"
        onClick={() => setShowFlagPopUp(!showFlagPopUp)}
      >
        <span
          ref={flagAnchor}
          className="fs-16 line-height-1"
          style={{ color: flagColor }}
          title={trans.fetchLabelKeyTranslation("FlagedTitle","Flaged: ") + flagName}
        >
          <i className="bi bi-flag-fill"></i>
        </span>
      </Button>
      <Popup
        anchor={flagAnchor.current}
        show={showFlagPopUp}
        popupClass={"popup-content"}
        style={{ marginLeft: "-25px", marginTop: "10px" }}
      >
        <div
          className="dropMenu droplist-ul d-flex align-items-center"
          style={{ minWidth: "250px", minHeight: "70px" }}
        >
          <div className="iBtn d-flex">
            {masterData?.data?.colorCategories
              .filter((el) => el.id !== colorCategory?.id)
              .map((flag) => {
                return (
                  <Button
                    className="iBtn-bg radius-50 d-flex align-items-center justify-conter m-r-3"
                    title={flag.name}
                    key={flag.id}
                    onClick={() => {
                        handleFlagUpdate(flag);
                    }}
                  >
                    <span style={{ color: flag.hexCode }}>
                      <i className="bi bi-flag-fill fs-16"></i>
                    </span>
                  </Button>
                );
              })}
            {(colorCategory && colorCategory.id != 0) && (
              <Button
                className="iBtn-bg radius-50 d-flex align-items-center justify-conter"
                title="Clear Flag"
                onClick={() => {
                    handleFlagUpdate(null);
                }}
              >
                <span className="text-black-11">
                  <i className="bi bi-eraser-fill fs-16"></i>
                </span>
              </Button>
            )}
          </div>
        </div>
      </Popup>
    </>
  );
};

export default CallFlag;
