import {
  ComboBox,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { Hint, Error } from "@progress/kendo-react-labels";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { useEffect, useState } from "react";
import { sortDataFunc } from "../../../utils/dropdownSort";

interface CustomComboBoxProps extends Omit<FieldRenderProps, ""> {
  onFocus?: () => void;
  onBlur?: () => void;
  validationMessage?: string | null;
  touched?: boolean;
  modified?: boolean;
  visited?: boolean;
  valid?: boolean;
  children?: any;
}

const CustomComboBox = (fieldRenderProps: CustomComboBoxProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    max,
    rows,
    value,
    showTextLimitHint,
    loading,
    data,
    textField,
    sorting = false, // if true, sorting enabled.
    sortField, // if 'data' is array of object then send the field name (on the basis of which you want sorting) in 'sortField' prop as string . if 'data' is array of primitive datatype then no need to send any 'sortField' prop.
    sortOrder = "asc", // send 'asc' or 'desc' for ascending or descending sorting respectively.
    filtering = false, // if true, filtering enabled.
    filterFields, // send those field names in the form of array of strings on the basis of which you want filtering. If no 'filterFields' prop is sent then kendo would take 'textField' prop to do the filtering. If no 'textField' and no 'filterFields' props are there, then no filtering will be done.
    ...others
  } = fieldRenderProps;

  const [items, setItems] = useState(data?.slice());
  const [sortedItems, setSortedItems] = useState(data?.slice());
  const [sortData, setSortData] = useState(data?.slice());
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  const filterData = (filter: FilterDescriptor) => {
    const newData = sorting ? sortData?.slice() : data?.slice();
    const filteredData =
      filterFields && filterFields?.length > 0
        ? newData.filter((row: any) =>
          {
            var isCorrect = false;
            if(filterFields?.includes('firstName')){
              var fullName = (row.firstName + ' ' + row.lastName).toLowerCase();
              if(fullName.includes(filter.value.toLowerCase())){
                isCorrect = true;
              }                  
            }              
            Object.entries(row).some(([key, value]: [string, any]) => {
              if (filterFields?.includes(key) && typeof value === "string") {            
                if(!isCorrect){
                  isCorrect = value.toLowerCase().includes(filter.value.toLowerCase());
                }
              }                
            })              
            return isCorrect;
          }
          )
        : filterBy(newData, filter);
    return filteredData;
  };

  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    if (sorting) {
      setSortedItems(filterData(event.filter));
    } else {
      setItems(filterData(event.filter));
    }
  };

  useEffect(() => {
    sorting && setSortData(sortDataFunc(data, sortField, sortOrder));
    sorting && setSortedItems(sortDataFunc(data, sortField, sortOrder));
    setItems(data?.slice());
  }, [data]);

  return (
    <div className={"k-form-field-wrap"}>
      <ComboBox
        filterable={filtering}
        onFilterChange={filterChange}
        loading={loading}
        data={sorting ? sortedItems : items}
        value={value}
        disabled={disabled}
        textField={textField}
        {...others}
      />
      {showTextLimitHint && (
        <Hint direction={"end"}>
          {value.length} / {max}
        </Hint>
      )}
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  );
};

export default CustomComboBox;
