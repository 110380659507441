import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  Link,
  useSearchParams,
} from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  Field,
  FormElement,
  FieldProps,
} from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators";
import authService from "../../../services/auth.service";
import axios from "axios";
import { LoginError } from "../../../types/auth/LoginError";
import { ChangePassword } from "../../../types/auth/ChangePassword";
import { Dictionary } from "../../../types/Dictionary";
import useLocale from "../../../hooks/useLocale";
import useAuth from "../../../hooks/useAuth";
import useSwal from "../../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import useTranslation from "../../../hooks/useTranslation";

const passwordRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

const CustomInputField = (fieldRenderProps: any) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const Changepassword: React.FC = () => {
  const trans = useTranslation("changePassword");
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [confirmNotMatching, setConfirmNotMatching] = useState(false);
  const [oldPassSame, setOldPassSame] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal();

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["changePassword"]) {
      trans.fetchTranslations("changePassword");
    }
  }, []);

  const passwordValidator = (value: string) => {
    if (value != "") {
      setInvalidPassword(!passwordRegex.test(value));
    }
    else {
      setInvalidPassword(false);
      setConfirmNotMatching(false);
      setOldPassSame(false);
    }

    setNewPassword(value);
  };

  const passwordConfirmation = (value: string) => {
    setPasswordConfirm(value);
  };

  const buttonValidator = async () => {

    setConfirmNotMatching(false);
    setInvalidPassword(false);
    setOldPassSame(false);

    if (
      !invalidPassword &&
      newPassword == passwordConfirm &&
      newPassword.length > 0 &&
      oldPassword != newPassword
    ) {
      const changePasswordData: ChangePassword = {
        Email: auth?.user?.email as string,
        OldPassword: oldPassword,
        NewPassword: newPassword,
      };

      try {
        setLoading(true);
        const message = await authService.userChangePassword(changePasswordData);
        await authService.logout();
        //navigate("/login"); 
        setIsSuccess(true);
        setLoginError(true);
      }
      catch (err) {
        console.error(err);

        if (axios.isAxiosError(err)) {
          var tempVar: LoginError = err.response?.data as LoginError;

          if (tempVar.error == "Unable to change password") {
            tempVar.error = trans.fetchLabelKeyTranslation("UnableToChange", "Unable to change password");
          }
          else if (tempVar.error == "Old Password does not match") {
            tempVar.error = trans.fetchLabelKeyTranslation("OldPasswordMatch", "Old Password does not match");
          }
          else {
            tempVar.error = trans.fetchLabelKeyTranslation("AnError", "An error occured");
          }

          setErrMessage(tempVar.error);
        }

        setLoginError(true);
      }
      finally {
        setLoading(false);
      }

    }
    else {



      if (newPassword != passwordConfirm) {
        setConfirmNotMatching(true);
      }
      if (invalidPassword) {
        setInvalidPassword(true);
      }
      if (oldPassword == newPassword) {
        setOldPassSame(true);
      }
    }
  };

  return (
    <div className="row m-b-20 h-100">
      <div className="col-md-12 h-100">
        <div className="formGroup h-100">
          <div className="formInrG cardEffect">
            <div className="hdrTrk-logo text-center p-t-5 p-b-15">
              <a className="navbar-logo" href="" title="Logo">
                <img
                  src={require("../../../assets/images/logo.png")}
                  alt="Logo"
                />
              </a>
            </div>
            <Form
              // onSubmit={handleSubmit}
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: "100%" }}>
                  {isSuccess ? (<div><span className="float-left w-100 text-center">{trans.fetchLabelKeyTranslation("ResetSuccess",
                    "Successfully reset password.")}{trans.fetchLabelKeyTranslation("ClickLogin",
                      "Please login again")}</span> <span className="float-left w-100 text-center p-t-10">
                      <Link className="btn btn-primary" to="/login">Login</Link></span></div>) :
                    (<div>
                      <fieldset className={"k-form-fieldset"}>
                        <legend
                          className={"k-form-legend fs-18 font-weight-semi"}
                          style={{ textTransform: "none" }}
                        >
                          {trans.fetchLabelKeyTranslation("ChangePass", "Change your password")}
                        </legend>
                        {loginError && <Error>{errMessage}</Error>}
                        <div className="m-b-15">
                          <Field
                            disabled
                            name="email"
                            type="email"
                            label={auth?.user?.email as string}
                            component={CustomInputField}
                          //validator={emailValidator}
                          />
                        </div>
                        <div className="m-b-15">
                          <Field
                            placeholder="Old Password"
                            name="oldPassword"
                            type="password"
                            // label="Password"
                            component={CustomInputField}
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.value)}
                          //validator={passwordValidator}
                          />
                        </div>
                        <div className="m-b-15">
                          <Field
                            placeholder="New Password"
                            name="newPassword"
                            type="password"
                            // label="Password"
                            component={CustomInputField}
                            value={newPassword}
                            onChange={(e) => passwordValidator(e.value)}
                          //validator={passwordValidator}
                          />
                        </div>
                        {oldPassSame ? (
                          <div>
                            <Error><div>{trans.fetchLabelKeyTranslation("OldPasswordSame",
                              "Old and new passwords must be different.")}</div></Error>
                          </div>
                        ) : ("")}
                        {invalidPassword ? (
                          <Error><div>
                            {trans.fetchLabelKeyTranslation("PassMustContain", "Password must contain:")}
                            <ul>
                              <li>{trans.fetchLabelKeyTranslation("MinEightChar",
                                "Minimum eight characters.")}</li>
                              <li>
                                {trans.fetchLabelKeyTranslation("UpperLowerChar",
                                  "At least one uppercase letter and one lowercase letter.")}
                              </li>
                              <li>
                                {trans.fetchLabelKeyTranslation("SpecialChar",
                                  "At least one number and one special character.")}
                              </li>
                            </ul>
                          </div></Error>
                        ) : (
                          ""
                        )}
                        <div className="m-b-1">
                          <Field
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            type="password"
                            // label="Password"
                            value={passwordConfirm}
                            component={CustomInputField}
                            onChange={(e) => passwordConfirmation(e.value)}
                          />
                        </div>
                        {confirmNotMatching ? (
                          <Error><div>{trans.fetchLabelKeyTranslation("ConfirmNotMatch",
                            "Confirm password not matching password.")}</div></Error>
                        ) : (
                          ""
                        )}
                      </fieldset>
                      <div className="k-form-buttons d-flex justify-content-center m-t-15">
                        <Button
                          type={"submit"}
                          style={{ width: "100%", textTransform: "uppercase" }}
                          className="k-button k-button-md k-rounded-md k-button-solid bg-primary text-white fs-16 p-t-7 p-b-7"
                          disabled={loading}
                          onClick={buttonValidator}
                        >
                          <i className="bi bi-lock fs-14 p-r-5"></i>
                          {loading ? (
                            <Loader
                              size="small"
                              type="infinite-spinner"
                              themeColor="light"
                            />
                          ) : (
                            "Change Password"
                          )}
                        </Button>
                      </div>
                    </div>
                    )}
                </FormElement>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Changepassword;
