import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import MsTeams from "./MsTeams";
import useAuth from "../../hooks/useAuth";
import useSwal from "../../hooks/useSwal";
import useLocale from "../../hooks/useLocale";
import { Dictionary } from "../../types/Dictionary";
import { AccessPermissionEnum } from "../../enums";
import UnauthorizedAccess from "../error/UnauthorizedAccess";
import LoadingOverlay from "../../components/LoadingOverlay";
import customerService from "../../services/customer.service";
import { PlatformDetail } from "../../types/customer";
import useMasterData from "../../hooks/useMasterData";
import SbcMsTeams from "./SbcMsTeams";
import useTranslation from "../../hooks/useTranslation";

const CustomerPlatform: React.FC = () => {
  const masterData = useMasterData();
  const trans=useTranslation("CustomerPlatformDetail");
  const auth = useAuth();
  const Swal = useSwal();
  const localeCtx = useLocale();
  const [selected, setSelected] = useState<number>(Number(sessionStorage.getItem("CustomerPlatformSelectedTab"))??0);
  const [pageFreezeLoader, setPageFreezeLoader] = useState<boolean>(false);
  const [customerPlatformDetails, setCustomerPlatformDetails] = useState<PlatformDetail[]>([]);

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["CustomerPlatformMsTeams"]) {
      trans.fetchTranslations("CustomerPlatformDetail")
    }
  }, [localeCtx?.selectedLocale]);


  useEffect(() => {
    getCustomerPlatformDetails();
  }, [auth?.tokenPayload]);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
    sessionStorage.setItem("CustomerPlatformSelectedTab",e.selected.toString())
  };

  const getCustomerPlatformDetails = async () => {
    const customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      try {
        setPageFreezeLoader(true);
        const platformDetails = await customerService.getCustomerPlatformDetail(
          customerId
        );
        setCustomerPlatformDetails(platformDetails);
      } catch (err) {
        if (err instanceof AxiosError) {
          console.log(err);
          setCustomerPlatformDetails([]);
        }
      } finally {
        setPageFreezeLoader(false);
      }
    }
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageAccount) ? (
        <UnauthorizedAccess />
      ) : (
        <>
        {trans?.translationsLoading && !pageFreezeLoader && (
        <LoadingOverlay
          customStyle={{ position: "fixed", marginTop: "55px" }}
          themeColor={"light"}
          size={"medium"}
          loadingText={trans?.fetchLabelKeyTranslation(
            "SwitchLanguageText",
            ""
          )}
        />
      )}
          {pageFreezeLoader && (
            <LoadingOverlay
              customStyle={{
                position: "fixed",
                marginTop: "55px",
                zIndex: "999999",
              }}
              themeColor={"light"}
              size={"medium"}
              loadingText={"Please wait..."}
            />
          )}
          <div className="m-b-20">
            <div className="row tabLeftView">
              <div className="col-md-12">
                <div className="card cardEffect">
                  <div className="card-header bg-white d-flex justify-content-between">
                    <span className="detailHeader d-flex align-items-center">
                      <span className="fs-16 font-weight-semi p-r-20">
                        {trans?.fetchLabelKeyTranslation(
                            "CustomerPlatformDetailsTitle",
                            "Platform Details"
                          )}
                      </span>
                    </span>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-md-12">
                        {customerPlatformDetails.length===0?(
                          <p className="text-center m-t-10">
                            {trans.fetchLabelKeyTranslation("NoPlatformAvailableMssg","There are no platforms available")}
                          </p>
                        ):(
                          <div className="settingData k-custom-tab p-t-20 p-r-15 p-l-20 p-b-20 systemSet">
                          <TabStrip
                            tabPosition={"left"}
                            selected={selected}
                            onSelect={handleSelect}
                          >
                            {customerPlatformDetails !== undefined && customerPlatformDetails !== null &&
                              customerPlatformDetails.map((platformDetail) => {
                                return (
                                  <TabStripTab title={masterData?.data?.platforms.find(p => p.name === platformDetail.platform)?.localizationValue ?? platformDetail.platform}>
                                    <div className="m-b-10">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="trk-container customerViewBox">
                                            <div className="trk-inr p-b-30">
                                              {platformDetail.platform === "Broadworks" && 
                                                <>
                                                  <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                                                    <span className="text-primary">
                                                    {trans?.fetchLabelKeyTranslation(
                                                      "BwPlatformTitle",
                                                      "Broadworks Platform"
                                                    )}
                                                      </span>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {trans?.fetchLabelKeyTranslation("EnterpriseId", "Enterprise Id")}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.enterpriseId}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {trans?.fetchLabelKeyTranslation("GroupId", "Group Id")}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.groupId}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {trans?.fetchLabelKeyTranslation("DirectoryUser", "Directory User")}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.directoryUser}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {trans?.fetchLabelKeyTranslation("XsiUserId", "Xsi User Id")}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.xsiUsername}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {trans?.fetchLabelKeyTranslation("XsiPassword", "Xsi Password")}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">
                                                          {platformDetail.customerDetail.xsiPassword
                                                            .split("")
                                                            .map(() => {
                                                              return (
                                                                <>&#x2022;</>
                                                              );
                                                            })}
                                                        </div>
                                                    </div>
                                                  </div>
                                                </>}
                                                {platformDetail.platform === "Telepo" && 
                                                <>
                                                  <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                                                    <span className="text-primary">
                                                    {trans?.fetchLabelKeyTranslation(
                                                      "TelepoPlatformTitle",
                                                      "Telepo Platform"
                                                    )}
                                                      </span>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {trans?.fetchLabelKeyTranslation("OrganisationId", "Organisation Id")}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.organizationId}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {trans?.fetchLabelKeyTranslation("OrganisationName", "Organisation Name")}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.organizationName}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {trans?.fetchLabelKeyTranslation("OrganisationDomain", "Organisation Domain")}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.organizationDomain}</div>
                                                    </div>
                                                  </div>
                                                </>}
                                                {platformDetail.platform === "SBC-MsTeams" && 
                                                <>
                                                  <SbcMsTeams platformDetail={platformDetail}/>
                                                </>}
                                                {platformDetail.platform === "SBC-Others" && 
                                                <>
                                                  <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                                                    <span className="text-primary">
                                                    {trans?.fetchLabelKeyTranslation(
                                                      "SbcOthersPlatformTitle",
                                                      "SBC-Others Platform"
                                                    )}
                                                    </span>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {trans?.fetchLabelKeyTranslation("SipCustomerRef", "Sip Customer Ref.")}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.sbcOthersSipCustomerReference}</div>
                                                    </div>
                                                  </div>
                                                </>}
                                                {platformDetail.platform === "MsTeams" && 
                                                <>
                                                  <MsTeams platformDetail={platformDetail}/>
                                                </>}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </TabStripTab>
                                )
                              })
                            }
                          </TabStrip>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomerPlatform;
