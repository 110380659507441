import { Button } from "@progress/kendo-react-buttons";
import { useContext } from "react";
import {
  AccessPermissionEnum,
  EntityLocalizationGroupEnum,
} from "../../enums/accessPermissionEnum";
import useAuth from "../../hooks/useAuth";
import {
  ScorecardEvaluationDetails,
  ScorecardEvaluationQuestion,
  ShortScorcardType,
} from "../../pages/call-details/RecordingScorecardTab";
import { RecordingScorecard } from "../../types/recording/RecordingScorecard";
import RecordingScorecardGrid from "./RecordingScorecardGrid";
import CustomUserInfo from "../custom/CustomUserInfo";
import useMasterData from "../../hooks/useMasterData";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface RecordingScorecardEvalutationProps {
  recordingId: number;
  scorecard: ShortScorcardType;
  onShowDialog: () => void;
  isEvaluated: boolean;
  questions?: ScorecardEvaluationQuestion[];
  onEvaluationSave: (e: RecordingScorecard) => void;
  evaluationDetails?: ScorecardEvaluationDetails;
  error?: string;
  shared?: boolean;
  fetchKeyTranslation: (key: string, defaultVal: string) => string;
  edited: boolean;
  setEdited: (e: boolean) => void;
  sendFetchRecordingScorecardRequest: () => void;
  setEvaluating: (e: boolean) => void;
  onEvaluationDelete: () => void;
}

const RecordingScorecardEvalutation: React.FC<
  RecordingScorecardEvalutationProps
> = ({
  recordingId,
  scorecard,
  onShowDialog,
  isEvaluated,
  questions,
  onEvaluationSave,
  evaluationDetails,
  error,
  shared,
  fetchKeyTranslation,
  edited,
  setEdited,
  sendFetchRecordingScorecardRequest,
  setEvaluating,
  onEvaluationDelete,
}) => {
  const auth = useAuth();
  const master = useMasterData();
  const scoreColor = evaluationDetails?.score
    ? evaluationDetails.score >= 3
      ? "tx-green"
      : evaluationDetails.score >= 1.5
      ? "tx-amber"
      : "tx-red"
    : "";

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="gridActionGroup d-flex justify-content-between">
            <div className="gridAction m-b-15">
              <Tooltip
                anchorElement={"target"}
                position={"bottom"}
                parentTitle={true}
              >
                <div className="actionLabel">
                  <div className="fs-14 p-b-2 text-black-9">
                    {fetchKeyTranslation(
                      "TextSCName",
                      "Evaluation Scorecard Name"
                    )}
                  </div>
                  {scorecard.id !== -1 && (
                    <Button
                      className={`btn btn-link ${
                        !auth?.checkUserAccess(
                          AccessPermissionEnum.EvaluateCalls
                        ) ||
                        (!auth?.accessToken && shared === true)
                          ? "disabledBtn"
                          : ""
                      }`}
                      onClick={onShowDialog}
                      disabled={
                        !auth?.checkUserAccess(
                          AccessPermissionEnum.EvaluateCalls
                        ) ||
                        (!auth?.accessToken && shared === true)
                      }
                    >
                      <span className="fs-16 font-weight-semi">
                        {fetchKeyTranslation(
                          master?.getEntityNameKeyfromConfigMap(
                            EntityLocalizationGroupEnum.Scorecard,
                            scorecard.text ?? ""
                          ) ?? "",
                          scorecard.text
                        )}
                        <i className="bi bi-pencil ml-2"></i>
                      </span>
                    </Button>
                  )}
                  {scorecard.id === -1 && (
                    <div className="d-flex align-items-center justify-content-center ml-1">
                      <span>
                        <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                        {"N/A"}
                      </span>
                    </div>
                  )}

                  <Button
                    className={`btn btn-link ${
                      !auth?.checkUserAccess(
                        AccessPermissionEnum.EvaluateCalls
                      ) ||
                      (!auth?.accessToken && shared === true)
                        ? "disabledBtn"
                        : ""
                    }`}
                    onClick={onEvaluationDelete}
                    title={fetchKeyTranslation(
                      "DeleteEvalutaionBtn",
                      "Delete Evalutation"
                    )}
                    disabled={
                      !auth?.checkUserAccess(
                        AccessPermissionEnum.EvaluateCalls
                      ) ||
                      (!auth?.accessToken && shared === true)
                    }
                  >
                    <span className="fs-16 font-weight-semi">
                      <i className="bi bi-trash3"></i>
                    </span>
                  </Button>
                </div>
              </Tooltip>
            </div>
            {isEvaluated &&
              evaluationDetails &&
              scorecard.id === evaluationDetails.id && (
                <div className="gridAction m-b-15 text-right d-flex">
                  <div className="actionLabel p-r-10 border-right-solid border-w-3 border-black-2">
                    <div className="fs-14 p-b-2 text-black-9">
                      {fetchKeyTranslation("TextScore", "Score")}
                    </div>
                    <div
                      className={`fs-15 m-t-5 font-weight-semi ${scoreColor}`}
                    >
                      {evaluationDetails.score &&
                        ((evaluationDetails.score / 5) * 100).toFixed(2)}
                      %
                    </div>
                  </div>
                  <div className="actionLabel m-l-30 p-r-10 border-right-solid border-w-3 border-black-2">
                    <div className="fs-14 p-b-2 text-black-9">
                      {fetchKeyTranslation("TextEvaluatedAt", "Evaluated at")}
                    </div>
                    <div className="fs-15 m-t-5 font-weight-semi">
                      {evaluationDetails.evaluatedAt}
                    </div>
                  </div>
                  <div className="actionLabel m-l-20 p-r-10 border-right-solid border-w-3 border-black-2">
                    <div className="fs-14 p-b-2 text-black-9">
                      {fetchKeyTranslation("TextEvaluatedBy", "Evaluated By")}
                    </div>
                    <div className="fs-15 font-weight-semi">
                      {evaluationDetails.evaluatedBy && (
                        <CustomUserInfo
                          userInfo={evaluationDetails.evaluatedBy}
                          customPnlCss={
                            "liDetail d-flex align-items-center p-r-5"
                          }
                          customDataHeaderCss={
                            "liDetail-h d-flex font-semi-bold text-balck-10 fs-14"
                          }
                          customDataBodyCss={
                            "liDetail-p fs-13 text-black-8 line-height-1"
                          }
                        />
                      )}
                    </div>
                  </div>
                  {/* <div>
                    <input
                      type="button"
                      value="DeleteEvalutation"
                      onClick={onEvaluationDelete}
                    ></input>
                  </div> */}
                </div>
              )}
          </div>
        </div>
      </div>
      <RecordingScorecardGrid
        scorecard={scorecard}
        recordingId={recordingId}
        questions={questions}
        onEvaluationSave={onEvaluationSave}
        error={error}
        shared={shared}
        fetchKeyTranslation={fetchKeyTranslation}
        edited={edited}
        setEdited={setEdited}
        sendFetchRecordingScorecardRequest={sendFetchRecordingScorecardRequest}
        isEvaluated={isEvaluated}
        setEvaluating={setEvaluating}
      />
    </>
  );
};

export default RecordingScorecardEvalutation;
