import * as React from "react";
import { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Dictionary } from "../../../types/Dictionary";
import useLocale from "../../../hooks/useLocale";
import UpsertNotificationSetup from "./UpsertNotificationSetup";
import NotificationSetupViewBox from "./NotificationSetupViewBox";
import { axiosWithAuth } from "../../../utils/customAxios";
import { GetNotificationRule } from "../../../types/notification-setup/NotificationSetup";
import { Loader } from "@progress/kendo-react-indicators";
import { AxiosError } from "axios";
import { Error } from "@progress/kendo-react-labels";
import LoadingOverlay from "../../../components/LoadingOverlay";
import useSwal from "../../../hooks/useSwal";
import useTranslation from "../../../hooks/useTranslation";

const NotificationSetup: React.FC = () => {
  const trans = useTranslation("NotificationSetup");
  const localeCtx = useLocale();

  const [visible, setVisible] = useState<boolean>(false);
  const [notificationRules, setNotificationRules] = useState<
    GetNotificationRule[] | undefined
  >(undefined);
  const [notificationUpdateData, setNotificationUpdateData] = useState<
    GetNotificationRule | undefined
  >(undefined);
  const [searchText, setSearchText] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const swal = useSwal();
  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
      "NotificationSetup"
      ]
    ) {
      trans.fetchTranslations("NotificationSetup");
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    getAllNotificationRules();
  }, []);

  const getAllNotificationRules = async () => {
    setError("");
    try {
      const response = await axiosWithAuth.get("/NotificationRule");
      setNotificationRules(response.data);
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(
          trans.fetchLabelKeyTranslation(
            "FetchError",
            "Cannot fetch notification rules at the moment. Please try again later."
          )
        );
      }
    }
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const deleteNotificationRule = async (id: number) => {
    try {
      const swalOptions: any = {
        customClass: {
          container: "my-swal",
        },
        title: trans.fetchLabelKeyTranslation(
          "SwalDeleteTitle",
          "Are you sure you want to delete this notification rule"
        ),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: trans.fetchLabelKeyTranslation("SwalDeleteConfirmText", "Ok"),
        cancelButtonColor: "#d33",
        cancelButtonText: trans.fetchLabelKeyTranslation("SwalDeleteRejectText", "Cancel"),
      };
      const response = await swal.fire(swalOptions);
      if (response.isConfirmed) {
        setLoader(true);
        await axiosWithAuth.delete(`/NotificationRule/${id}`);
        const updatedNotficationRules = notificationRules?.filter(
          (notification: GetNotificationRule) => notification.id !== id
        );
        setNotificationRules(updatedNotficationRules);
        setLoader(false);
        const swalOptions: any = {
          customClass: {
            container: "my-swal",
          },
          title: trans.fetchLabelKeyTranslation(
            "SwalDeleteSuccessTitle",
            "Notification Rule deleted successfully"
          ),
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: trans.fetchLabelKeyTranslation("SwalDeleteConfirmText", "Ok"),
        };
        await swal.fire(swalOptions);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const swalOptions: any = {
          customClass: {
            container: "my-swal",
          },
          title: trans.fetchLabelKeyTranslation(
            "SwalDeleteFailureTitle",
            `Notification Rule deletion failed`
          ),
          text: trans.fetchLabelKeyTranslation(
            "SwalDeleteFailureText",
            `Notification Rule cannot be deleted. Please try again later.`
          ),
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: trans.fetchLabelKeyTranslation("NotificationSetupConfirmText", "Ok"),
        };
        await swal.fire(swalOptions);
      }
      setLoader(false);
    }
  };

  const upsertNotificationRule = (
    insert: boolean,
    notificationData: GetNotificationRule
  ) => {
    if (insert) {
      if (notificationRules) {
        setNotificationRules([...notificationRules, notificationData]);
      }
    } else {
      const updatedRules: GetNotificationRule[] | undefined =
        notificationRules?.map((notification: GetNotificationRule) => {
          if (notification.id === notificationData.id) {
            return notificationData;
          } else {
            return notification;
          }
        });
      if (updatedRules) {
        setNotificationRules(updatedRules);
      }
    }
  };

  const editClickHandler = (notificationData: GetNotificationRule) => {
    if (notificationData) setNotificationUpdateData(notificationData);
    toggleDialog();
  };

  const addNotificationHandler = () => {
    setNotificationUpdateData(undefined);
    toggleDialog();
  };

  const searchHandler = (event: InputChangeEvent) => {
    setSearchText(event.value.toLowerCase());
  };

  return (
    <>
      {trans.translationsLoading && (
        <LoadingOverlay
          customStyle={{ position: "fixed", marginTop: "55px" }}
          themeColor={"light"}
          size={"medium"}
          loadingText={trans.fetchLabelKeyTranslation(
            "SwitchLanguageText",
            ""
          )}
        />
      )}
      <Tooltip anchorElement={"target"} parentTitle={true} position={"top"}>
        <div className="contentPushArea boxUserGroups fullBoxCard h-100">
          <div className="contentHead h-100">
            <div className="fulCardList p-r-8 p-l-8">
              <div className="contentHeadTopInr d-flex justify-content-between align-items-center border-bottom-w-1 border-bottom-solid border-black-2 p-b-15">
                <div className="contentHeadTitle fs-18">
                  {trans.fetchLabelKeyTranslation(
                    "NotificationSetupTitle",
                    "My Notifications"
                  )}
                </div>
                <div className="contentHeadAction">
                  <div className="btn-sm buttons-container hov-transparent d-flex align-itmes-center p-0">
                    <div className="form-group searchCol searchBox searchIcon m-r-10">
                      <Input
                        className="input-search"
                        placeholder={
                          trans.fetchLabelKeyTranslation(
                            "SearchPlaceholder",
                            "Search..."
                          )
                        }
                        onChange={(event) => searchHandler(event)}
                        name="search"
                        id="search"
                        type="text"
                        style={{
                          height: "32px",
                        }}
                      />
                      <span className="searchIconBtn">
                        <i className="bi bi-search"></i>
                      </span>
                    </div>
                    <Button
                      className="btn bg-primary line-height-1 text-white p-l-5"
                      style={{
                        height: "32px",
                      }}
                      onClick={addNotificationHandler}
                    >
                      <i className="bi bi-plus fs-20" title="Add"></i>
                      <span>
                        {trans.fetchLabelKeyTranslation(
                          "AddButtonText",
                          "Add New"
                        )}
                      </span>
                    </Button>
                    {visible && (
                      <Dialog
                        title={
                          !notificationUpdateData
                            ? trans.fetchLabelKeyTranslation(
                              "NotificationSetupCreateText",
                              "Create New Notification"
                            )
                            : trans.fetchLabelKeyTranslation(
                              "NotificationSetupUpdateText",
                              "Update Notification"
                            )
                        }
                        onClose={toggleDialog}
                      >
                        <UpsertNotificationSetup
                          toggleDialog={toggleDialog}
                          upsertNotificationRule={upsertNotificationRule}
                          notificationUpdateData={notificationUpdateData}
                        />
                      </Dialog>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {(loader || (!notificationRules && error === "")) && (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <Loader size="medium" type="infinite-spinner" />
              </div>
            )}
            {!notificationRules && error !== "" && (
              <Error
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                {error}
              </Error>
            )}
            {notificationRules &&
              (!notificationRules.length ||
                !notificationRules.filter((notification: GetNotificationRule) => {
                  return notification.name.toLowerCase().includes(searchText);
                }).length) ? (
              <p style={{ marginTop: "10px", textAlign: "center" }}>
                {trans.fetchLabelKeyTranslation(
                  "EmptyNotificationsError",
                  "There are no notification rules for you"
                )}
              </p>

            ) : (
              <>
                {!loader && (
                  <div className="contentBody">
                    <div className="cardBrand">
                      <div className="cardListArea p-r-15 p-l-15">
                        <div className="row">
                          {notificationRules &&
                            notificationRules
                              .filter((notification: GetNotificationRule) => {
                                return notification.name
                                  .toLowerCase()
                                  .includes(searchText);
                              })
                              .map((notification: GetNotificationRule) => {
                                return (
                                  <NotificationSetupViewBox
                                    notificationRule={notification}
                                    deleteNotificationRule={
                                      deleteNotificationRule
                                    }
                                    editClickHandler={editClickHandler}
                                  />
                                );
                              })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Tooltip>
    </>
  );
};

export default NotificationSetup;
