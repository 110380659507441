import React, { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { RecordingAction } from "../../types/recording/RecordingAction";
import { AxiosError } from "axios";
import recordingsService from "../../services/recordings.service";
import ContentPlaceholder from "../../components/ContentPlaceholder";
import useMasterData from "../../hooks/useMasterData";
import { RecordingActionState } from "../../types/master-data/RecordingActionState";
import { SweetAlertOptions } from "sweetalert2";
import useSwal from "../../hooks/useSwal";

const statusOptions = [
  { id: 1, className: "text-secondary" },
  { id: 2, className: "text-warning" },
  { id: 3, className: "text-success" },
  { id: 4, className: "text-danger" }
];

// Defining a type for the status items
interface StatusItem {
  text: string;
  className: string;
}

interface RecordingActionTabProps {
  recordingId: number;
  isAIAnalysed?: boolean;
  fetchLabelKeyTranslation: (key: string, defaultVal: string) => string;
  actions?: RecordingAction[];
}

const RecordingActionTab: React.FC<RecordingActionTabProps> = ({recordingId , isAIAnalysed , fetchLabelKeyTranslation, actions}) => {
  const masterData = useMasterData();
  const swal = useSwal();

  const updateActionState = async ( recordingAction : RecordingAction, state : any) => {
    try{
      const data = await recordingsService.updateRecordingAction(
        recordingId,
        recordingAction.id,
        state.name
      );
    } catch(err){
      if(err instanceof Error){
        if(err instanceof AxiosError) {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "error",
            title: "Error",
            text: "Recording Action Update failed. Please try again.",
          };
          await swal.fire(swalOptions);
        }
      }
    }
  }

  const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: any) => {
    const item : any = statusOptions.find((option) => option.id === itemProps.dataItem.id);

    // Cloning the list item and applying the className dynamically
    return React.cloneElement(
      li,
      //item.className,
      { className: item.className },
      li.props.children && typeof li.props.children === 'string' ? li.props.children : <>{li.props.children}</>
    );
  };

  // Function to render the selected value with appropriate class
  const valueRender = (element: React.ReactElement<HTMLSpanElement>, value: RecordingActionState) => {
    if (!value) return null;
    const selectedItem = statusOptions.find(option => option.id === value.id); // Find the selected item
    return (
      <span className={selectedItem?.className}>
        {value.name}
      </span>
    );
  };

  return isAIAnalysed ? (
    actions ? (
      <div className="row">
        <div className="col-12 call-actions">
          <div className="card">
            <ul className="list-group list-group-flush">
              {
                actions?.map( (item) => {
                  return (<li className="list-group-item" key={item.id}>
                    <div className="d-flex justify-content-between">
                      <div className="title mr-2">
                        {item.action}
                      </div>
                      <form className="k-form" onSubmit={(e) => e.preventDefault()}>
                        <fieldset className="status-dropdown">
                          <DropDownList
                            style={{
                              width: "130px",
                            }}
                            data={masterData?.data?.recordingActionState}
                            textField="name"
                            itemRender={itemRender}
                            valueRender={valueRender}
                            defaultValue={masterData?.data?.recordingActionState.find( (options) => options.name === item.state) }
                            onChange={(e) => {
                              updateActionState(item,e.target.value);
                            }}
                            //opened={true}
                          />
                        </fieldset>
                      </form>
                    </div>
                  </li>);
                })
              }
            </ul>
          </div>
        </div>
      </div>
    ) : (
      <ContentPlaceholder>
        {
          <span>
            <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
            {fetchLabelKeyTranslation(
              "NoActionsMsg",
              "NO ACTIONS AVAILABLE"
            )}
          </span>
        }
      </ContentPlaceholder>
    )
  ) : (
    <p>
      {fetchLabelKeyTranslation(
        "UpdateLicenseActionText",
        "Actions not available for this recording"
      )}
    </p>
  );
};

export default RecordingActionTab;

