import { RecordingTranscription } from "../../types/recording";
import { TinyUser } from "../../types/user";
import { RecordingTrackSegment } from "../../types/player-track";
import Transcription from "./Transcription";
import RecordingSummaryTab from "./RecordingSummaryTab";
import { RecordingSummary } from "../../types/recording/RecordingSummary";
import { useEffect } from "react";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { useState } from "react";
import RecordingActionTab from "./RecordingActionTab";
import { RecordingAction } from "../../types/recording/RecordingAction";

interface TranscriptionTabProps {
  rawTranscript?: RecordingTranscription;
  rawTranscriptError?: string;
  sentimentsTrackInsight?: RecordingTrackSegment[];
  playbackProgress: number;
  secondParties?: TinyUser[];
  callDatetime?: string;
  isAIAnalysed?: boolean;
  shared?: boolean;
  fetchLabelKeyTranslation: (key: string, defaultVal: string) => string;
  lockedSegmentCount: number;
  summary?: RecordingSummary;
  recordingId : number;
  actions?: RecordingAction[];
}

const TranscriptionTab: React.FC<TranscriptionTabProps> = ({
  rawTranscript,
  rawTranscriptError,
  sentimentsTrackInsight,
  playbackProgress,
  secondParties,
  callDatetime,
  isAIAnalysed,
  shared,
  fetchLabelKeyTranslation,
  lockedSegmentCount,
  summary,
  recordingId,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };

  return (
    <div className="contentHeadTab h-100 call-summary-tab">
      <div className="tabLabel tabLeftPadding-0 h-100">
        <TabStrip selected={selectedTab} onSelect={handleSelect}>
          <TabStripTab
            title={`${fetchLabelKeyTranslation(
              "TranscriptTabTitle",
              "Transcript"
            )}`}
          >
            <Transcription
              rawTranscript={rawTranscript}
              rawTranscriptError={rawTranscriptError}
              sentimentsTrackInsight={sentimentsTrackInsight}
              playbackProgress={playbackProgress}
              secondParties={secondParties}
              callDatetime={callDatetime}
              isAIAnalysed={isAIAnalysed}
              shared={shared}
              fetchLabelKeyTranslation={fetchLabelKeyTranslation}
              lockedSegmentCount={lockedSegmentCount}
            />
          </TabStripTab>

          {(
            <TabStripTab
              title={`${fetchLabelKeyTranslation(
                "SummaryTabTitle",
                "Summary"
              )}`}
            >
              <RecordingSummaryTab 
                summary={summary} 
                fetchLabelKeyTranslation={fetchLabelKeyTranslation}
                isAIAnalysed={isAIAnalysed}
              />
            </TabStripTab>
          )}
          <TabStripTab
            title={`${fetchLabelKeyTranslation(
              "ActionTabTitle",
              "Actions"
            )}`}
          >
            <RecordingActionTab 
              recordingId = {recordingId}
              isAIAnalysed={isAIAnalysed}
              fetchLabelKeyTranslation={fetchLabelKeyTranslation}
              actions={actions}
            />
          </TabStripTab>
        </TabStrip>
      </div>
    </div>
  );
};

export default TranscriptionTab;
