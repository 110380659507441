export interface InsightKeysData {
    Directions: string;
    Received: string;
    Dialled: string;
    Transcribed: string;
    Transcription: string;
    Evaluation: string;
    Total: string;
    Good: string;
    Average: string;
    Poor: string;
    List: string
    Sentiments: string;
    Positive: string;
    Neutral: string;
    Negative: string;
    Flags: string;
    Red: string;
    Green: string;
    Yellow: string;
    Blue: string;
    Orange: string;
    Topics: string;
    CallResults: string;
    Hour: string;
    Day: string;
    Week: string;
    Month: string;
    Users: string;
    Evaluators: string;
    'Call Outcomes': string;
}

export const insightKeys: InsightKeysData = {
    Directions: "KeyDirections",
    Transcription: "KeyTranscription",
    Evaluation: "KeyEvaluation",
    List: "KeyList",
    Sentiments: "KeySentiments",
    Flags: "KeyFlags",
    Topics: "KeyTopics",
    CallResults: "KeyCallResults",
    Received: "ValueReceived",
    Dialled: "ValueDialled",
    Transcribed: "ValueTranscribed",
    Total: "ValueTotal",
    Good: "ValueGood",
    Average: "ValueAverage",
    Poor: "ValuePoor",
    Positive: "ValuePositive",
    Neutral: "ValueNeutral",
    Negative: "ValueNegative",
    Red: "ValueRed",
    Green: "ValueGreen",
    Yellow: "ValueYellow",
    Blue: "ValueBlue",
    Orange: "ValueOrange",
    Hour: "ValueHour",
    Day: "ValueDay",
    Week: "ValueWeek",
    Month: "ValueMonth",
    Users: "ValueUsers",
    Evaluators: "ValueEvaluators",
    'Call Outcomes': "ValueCallOutcomes",
}

interface IMetricKey {
    id: number,
    title: string,
    localizationKey: string,
    trim: boolean
}

export const days: [IMetricKey, IMetricKey, IMetricKey, IMetricKey, IMetricKey, IMetricKey, IMetricKey] = [
    {
        id: 1,
        title: "Sunday",
        localizationKey: "SundayText",
        trim: true
    },
    {
        id: 2,
        title: "Monday",
        localizationKey: "MondayText",
        trim: true
    },
    {
        id: 3,
        title: "Tuesday",
        localizationKey: "TuesdayText",
        trim: true
    },
    {
        id: 4,
        title: "Wednesday",
        localizationKey: "WednesdayText",
        trim: true
    },
    {
        id: 5,
        title: "Thursday",
        localizationKey: "ThursdayText",
        trim: true
    },
    {
        id: 6,
        title: "Friday",
        localizationKey: "FridayText",
        trim: true
    },
    {
        id: 7,
        title: "Saturday",
        localizationKey: "SaturdayText",
        trim: true
    }
]

export const months: [IMetricKey, IMetricKey, IMetricKey, IMetricKey, IMetricKey, IMetricKey,
    IMetricKey, IMetricKey, IMetricKey, IMetricKey, IMetricKey, IMetricKey] = [
        {
            id: 1,
            title: "January",
            localizationKey: "JanuaryText",
            trim: true
        },
        {
            id: 2,
            title: "February",
            localizationKey: "FebruaryText",
            trim: true
        },
        {
            id: 3,
            title: "March",
            localizationKey: "MarchText",
            trim: true
        },
        {
            id: 4,
            title: "April",
            localizationKey: "AprilText",
            trim: true
        },
        {
            id: 5,
            title: "May",
            localizationKey: "MayText",
            trim: true
        },
        {
            id: 6,
            title: "June",
            localizationKey: "JuneText",
            trim: true
        },
        {
            id: 7,
            title: "July",
            localizationKey: "JulyText",
            trim: true
        },
        {
            id: 8,
            title: "August",
            localizationKey: "AugustText",
            trim: true
        },
        {
            id: 9,
            title: "September",
            localizationKey: "SeptemberText",
            trim: true
        },
        {
            id: 10,
            title: "October",
            localizationKey: "OctoberText",
            trim: true
        },
        {
            id: 11,
            title: "November",
            localizationKey: "NovemberText",
            trim: true
        },
        {
            id: 12,
            title: "December",
            localizationKey: "DecemberText",
            trim: true
        }
    ]

export const processMetricKeyForDisplay = (
    metricKeyLabel: string, metricKey: string, isInsightViewByGrid: boolean = false
): IMetricKey => {
    if (metricKeyLabel === "Hour") {
        const hour = Number(metricKey);
        if (isInsightViewByGrid) {
            const title: string = `${hour > 9
                ? `${hour}:00 - ${hour === 23 ? "00" : hour + 1}:00`
                : `${hour}:00 - ${hour + 1}:00`
                }`;
            return {
                id: hour,
                title: title,
                localizationKey: title,
                trim: false
            }
        }
        else {
            const title: string = `${hour > 9 ? `${hour}:00` : `0${hour}:00`}`;
            return {
                id: hour,
                title: title,
                localizationKey: title,
                trim: false
            }
        }
    }
    else if (metricKeyLabel === "Day") {
        const dayObj = days[Number(metricKey)];
        return dayObj;
    }
    else if (metricKeyLabel === "Week") {
        const title: string = `WC ${metricKey}`;
        return {
            id: 0,
            title: title,
            localizationKey: title,
            trim: false
        }
    }
    else if (metricKeyLabel === "Month") {
        const monthObj = months[Number(metricKey) % months.length];
        return monthObj;
    }
    // If metricKeyLabel = Sentiments, Flags, Topics, CallResults
    return {
        id: 0,
        title: metricKey,
        localizationKey: metricKey,
        trim: false
    };
};