import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { AUTH_BASE_URL, AUTH_CLIENT_ID } from "../../constants";
import authService from "../../services/auth.service";
import LoadingOverlay from "../../components/LoadingOverlay";
import { AxiosError } from "axios";
import useBranding from "../../hooks/useBranding";
import defaultLogo from "../../assets/images/logo.png";
import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { appUrl } from "../..";
import { LoginUser } from "../../types/auth/LoginUser";
import { Dictionary } from "../../types/Dictionary";
import useLocale from "../../hooks/useLocale";
import { SweetAlertOptions } from "sweetalert2";
import useSwal from "../../hooks/useSwal";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import CustomInput from "../../components/custom/form/CustomInput";
import { ComboBox, ListItemProps } from "@progress/kendo-react-dropdowns";
import CustomComboBox from "../../components/custom/form/CustomComboBox";
import { PairCustomerInfoAndId } from "../../types/auth/PairCustomerInfoAndId";
import demoInstanceService from "../../services/demoInstance.service";
import useTranslation from "../../hooks/useTranslation";
import { SessionStorageKeys } from "../../enums";
import { LocalStorageKeys } from "../../enums/localStorageKeys";

const Login: React.FC = () => {
  const trans = useTranslation("Login");
  const brandingCtx = useBranding();
  const localeCtx = useLocale();
  const swal = useSwal();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [loginUserToken, setLoginUserToken] = useState<string>();
  const [customerList, setCustomerList] = useState<PairCustomerInfoAndId[]>([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number>(0);

  const [isDemoInstance, setIsDemoInstance] = useState<boolean>(false);
  const [isDemoInstanceChecked, setIsDemoInstanceChecked] = useState<boolean>(false);
  const from = location.state?.from?.pathname + location.state?.from?.search || "/home";

  const username = searchParams.get("username");
  const appToken = searchParams.get("appToken");
  const language = searchParams.get("language");

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["Login"]) {
      trans.fetchTranslations("Login");
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    if (!appToken) {
      demoInstanceService.fetchIsDemoInstance()
        .then((response) => {
          setLoading(true)
          if (response) {
            setIsDemoInstance(true)
            navigate("/demo-login-register", { replace: true });
          } else {
            setIsDemoInstance(false)
          }
        })
        .catch(error => {
          setError("InternalServerError");
        })
        .finally(() => {
          setLoading(false);
          setIsDemoInstanceChecked(true)
        });
    } else {
      setIsDemoInstanceChecked(true)
    }
  }, [])



  useEffect(() => {
    if (!isDemoInstanceChecked || isDemoInstance) return;

    let originalRedirectUri = searchParams.get("originalRedirectUri");
    if(originalRedirectUri==="/" || from==="/"){
      originalRedirectUri='/home';
    }
    let authAppBaseUrl = AUTH_BASE_URL;

    if (brandingCtx?.branding?.authAppConfiguration?.authClientAppUrl) {
      authAppBaseUrl =
        brandingCtx.branding.authAppConfiguration.authClientAppUrl;
    }
    let authAppClientId = AUTH_CLIENT_ID;
    if (brandingCtx?.branding?.authAppConfiguration?.authAppClientId) {
      authAppClientId =
        brandingCtx.branding.authAppConfiguration.authAppClientId;
    }
    const authAppLoginUrl = `${authAppBaseUrl}/login?clientId=${authAppClientId}&originalRedirectUri=${originalRedirectUri ?? from}`;
    if (brandingCtx?.branding) {
      if (username && appToken) {
        setLoading(true);
        authService
          .verifycredentials({ username, appToken })
          .then((tempTokenResponse) => {
            if (tempTokenResponse.customerList.length > 1) {
              setLoginUserToken(tempTokenResponse.userValidationToken);
              setCustomerList(tempTokenResponse.customerList);
              setShowForm(true);
            } else {
              if (tempTokenResponse.customerList.length > 0) {
                setSelectedCustomerId(tempTokenResponse.customerList[0].id);
                const loginUser: LoginUser = {
                  username: username,
                  userValidationToken: tempTokenResponse.userValidationToken,
                  customerId: tempTokenResponse.customerList[0].id,
                };
                authService.login(loginUser).then((token) => {
                  if (token && token.trim().length > 0) {
                    window.localStorage.removeItem(
                      LocalStorageKeys.VoiceAIInsightsFilter
                    );
                    window.sessionStorage.removeItem(SessionStorageKeys.insightId);
                    sessionStorage.removeItem("VoiceAiCallFilter");
                    console.log("Token: ", token);
                    auth?.setUserAuthToken(token);
                    navigate(
                      originalRedirectUri ?? `/home?language=${language}`,
                      { replace: true }
                    );
                  }
                });
              }
            }
          })
          .catch((error) => {
            auth?.setUserAuthToken(undefined);
            if (error instanceof AxiosError) {
              if (error.response?.status === 400) {
                setError("UserNotFound");
              }
              else if (error.response?.status === 401) {
                setError("Unauthorized");
              }
              if (error.response?.status === 403) {
                setError("UserLoginDisabled");
              }
              else if (error.response?.status === 500) {
                setError("InternalServerError");
              }
              else {
                setError("InternalServerError");
              }
            }
            //window.location.replace(authAppLoginUrl);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (auth?.accessToken) {
        navigate(from, { replace: true });
      } else {
        window.location.replace(authAppLoginUrl);
      }
    }
  }, [isDemoInstanceChecked, isDemoInstance, brandingCtx?.brandingLoaded]);

  const onErrorHandler = async () => {
    let authAppBaseUrl = AUTH_BASE_URL;
    if (brandingCtx?.branding?.authAppConfiguration.authClientAppUrl) {
      authAppBaseUrl =
        brandingCtx.branding.authAppConfiguration.authClientAppUrl;
    }
    let authAppClientId = AUTH_CLIENT_ID;
    if (brandingCtx?.branding?.authAppConfiguration.authAppClientId) {
      authAppClientId =
        brandingCtx.branding.authAppConfiguration.authAppClientId;
    }

    try {
      window.location.replace(
        `${authAppBaseUrl}/logout?clientId=${authAppClientId}&redirectUri=${appUrl}/login`
      );
    } catch (err) {
      console.error(err);
    }
  };

  const submitHandler = async () => {
    let originalRedirectUri = searchParams.get("originalRedirectUri");
    const loginUser: LoginUser = {
      username: username ?? "",
      userValidationToken: loginUserToken ?? "",
      customerId: selectedCustomerId,
    };
    authService.login(loginUser).then((token) => {
      if (token && token.trim().length > 0) {
        console.log("Token: ", token);
        window.localStorage.removeItem(
          LocalStorageKeys.VoiceAIInsightsFilter
        );
        window.sessionStorage.removeItem(SessionStorageKeys.insightId);
        auth?.setUserAuthToken(token);
        if(!originalRedirectUri || originalRedirectUri==="/"){
          originalRedirectUri=`/home?language=${language}`
        }
        navigate(originalRedirectUri,{ replace: true });
      }
    });
  };

  const backHandler = async () => {
    let authAppBaseUrl = AUTH_BASE_URL;
    if (brandingCtx?.branding?.authAppConfiguration.authClientAppUrl) {
      authAppBaseUrl =
        brandingCtx.branding.authAppConfiguration.authClientAppUrl;
    }
    let authAppClientId = AUTH_CLIENT_ID;
    if (brandingCtx?.branding?.authAppConfiguration.authAppClientId) {
      authAppClientId =
        brandingCtx.branding.authAppConfiguration.authAppClientId;
    }
    window.location.replace(
      `${authAppBaseUrl}/logout?clientId=${authAppClientId}&redirectUri=${appUrl}/login`
    );
    try {
      console.log("Loggin out...");
    } catch (err) {
      console.error(err);
    }
  };

  const itemRenderCustomers = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const isActive = itemProps.dataItem?.isActive;
    const isActiveText = isActive ? trans.fetchLabelKeyTranslation("IsActiveText","Active") : trans.fetchLabelKeyTranslation("IsInactiveText","Inactive");
    const isActiveStyle = isActive ? { color: "green" } : { color: "red" };

    const itemChildren = (
      <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: "100%" }}>
        <span>{itemProps.dataItem?.customerName}</span>
        <span style={isActiveStyle}>{isActiveText}</span>
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <div className="loginBg float-left w-100 h-100">
      <div className="h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            {loading && (
              <LoadingOverlay
                customStyle={{ position: "fixed", marginTop: "55px" }}
                themeColor={"light"}
                size={"medium"}
                loadingText={"Redirecting..."}
              />
            )}
            {showForm && (
              <div className="formGroup h-100">
                <div
                  className="formInrG cardEffect"
                  style={{ position: "relative" }}
                >
                  {brandingCtx?.branding?.logos?.find(
                    (l) => l.name === "HeaderPrimaryLogo"
                  )?.logoImageUrl && (
                      <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                        {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
                        <img
                          src={
                            brandingCtx?.branding?.logos?.find(
                              (l) => l.name === "HeaderPrimaryLogo"
                            )?.logoImageUrl
                          }
                          alt="Logo"
                        />
                      </div>
                    )}
                  <Form
                    initialValues={{
                      customer: undefined,
                    }}
                    onSubmit={submitHandler}
                    render={(formRenderProps) => (
                      <FormElement style={{ maxWidth: "100%" }}>
                        <fieldset className={"k-form-fieldset"}>
                          <legend
                            className={"k-form-legend fs-18 font-weight-semi"}
                            style={{ textTransform: "none" }}
                          >
                            {trans.fetchLabelKeyTranslation(
                              "LoginFormTitle",
                              "Please select the customer"
                            )}
                          </legend>
                          <div className="m-b-15">
                            <Field
                              id="customer"
                              name={"Customer"}
                              className="comboBoxInput"
                              placeholder={trans.fetchLabelKeyTranslation("CustomerSelectPlaceholderTxt","Please select ...")}
                              data={customerList ?? []}
                              textField="customerName"
                              itemRender={itemRenderCustomers}
                              value={formRenderProps.valueGetter("customer")}
                              component={CustomComboBox}
                              dataItemKey="id"
                              sorting={true}
                              style={{ width: '100%' }}
                              onChange={(e) => {
                                setSelectedCustomerId(e.value.id);
                              }}
                            />
                          </div>
                        </fieldset>
                        <div className="k-form-buttons d-flex justify-content-center m-t-15" style={{ width: '100%' }}>
                          <Button
                            type={"submit"}
                            style={{
                              width: "100%",
                              textTransform: "uppercase",
                            }}
                            className={`btn bg-primary text-white fs-16 p-t-7 p-b-7 ${selectedCustomerId === 0 && "disabledBtn"
                              }`}
                            disabled={selectedCustomerId === 0}
                          >
                            <i className="bi bi-lock fs-14 p-r-5"></i>
                            {trans.fetchLabelKeyTranslation(
                              "SubmitBtnText",
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </FormElement>
                    )}
                  />
                  <p className="text-center text-muted m-t-6 m-b-0 fs-16">
                    {trans.fetchLabelKeyTranslation("GoText", "Go")}{" "}
                    <span
                      onClick={backHandler}
                      className="forgotPasswordLbl cursor-pointer btn-link"
                    >
                      {trans.fetchLabelKeyTranslation("BackText", "Back")}
                    </span>
                  </p>
                </div>
              </div>
            )}
            {!showForm && !loading && error && (
              <div className="loginBg h-100 p-l-15 p-r-15">
                <div className="row m-b-20 h-100">
                  <div className="col-md-12 h-100">
                    <div className="formGroup h-100">
                      <div
                        className="formInrG cardEffect"
                        style={{ position: "relative" }}
                      >
                        <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                          <a className="navbar-logo" href="" title="Logo">
                            <img
                              src={
                                brandingCtx?.branding?.logos?.find(
                                  (l) => l.name === "HeaderPrimaryLogo"
                                )?.logoImageUrl ?? defaultLogo
                              }
                              alt="Logo"
                            />
                          </a>
                        </div>
                        <Error className="fs-18">
                          {trans.fetchLabelKeyTranslation(error, error)
                          }
                        </Error>
                        <div className="text-center p-t-5 p-b-15">
                          <Button
                            className={`btn bg-primary text-white `}
                            onClick={onErrorHandler}
                          >
                            {trans.fetchLabelKeyTranslation(
                              "BackButtonText",
                              "Back to Login Page"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
