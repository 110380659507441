import "./Insights.scss";
import React, { useEffect, useState } from "react";
import moment from "moment";
import 'moment/min/locales';
import {
  Splitter,
  SplitterOnChangeEvent,
  SplitterPaneProps,
} from "@progress/kendo-react-layout";
import LoadingOverlay from "../../components/LoadingOverlay";
import {
  Insight,
  InsightMetricKeyLabel,
  InsightsFilter,
  TopicInsightDetail,
  TopicsInsight,
} from "../../types/insight";
import { Dictionary } from "../../types/Dictionary";
import useSessionStorage from "../../hooks/useSessionStorage";
import InsightMetricCards from "./InsightMetricCards";
import InsightDataAggregateCard from "./InsightDataAggregateCard";
import insightsService from "../../services/insights.service";
import useLocale from "../../hooks/useLocale";
import useSwal from "../../hooks/useSwal";
import { SessionStorageKeys } from "../../enums";
import { useSearchParams } from "react-router-dom";
import { Dates, getDateBasedOnFilterType } from "../../utils/FilterDrawerUtil";
import { InsightFilterDates } from "../../types/insight/InsightFilterDates";
import useTranslation from "../../hooks/useTranslation";
import useLocalStorage from "../../hooks/useLocalStorage";
import { CallFilter } from "../../types/filters";
import { LocalStorageKeys } from "../../enums/localStorageKeys";

export interface InsightFilterMetric {
  metricKeyLabel: string;
  metricKeySubLabel?: string;
  metricKey: string;
}

interface AugmentedInsightsFilter extends CallFilter {
  viewByMetric?: InsightMetricKeyLabel;
  filterMetric?: InsightFilterMetric;
}

const Insights: React.FC = () => {
  const localeCtx = useLocale();
  const trans=useTranslation("Insights");
  const [searchParams, setSearchParams] = useSearchParams();
  const swal = useSwal();
  const [splitterPanes, setSplitterPanes] = React.useState<
    Array<SplitterPaneProps>
  >([
    {
      size: "30%",
      min: "230px",
      max: "660px",
      resizable: true,
      collapsible: true,
      collapsed: false,
    },
    {},
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterLoading, setFilterLoading] = useState<boolean>(false);
  const [insight, setInsight] = useState<Insight>();
  const [filterDates, setFilterDates] = useState<InsightFilterDates>();
  const [insightsFilter, setInsightsFilter] = useLocalStorage<
    AugmentedInsightsFilter | undefined
  >(LocalStorageKeys.VoiceAIInsightsFilter, undefined);

  const defaultViewByMetric: InsightMetricKeyLabel = {
    id: 0,
    name: "default",
    displayName: "List",
    description: trans.fetchLabelKeyTranslation(
            "DefaultViewByMetricText",
            "Default ViewBy Metric for showing call list"
          ),
  };

  const defaultFilterMetric: InsightFilterMetric = {
    metricKeyLabel: trans.fetchLabelKeyTranslation("TotalCallsText", "TotalCalls"),
    metricKey: trans.fetchLabelKeyTranslation("TotalCallsText", "TotalCalls"),
  };

  const [viewByMetric, setViewByMetric] =
    useState<InsightMetricKeyLabel>(defaultViewByMetric);
  const [filterMetric, setFilterMetric] = useState<
    InsightFilterMetric | undefined
  >(defaultFilterMetric);

  const language = searchParams.get("language");

  useEffect(() => {
    if (localeCtx?.selectedLocale?.current.locale.code !== language) {
      var selectedLocalization = localeCtx?.supportedLocales.filter(
        (x) => x.code === language
      )[0];
      console.log(selectedLocalization);
      if (selectedLocalization) {
        localeCtx?.setUserLocale(selectedLocalization);
      }
    }
    // if an insight filter is already found in local storage
    if (insightsFilter) {
      // get the existing insight from API svc.
      // set "ViewBy" Metric and "Filter" Metric.
      upsertInsight(insightsFilter);
      setViewByMetric(insightsFilter.viewByMetric ?? defaultViewByMetric);
      setFilterMetric(insightsFilter.filterMetric ?? defaultFilterMetric);
    }
    // if no insight filter is found
    else {
      // create a new insight from API svc using "Today" Period Call Filter.
      // set Insight filter in session storage to "Today" filter.
      // set the insightId in Insights Filter object to the response Id you get from API svc.
      // set "ViewBy" Metric to "List" and "Filter" Metric to "TotalCalls"
      const defaultFilter: AugmentedInsightsFilter = {
        startDate: moment(new Date().setHours(0, 0, 0)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        endDate: moment(new Date().setHours(23, 59, 59)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        periodType: 1,
        filterType: 1,
        viewByMetric: defaultViewByMetric,
        filterMetric: defaultFilterMetric,
      };
      upsertInsight(defaultFilter);
      setInsightsFilter(defaultFilter);
    }

    return () => {
      const iId=window.sessionStorage.getItem(SessionStorageKeys.insightId)
      if (iId) {
        insightsService
          .deleteUserInsight(iId)
          .then((res) => {
            if (res) {
              window.sessionStorage.removeItem(SessionStorageKeys.insightId)
            }
          })
      }
    };
  }, []);

  window.addEventListener('storage', function(event) {
    if(event.key==="VoiceAIInsightsFilter"){
      const oldValue=JSON.parse(event.oldValue??"");
      const newValue=JSON.parse(event.newValue??"");
      if(oldValue.insightId==newValue.insightId){
        window.location.reload();
      }
    }
 });


  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["Insights"]) {
      trans.fetchTranslations("Insights")
    }
  }, [localeCtx?.selectedLocale]);

  const sortTopics = (resp: TopicsInsight): TopicInsightDetail[] => {
    const exactTopics: TopicInsightDetail[] = [],
      extendedTopics: TopicInsightDetail[] = [];
    resp &&
      resp.topics.length &&
      resp.topics.forEach((topic: TopicInsightDetail) => {
        if (topic.isExtendedSearch) {
          extendedTopics.push(topic);
        } else {
          exactTopics.push(topic);
        }
      });
    const updatedExtendedTopics: TopicInsightDetail[] = extendedTopics.sort(
      (a: TopicInsightDetail, b: TopicInsightDetail) => {
        if (
          a.name.substring(4).toLowerCase() > b.name.substring(4).toLowerCase()
        ) {
          return 1;
        }
        if (
          a.name.substring(4).toLowerCase() < b.name.substring(4).toLowerCase()
        ) {
          return -1;
        }
        return 0;
      }
    );

    const updatedExactTopics: TopicInsightDetail[] = exactTopics.sort(
      (a: TopicInsightDetail, b: TopicInsightDetail) => {
        if (
          a.name.substring(4).toLowerCase() > b.name.substring(4).toLowerCase()
        ) {
          return 1;
        }
        if (
          a.name.substring(4).toLowerCase() < b.name.substring(4).toLowerCase()
        ) {
          return -1;
        }
        return 0;
      }
    );
    return updatedExactTopics.concat(updatedExtendedTopics);
  };

  const upsertInsight = async (filter: InsightsFilter): Promise<boolean> => {
    try {      
      setLoading(true);
      setFilterDates({startdate : filter.startDate, enddate : filter.endDate})

      if(filter.periodType !== 4){
        const getFilterBasesDates: Dates = getDateBasedOnFilterType(
          filter.periodType ?? 0,
          filter.filterType ?? 0
        );
        filter = {...filter,
          startDate: moment(getFilterBasesDates.tempStartDate).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          endDate: moment(getFilterBasesDates.tempEndDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        }
      }
      const resp = await insightsService.upsertUserInsight(filter);
      resp.topicsInsight.topics = sortTopics(resp.topicsInsight);
      setInsightsFilter(filter)
      setInsight(resp);
      window.sessionStorage.setItem(SessionStorageKeys.insightId , resp.id);
      return true;
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
    return false;
  };

  const insightsFilterUpdateHandler = async (filter: InsightsFilter) => {
    await upsertInsight(filter);
  };

  const onFilterMetricClickHandler = (
    metricKey: string,
    metricKeyLabel?: string,
    metricKeySubLabel?: string
  ) => {
    if (metricKeyLabel) {
      setFilterLoading(true);
      setFilterMetric({
        metricKeyLabel: metricKeyLabel,
        metricKeySubLabel: metricKeySubLabel,
        metricKey: metricKey,
      });
      setInsightsFilter({
        ...insightsFilter,
        filterMetric: {
          metricKeyLabel: metricKeyLabel,
          metricKeySubLabel: metricKeySubLabel,
          metricKey: metricKey,
        },
      });
      setFilterLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const onViewByFilterClick = (
    viewByFilter: InsightMetricKeyLabel
  ): boolean => {
    setFilterLoading(true);
    setViewByMetric(viewByFilter);
    setInsightsFilter({ ...insightsFilter, viewByMetric: viewByFilter });
    setFilterLoading(false);
    return true;
  };

  const onUserFilterRemove = (name: string, isViewBy: boolean) => {
    if (isViewBy) {
      setInsightsFilter({
        ...insightsFilter,
        viewByMetric: defaultViewByMetric,
      });
      setViewByMetric(defaultViewByMetric);
    } else {
      // when we get to multiple filters, we need to check the filterName in this case and then remove the correct one.
      setFilterMetric(defaultFilterMetric);
      setInsightsFilter({
        ...insightsFilter,
        filterMetric: defaultFilterMetric,
      });
    }
  };

  const handleSplitterPanesChanges = (event: SplitterOnChangeEvent) => {
    setSplitterPanes(event.newState);
  };

  return (
    <React.Fragment>
       {trans?.translationsLoading && !loading && (
        <LoadingOverlay
          customStyle={{ position: "fixed", marginTop: "55px" }}
          themeColor={"light"}
          size={"medium"}
          loadingText={trans?.fetchLabelKeyTranslation(
            "SwitchLanguageText",
            ""
          )}
        />
      )}
      {loading && (
        <LoadingOverlay
          customStyle={{ position: "fixed", marginTop: "55px" }}
          themeColor={"light"}
          size={"medium"}
          loadingText={trans?.fetchLabelKeyTranslation(
                  "LoadingText",
                  "Generating insights and getting everything ready..."
                )}
        />
      )}
      <div className="p-b-20 h-100">
          <div className="mainBox docSpliter d-flex justify-content-between h-100">
            <Splitter
              panes={splitterPanes}
              onChange={handleSplitterPanesChanges}
              // style={{ height: "84vh" }}
            >
              <InsightMetricCards
                insight={insight}
                onMetricClickHandler={onFilterMetricClickHandler}
                filterMetric={filterMetric ?? defaultFilterMetric}
                insightsFilter={insightsFilter}
                onFilterUpdate={insightsFilterUpdateHandler}
              />
              <InsightDataAggregateCard
                docked={
                  splitterPanes[0]?.collapsed
                    ? splitterPanes[0]?.collapsed
                    : false
                }
                insight={insight}
                viewByMetric={viewByMetric}
                filterMetric={filterMetric}
                filterLoading={filterLoading}
                onViewByFilterClick={onViewByFilterClick}
                onUserFilterRemove={onUserFilterRemove}
                filterDates={filterDates}
              />
            </Splitter>
          </div>
      </div>
    </React.Fragment>
  );
};

export default Insights;
