import { Scorecard, ScorecardQuestion } from "../types/scorecard";
import { BooleanSuccess } from "../types/success/BooleanSuccess";
import { TinyObj } from "../types/TinyObj";
import { axiosForShare, axiosWithAuth } from "../utils/customAxios";

const scorecardService = {
  fetchAllScorecards: async (
    short: boolean = false,
    shared: boolean = false
  ): Promise<Scorecard[]> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(`scorecard?short=${short}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  fetchScorecardById: async (
    id: number,
    shared: boolean = false
  ): Promise<Scorecard> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(`scorecard/${id}`);
      const scorecard: Scorecard = response.data;

      return scorecard;
    } catch (err) {
      throw err;
    }
  },

  fetchScorecardUsage: async (scorecardId: number): Promise<boolean> => {
    try {
      const response = await axiosWithAuth.get(`/scorecard/${scorecardId}/usage`);
      const isUsed: BooleanSuccess = response.data;
      return isUsed.state;
    } catch (err) {
      throw err;
    }
  },

  updateScorecardById: async (
    scorecardId: number,
    updatedScorecard: Scorecard
  ): Promise<Scorecard> => {
    try {
      const response = await axiosWithAuth.put(
        `/scorecard/${scorecardId}`,
        updatedScorecard
      );
      const scorecard: Scorecard = response.data;

      return scorecard;
    } catch (err) {
      throw err;
    }
  },

  deleteScorecardById: async (scorecardId: number) => {
    try {
      await axiosWithAuth
        .delete(`/scorecard/${scorecardId}`)
    } catch (err) {
      throw err;
    }
  },

  deleteSCQuestion: async (scorecardId: number, groupId: number, questionId: number) => {
    await axiosWithAuth
      .delete(
        `scorecard/${scorecardId}/scorecardquestiongroup/${groupId}/scorecardquestion/${questionId}`
      )
  },

  deleteSCQuestionGroup: async (scorecardId: number, groupId: number) => {
    axiosWithAuth
      .delete(`scorecard/${scorecardId}/scorecardquestiongroup/${groupId}`)
  },

  fetchQuestionListBasedOnUserInput: async (userInput: string): Promise<ScorecardQuestion[]> => {
    try {
      const response = await axiosWithAuth.get(`/scorecard/questionlist?userInput=${userInput}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  },
};

export default scorecardService;
