import ContentPlaceholder from "../../components/ContentPlaceholder";
import { RecordingSummary } from "../../types/recording/RecordingSummary";

interface RecordingSummaryTabProps {
  summary?: RecordingSummary;
  fetchLabelKeyTranslation: (key: string, defaultVal: string) => string;
  isAIAnalysed?: boolean;
}

const RecordingSummaryTab: React.FC<RecordingSummaryTabProps> = ({
  summary,
  fetchLabelKeyTranslation,
  isAIAnalysed,
}) => {
  return isAIAnalysed ? (
    <div
      className="scroller"
      style={{
        maxHeight: "40vh",
        overflowY: "auto",
      }}
    >
      {summary && summary.callSummary?.length !== 0 ? (
        <p>{summary?.callSummary}</p>
        ) : (
          <ContentPlaceholder>
          {
            <span>
              <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
              {fetchLabelKeyTranslation(
                "NoSummaryMsg",
                "NO SUMMARY AVAILABLE"
              )}
            </span>
          }
          </ContentPlaceholder>
        )}
      
    </div>
  ) : (
    <p>
      {fetchLabelKeyTranslation(
        "UpdateLicenseSummaryText",
        "Summary not available for this recording"
      )}
    </p>
  );
};

export default RecordingSummaryTab;
