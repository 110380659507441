import { Loader, Skeleton } from "@progress/kendo-react-indicators";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import ContentPlaceholder from "../../components/ContentPlaceholder";
import { RecordingTranscription } from "../../types/recording";
import { SpeakerTranscriptInfo } from "../../types/transcription";
import { CombinedTranscription } from "../../types/transcription/CombinedTranscription";
import { TinyUser } from "../../types/user";
import { formatDateTime, formatSecondsToTime } from "../../utils/dateTimeUtils";
import { processRawTranscript } from "../../utils/transcriptUtils";
import { RecordingTrackSegment } from "../../types/player-track";
import { combineTranscriptSentiment } from "../../utils/transcriptSentimentUtil";

interface ITranscriptionProps {
  rawTranscript?: RecordingTranscription;
  rawTranscriptError?: string;
  sentimentsTrackInsight?: RecordingTrackSegment[];
  playbackProgress: number;
  secondParties?: TinyUser[];
  callDatetime?: string;
  isAIAnalysed?: boolean;
  shared?: boolean;
  fetchLabelKeyTranslation: (key: string, defaultVal: string) => string;
  lockedSegmentCount: number;
}

const Transcription: React.FC<ITranscriptionProps> = ({
  rawTranscript,
  rawTranscriptError,
  sentimentsTrackInsight,
  playbackProgress,
  secondParties,
  callDatetime,
  isAIAnalysed,
  shared,
  fetchLabelKeyTranslation,
  lockedSegmentCount,
}) => {
  const secondPartyContact = secondParties
    ? secondParties[0].phoneNo
      ? secondParties[0].phoneNo
      : secondParties[0].email
    : "";
  const [transcript, setTranscript] = useState<
    CombinedTranscription[] | undefined
  >(undefined);
  const [activeSpeakerEndtime, setActiveSpeakerEndtime] = useState<number>(0);
  const [activeSpeakerId, setActiveSpeakerId] = useState<number>(-1);
  let refs = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // If the currentIndex is valid and we have a ref for it
    if (activeSpeakerId > 0 && refs.current[activeSpeakerId]) {
      // Scroll the current item into view
      const itemElement = refs.current[activeSpeakerId].current;
      const containerElement = containerRef.current;

      if (itemElement && containerElement) {
        // Calculate the position of the item within the container
        const itemRect = itemElement.getBoundingClientRect();
        const containerRect = containerElement.getBoundingClientRect();
        // Adjust the scroll position of the container
        containerElement.scrollTop += itemRect.top - containerRect.top;
      }
    }
  }, [activeSpeakerId]);

  useEffect(() => {
    if (rawTranscript) {
      const processedTranscript = processRawTranscript(
        rawTranscript,
        lockedSegmentCount
      );
      const combinedTranscript = combineTranscriptSentiment(
        processedTranscript,
        sentimentsTrackInsight
      );
      setTranscript(combinedTranscript);
      if (combinedTranscript) {
        if (combinedTranscript.length > 0) {
          //setActiveSpeakerEndtime(processedTranscript[0].end);
          refs.current = Array.from(
            { length: combinedTranscript.length },
            (a) => React.createRef()
          );
        }
      }
    }
  }, [rawTranscript]);

  useEffect(() => {
    const activateSpeaker = () => {
      if (transcript && transcript.length > 0) {
        if (activeSpeakerId >= 0) {
          setTranscript((prev) => {
            return prev?.map((s) => {
              s.active = false;
              return s;
            });
          });
        }

        let activeId = 0;
        let end = transcript[activeId].end;
        let start = transcript[activeId].start;
        for (let i = 0; i < transcript.length; i++) {
          if (transcript[i].end > playbackProgress) {
            activeId = transcript[i].id;
            end = transcript[i].end;
            start = transcript[i].start;
            break;
          }
        }

        if (playbackProgress > start && activeSpeakerId !== activeId) {
          setTranscript((prev) => {
            if (prev) {
              prev[activeId].active = true;
            }
            return prev;
          });
          setActiveSpeakerId(activeId);
          setActiveSpeakerEndtime(end);
        }
      }
    };

    if (playbackProgress > activeSpeakerEndtime) {
      if (
        transcript &&
        transcript.length > 0 &&
        activeSpeakerId === transcript[transcript.length - 1].id
      ) {
        setActiveSpeakerEndtime(Number.MAX_SAFE_INTEGER);
      } else {
        activateSpeaker();
      }
    } else if (
      transcript &&
      activeSpeakerId > 0 &&
      !transcript[activeSpeakerId].active
    ) {
      setTranscript((prev) => {
        if (prev) {
          prev[activeSpeakerId].active = true;
        }
        return prev;
      });
    } else if (
      transcript &&
      activeSpeakerId > 0 &&
      playbackProgress < transcript[activeSpeakerId].start
    ) {
      activateSpeaker();
    }
  }, [playbackProgress, transcript, activeSpeakerId, activeSpeakerEndtime]);

  return isAIAnalysed ? (
    <div className="row">
      <div className="col-md-12">
        <div className="call-transcript">
          <div className="d-flex "></div>
          <div className="text-center p-t-10">
            <div className="row user-view">
              <div className="col-md-12">
                <div
                  className="transcript scroller"
                  style={{
                    maxHeight: "40vh",
                    overflowY: "auto",
                  }}
                  ref={containerRef}
                >
                  <div className="rec-comments p-r-20">
                    {!rawTranscript && !rawTranscriptError && (
                      <ContentPlaceholder>
                        <Loader type={"infinite-spinner"} />
                      </ContentPlaceholder>
                    )}
                    {rawTranscriptError ? (
                      <ContentPlaceholder>
                        {
                          <span>
                            <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                            {fetchLabelKeyTranslation(
                              "TranscriptError",
                              "There was an error in displaying the transcription"
                            )}
                          </span>
                        }
                      </ContentPlaceholder>
                    ) : (
                      <>
                        {!transcript || transcript.length === 0 ? (
                          <ContentPlaceholder>
                            {
                              <span>
                                <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                                {fetchLabelKeyTranslation(
                                  "NoTranscriptMssg",
                                  "NO TRANSCRIPT AVAILABLE"
                                )}
                              </span>
                            }
                          </ContentPlaceholder>
                        ) : (
                          <>
                            {transcript &&
                              transcript.map((t) => {
                                return (
                                  <div
                                    key={t.id}
                                    ref={refs && refs.current[t.id]}
                                    className={`spkTrk spk1 ${t.active && "active"
                                    }`}
                                  >
                                    <div
                                      className={t.sentimentCss}
                                      style={{
                                        minWidth: "4px",
                                        marginRight: "10px",
                                      }}
                                    ></div>
                                    <div className="spkTrk-h text-black-12">
                                      {`${fetchLabelKeyTranslation(
                                        "TextParticipants",
                                        "Participant"
                                      )
                                        } ${t.speaker + 1}`}
                                    </div>
                                    <div className="spkTrk-txt">
                                      <div className="trans-list p-t-3">
                                        <div className="trans-group d-flex">
                                          <div className="spkTrk-time p-r-20 text-black-8">
                                            {formatSecondsToTime(t.start)}
                                          </div>
                                          <div className="spkTrk-para m-b-15">
                                            {t.text}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <p>
      {fetchLabelKeyTranslation(
        "UpdateLicenseText",
        "Transcription not available for this recording"
      )}
    </p>
  );
};

export default Transcription;
