import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import {
  Button,
  Chip,
  ChipList,
  ChipListDataChangeEvent,
  ChipProps,
  ChipRemoveEvent,
  DropDownButton,
  DropDownButtonItemClickEvent,
} from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import LoadingOverlay from "../../components/LoadingOverlay";
import useAuth from "../../hooks/useAuth";
import useLocale from "../../hooks/useLocale";
import insightsService from "../../services/insights.service";
import { Dictionary } from "../../types/Dictionary";
import {
  Insight,
  InsightMetricKeyLabel,
  InsightViewByTblData,
} from "../../types/insight";
import InsightCallsGrid from "./InsightCallsGrid";
import { InsightFilterMetric } from "./Insights";
import InsightViewByGrid from "./insight-view-by-grid/InsightViewByGrid";
import InsightViewChart from "./InsightViewChart";
import useSwal from "../../hooks/useSwal";
import { InsightFilterDates } from "../../types/insight/InsightFilterDates";
import { InsightKeysData, insightKeys } from "./InsightTranslationKeys";
import useTranslation from "../../hooks/useTranslation";

interface InsightDataAggregateCardProps {
  docked?: boolean;
  insight?: Insight;
  viewByMetric: InsightMetricKeyLabel;
  filterMetric?: InsightFilterMetric;
  filterLoading: boolean;
  filterDates: InsightFilterDates | undefined;
  onViewByFilterClick: (viewByFilter: InsightMetricKeyLabel) => boolean;
  onUserFilterRemove: (name: string, isViewBy: boolean) => void;
}

interface ViewByMetricLink extends InsightMetricKeyLabel {
  displayNumber: number;
  isPeriodFilter: boolean;
  active: boolean;
  disabled: boolean;
  iconCls?: string;
}

const InsightDataAggregateCard: React.FC<InsightDataAggregateCardProps> = ({
  docked,
  insight,
  viewByMetric,
  filterMetric,
  filterLoading,
  filterDates,
  onViewByFilterClick,
  onUserFilterRemove,
}) => {
  const trans=useTranslation("InsightDataAggregateCard");
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal();
  const [viewByFilterShow, setViewByFilterShow] = useState<boolean>(false);
  const [viewByMetricLabels, setViewByMetricLabels] = useState<
    ViewByMetricLink[]
  >([]);
  const [filters, setFilters] = useState<
    { id: number; text: string; value: string; isViewBy: boolean }[]
  >([]);
  const [viewByDataLoading, setViewByDataLoading] = useState<boolean>(false);
  const [viewByTblData, setViewByTblData] = useState<InsightViewByTblData>();
  const [error, setError] = useState<string>();
  const [viewByMetricFilterValue, setViewByMetricFilterValue] = useState<{
    metricKeyLabel: string;
    metricKey: string;
    metricSubKeyLabel?: string;
    displayName?: string;
  }>();

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "InsightDataAggregateCard"
      ]
    ) {
      trans.fetchTranslations("InsightDataAggregateCard");
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    const filters: {
      id: number;
      text: string;
      value: string;
      isViewBy: boolean;
    }[] = [];
    let cnt = 0;
    if (filterMetric && filterMetric?.metricKeyLabel !== "TotalCalls") {
      filters.push({
        id: cnt + 1,
        text: `${
          trans.fetchLabelKeyTranslation(
                insightKeys[
                  filterMetric.metricKeyLabel as keyof InsightKeysData
                ],
                filterMetric.metricKeyLabel
              )
        }=${
          filterMetric.metricKeyLabel === "Topics"
            ? `${filterMetric.metricKey.substring(4)} ${
                filterMetric.metricKeySubLabel !== "ExactMatchTopic"
                  ? modifyExtendedSearchTopicName(filterMetric)
                  : ""
              }`
            : trans.fetchLabelKeyTranslation(
                insightKeys[filterMetric.metricKey as keyof InsightKeysData],
                filterMetric.metricKey
              )
        }`,
        value: filterMetric.metricKey,
        isViewBy: false,
      });
    }
    if (viewByMetric && viewByMetric.id > 0) {
      filters.push({
        id: cnt + 1,
        text: `${
          trans.fetchLabelKeyTranslation("KeyViewBy", "View by")
        } = ${
          trans.fetchLabelKeyTranslation(
                insightKeys[viewByMetric.displayName as keyof InsightKeysData],
                viewByMetric.displayName
              )
        }`,
        value: viewByMetric.name,
        isViewBy: true,
      });
    }
    setFilters(filters);
  }, [filterMetric, viewByMetric, trans.translationsLoading]);

  useEffect(() => {
    if (viewByMetric.id > 0) {
      fetchInsightViewByFilteredData();
    }
  }, [insight, filterMetric, viewByMetric]);

  useEffect(() => {
    if (insight) {
      fetchViewByMetricLabels();
    }
  }, [insight, viewByMetric]);

  const modifyExtendedSearchTopicName = (metric: InsightFilterMetric) => {
    if (metric.metricKeySubLabel === "LowRelevanceTopic")
      return `(${
    trans.fetchLabelKeyTranslation("LowRelevanceText", "Low Relevance")
      })`;
    else if (metric.metricKeySubLabel === "MediumRelevanceTopic")
      return `(${
    trans.fetchLabelKeyTranslation("MediumRelevanceText", "Medium Relevance")
      })`;
    else if (metric.metricKeySubLabel === "HighRelevanceTopic")
      return `(${
    trans.fetchLabelKeyTranslation("HighRelevanceText", "High Relevance")
      })`;
    else return "";
  };

  const defaultViewByMetric: InsightMetricKeyLabel = {
    id: 0,
    name: "default",
    displayName: "List",
    description: `${
      trans.fetchLabelKeyTranslation(
          "DefaultViewByMetricText",
          "Default ViewBy Metric for showing call list"
        )
    }`,
  };

  const fetchViewByMetricLabels = async () => {
    try {
      const metricLabels = await insightsService.getInsightMetricLabels(true);
      const updatedMetricLabels: ViewByMetricLink[] = [];
      metricLabels.forEach((m) => {
        switch (m.name) {
          case "Hour": {
            updatedMetricLabels.push({
              ...m,
              displayNumber: 1,
              active: false,
              isPeriodFilter: true,
              disabled: false,
            });
            break;
          }
          case "Day": {
            updatedMetricLabels.push({
              ...m,
              displayNumber: 2,
              active: false,
              isPeriodFilter: true,
              disabled: false,
            });
            break;
          }
          case "Week": {
            updatedMetricLabels.push({
              ...m,
              displayNumber: 3,
              active: false,
              isPeriodFilter: true,
              disabled: false,
            });
            break;
          }
          case "Month": {
            updatedMetricLabels.push({
              ...m,
              displayNumber: 4,
              active: false,
              isPeriodFilter: true,
              disabled: false,
            });
            break;
          }
          case "Sentiments": {
            updatedMetricLabels.push({
              ...m,
              displayNumber: 6,
              active: false,
              isPeriodFilter: false,
              disabled: false,
              iconCls: "bi bi-emoji-smile fs-17",
            });
            break;
          }
          case "Flags": {
            updatedMetricLabels.push({
              ...m,
              displayNumber: 7,
              active: false,
              isPeriodFilter: false,
              disabled:
                insight && insight.callFlagsInsight.flaggedCalls.length > 0
                  ? false
                  : true,
              iconCls: "bi bi-flag",
            });
            break;
          }
          case "Users": {
            updatedMetricLabels.push({
              ...m,
              displayNumber: 8,
              active: false,
              isPeriodFilter: false,
              disabled: false,
              iconCls: "bi bi-person fs-17",
            });
            break;
          }
          case "Evaluators": {
            updatedMetricLabels.push({
              ...m,
              displayNumber: 9,
              active: false,
              isPeriodFilter: false,
              disabled:
                insight && insight.qualityAssuranceInsight.total == 0
                  ? true
                  : false,
              iconCls: "bi bi-person-check fs-17",
            });
            break;
          }
          case "Topics": {
            updatedMetricLabels.push({
              ...m,
              displayNumber: 10,
              active: false,
              isPeriodFilter: false,
              disabled:
                insight && insight.topicsInsight.topics.length > 0
                  ? false
                  : true,
            });
            break;
          }
          //case "Moments": {
          //  updatedMetricLabels.push({
          //    ...m,
          //    displayNumber: 10,
          //    active: false,
          //    isPeriodFilter: false,
          //    disabled:
          //      insight && insight.exactMatchTopicsInsight.moments.length > 0
          //        ? false
          //        : true,
          //  });
          //  break;
          //}
          case "CallResults": {
            updatedMetricLabels.push({
              ...m,
              displayNumber: 11,
              active: false,
              isPeriodFilter: false,
              disabled:
                insight && insight.callResultsInsight.callResults.length > 0
                  ? false
                  : true,
            });
            break;
          }
          // case "CustomerExperiences": {
          //   updatedMetricLabels.push({
          //     ...m,
          //     displayNumber: 12,
          //     active: false,
          //     isPeriodFilter: false,
          //     disabled:
          //       insight &&
          //       insight.customerExperiencesInsight.customerExperiences
          //         .length > 0
          //         ? false
          //         : true,
          //   });
          //   break;
          // }
        }
      });
      if (insight?.totalRecordingsInsight.totalCalls === 0) {
        updatedMetricLabels.forEach((u) => {
          u.disabled = true;
        });
      }
      updatedMetricLabels.push({
        ...defaultViewByMetric,
        displayNumber: 5,
        active: false,
        isPeriodFilter: false,
        disabled: false,
        iconCls: "bi bi-list",
      });
      updatedMetricLabels.sort((x, y) => x.displayNumber - y.displayNumber);
      setViewByMetricLabels(
        updatedMetricLabels.map((m) => {
          if (viewByMetric.id === m.id) {
            m.active = true;
          } else {
            m.active = false;
          }
          return m;
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const fetchInsightViewByFilteredData = async () => {
    try {
      if (insight && insight.id) {
        let resp;
        setViewByDataLoading(true);
        setError(undefined);
        resp = await insightsService.getInsightViewByFilteredData(
          insight?.id,
          viewByMetric.name,
          filterMetric?.metricKeyLabel,
          filterMetric?.metricKeySubLabel,
          filterMetric?.metricKey,
          filterDates
        );

        setViewByTblData(resp);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        setError(err.message);
      }
    } finally {
      setViewByDataLoading(false);
    }
  };

  const onFiltersDataChange = (event: ChipListDataChangeEvent) => {
    setFilters(event.value);
  };

  const onFilterRemove = (e: ChipRemoveEvent) => {
    onUserFilterRemove(
      e.target.props.dataItem.name,
      e.target.props.dataItem.isViewBy
    );
    setViewByMetricFilterValue(undefined);
  };

  const onViewByFilterClickHandler = (id: number) => {
    setViewByDataLoading(true);
    setViewByTblData(undefined);
    const success = onViewByFilterClick(
      viewByMetricLabels.find((vm) => vm.id === id) ?? viewByMetricLabels[0]
    );
    setViewByFilterShow(false);
    setViewByDataLoading(false);
    setViewByMetricFilterValue(undefined);
  };

  const onViewByCallCountClickHandler = (
    metricKeyLabel: string,
    metricKey: string,
    metricSubKeyLabel?: string,
    displayName?: string
  ) => {
    setViewByMetricFilterValue({
      metricKeyLabel,
      metricKey,
      metricSubKeyLabel,
      displayName,
    });
  };

  const onUserBackClickOnViewByCallsGrid = () => {
    setViewByMetricFilterValue(undefined);
  };

  const dropdownItemRender = (props: { item: any; itemIndex: number }) => {
    return (
      <div>
        {props.item.disabled ? (
          <span style={{pointerEvents: "none"}} onClick={(event) =>event.preventDefault()} className="text-black-8 fs-14 cursor-default">
            {trans.fetchLabelKeyTranslation(
                  insightKeys[
                    props.item.displayName as keyof InsightKeysData
                  ],
                  props.item.displayName
                )}
          </span>
        ) : (
          <span
            className={`droplist-a fs-14 ${
              props.item.id === viewByMetric.id
                ? "activeFilter"
                : ""
            }`}
            onClick={(e) =>
              onViewByFilterClickHandler(props.item.id)
            }
            title={`${
              trans.fetchLabelKeyTranslation(
                    "KeyViewBy",
                    "View by"
                  )
            } ${trans.fetchLabelKeyTranslation(
                    insightKeys[
                      props.item.displayName as keyof InsightKeysData
                    ],
                    props.item.displayName
                  )
            }`}
          >
            {trans.fetchLabelKeyTranslation(
                  insightKeys[
                    props.item.displayName as keyof InsightKeysData
                  ],
                  props.item.displayName
                )}
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      <Tooltip
        anchorElement={"target"}
        position={"bottom"}
        parentTitle={true}
      >
        <div className="detailBox p-l-15 p-b-30">
          <div className="row p-b-20">
            <div className="col-md-12">
              <div className="card cardEffect">
                {filterLoading && (
                  <LoadingOverlay
                    customStyle={{
                      marginTop: "53px",
                      height: "90%",
                    }}
                    themeColor={"light"}
                    loadingText={trans.fetchLabelKeyTranslation(
                            "FilterProcessTExt",
                            "Applying filters..."
                    )}
                  />
                )}
                <div className="card-header bg-white d-flex justify-content-between">
                <div className="mobile-heading-view-by">
                  {trans.fetchLabelKeyTranslation("ViewByText","View By")}
                </div>
                  <div className="filterChipList">
                    {filters.length > 0 && (
                      <ChipList
                        data={filters}
                        selection="single"
                        onDataChange={onFiltersDataChange}
                        chip={(props: ChipProps) => (
                          <Chip
                            {...props}
                            size={"large"}
                            themeColor={`${
                              props.dataItem.isViewBy ? "success" : "info"
                            }`}
                            fillMode={"solid"}
                            rounded={"full"}
                            removable={true}
                            onRemove={onFilterRemove}
                          />
                        )}
                      />
                    )}
                  </div>
                  <div className="buttons-container hov-transparent d-flex align-itmes-center">
                    <div className="btnGroupData float-right">
                      <div className="d-flex btnGroupCol float-left">
                        <div className="d-flex periodFilters">
                          {viewByMetricLabels
                            .filter((m) => m.isPeriodFilter === true)
                            .map((vm) => {
                              return (
                                <Tooltip
                                  anchorElement={"target"}
                                  position={"bottom"}
                                  parentTitle={true}
                                  key={vm.id}
                                >
                                  <Button
                                    togglable={true}
                                    className={`bg-black-2 ${
                                      vm.disabled && "text-black-5"
                                    }`}
                                    onClick={(e) =>
                                      onViewByFilterClickHandler(vm.id)
                                    }
                                    selected={vm.active}
                                    disabled={vm.disabled}
                                    title={`${
                                      trans.fetchLabelKeyTranslation(
                                            "KeyViewBy",
                                            "View by"
                                          )
                                    } ${trans.fetchLabelKeyTranslation(
                                            insightKeys[
                                              vm.displayName as keyof InsightKeysData
                                            ],
                                            vm.displayName
                                          )
                                    }`}
                                  >
                                    {trans.fetchLabelKeyTranslation(
                                          insightKeys[
                                            vm.displayName as keyof InsightKeysData
                                          ],
                                          vm.displayName
                                        )}
                                  </Button>
                                </Tooltip>
                              );
                            })}
                        </div>
                        <div className="d-flex iconFilters">
                          {viewByMetricLabels
                            .filter((m) => m.isPeriodFilter === false && m.iconCls)
                            .map((m) => {
                              return (
                                <Tooltip
                                  anchorElement={"target"}
                                  position={"bottom"}
                                  parentTitle={true}
                                  key={m.id}
                                >
                                  <Button
                                    key={m.id}
                                    togglable={true}
                                    onClick={(e) =>
                                      onViewByFilterClickHandler(m.id)
                                    }
                                    selected={m.active}
                                    disabled={m.disabled}
                                    className={`bg-black-2 m-l-5 radius-50 fs-16 ${
                                      m.disabled && "text-black-5"
                                    }`}
                                    style={{ height: "31px", width: "31px" }}
                                    title={`${
                                      trans.fetchLabelKeyTranslation(
                                            "KeyViewBy",
                                            "View by"
                                          )
                                    } ${trans.fetchLabelKeyTranslation(
                                            insightKeys[
                                              m.displayName as keyof InsightKeysData
                                            ],
                                            m.displayName
                                          )
                                    }`}
                                  >
                                    {m.iconCls && <i className={m.iconCls}></i>}
                                  </Button>
                                </Tooltip>
                              );
                            })}
                          {viewByMetricLabels.filter(
                            (m) => m.isPeriodFilter === false && !m.iconCls
                          ).length > 0 && (
                            <DropDownButton
                              title={trans.fetchLabelKeyTranslation(
                                "TextMoreoptions",
                                "More options"
                              )}
                              itemRender={dropdownItemRender}
                              onItemClick={(event:DropDownButtonItemClickEvent)=>onViewByFilterClickHandler(event.item.id)}
                              items={viewByMetricLabels
                                .filter((m) => m.isPeriodFilter === false && !m.iconCls)
                                .filter((vm) => {
                                  if (
                                    vm.name === "Topics" &&
                                    !auth?.checkTopicAvailibility()
                                  ) {
                                    return;
                                  } else {
                                    return vm;
                                  }
                                })}
                                popupSettings={{
                                  popupClass: "DropDownButton-popup",
                                }}
                              iconClass="bi bi-three-dots-vertical fs-16 bg-black-2 radius-50 p-1 display-block"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  {insight && viewByMetric.id > 0 && (
                    <div className="row p-15 p-t-10 insightViewChart">
                      <div className="col-md-12">
                        <InsightViewChart
                          viewFilterTblData={viewByTblData}
                          viewByDataLoading={viewByDataLoading}
                          viewByMetric={viewByMetric}
                        />
                      </div>
                    </div>
                  )}
                  {!viewByMetricFilterValue && insight && viewByMetric.id > 0 && (
                    <InsightViewByGrid
                      viewBy={viewByMetric}
                      viewByFilterTblData={viewByTblData}
                      error={error}
                      onViewByCallCountClickHandler={onViewByCallCountClickHandler}
                    />
                  )}
                  {(viewByMetricFilterValue || viewByMetric.id === 0) && (
                    <InsightCallsGrid
                      docked={docked}
                      insight={insight}
                      filterMetric={filterMetric}
                      viewByMetric={viewByMetric}
                      viewByMetricFilterValue={viewByMetricFilterValue}
                      onUserBackClick={onUserBackClickOnViewByCallsGrid}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    </>
  );
};

export default InsightDataAggregateCard;
