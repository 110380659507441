import { Link } from "react-router-dom";
import { CallDetail } from "../../types/call";
import { GridCellProps } from "@progress/kendo-react-grid";
import { formatDateTime } from "../../utils/dateTimeUtils";
import { formatSecondsToTime } from "../../utils/dateTimeUtils";
import { useEffect, useState } from "react";
import CustomUserGridInfo from "./grid/CustomUserGridInfo";
import CallDirection from "./CallDirection";
import { IsValidString } from "../../utils/stringUtils";
import CustomSentiment from "./CustomSentiment";
import { RecordingTopic } from "../../types/recording";
import ShareDialog from "../ShareDialog";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import useAuth from "../../hooks/useAuth";
import { AccessPermissionEnum } from "../../enums";
import { Dictionary } from "../../types/Dictionary";
import { SweetAlertOptions } from "sweetalert2";
import useSwal from "../../hooks/useSwal";
import useLocale from "../../hooks/useLocale";
import CustomGridCellForCallTopic from "./grid/CustomGridCellForCallTopic";
import ParticipantPopup from "./ParticipantPopup";
import useTranslation from "../../hooks/useTranslation";

interface ICallListingForMobileProps extends GridCellProps {
  dataItem: CallDetail;
  handlePlayerExpand: (callDetail: CallDetail) => void;
}

const CallListingForMobile = (props: ICallListingForMobileProps) => {
  const trans = useTranslation("calls");
  const auth = useAuth();
  const swal = useSwal();
  const localeCtx = useLocale();
  const scoreColor =
    props.dataItem.recordings[0].recordingScorecardScore !== undefined
      ? props.dataItem.recordings[0].recordingScorecardScore >= 3
        ? "tx-green"
        : props.dataItem.recordings[0].recordingScorecardScore >= 1.5
          ? "tx-amber"
          : "tx-red"
      : "";
  const routeUrl = `/calldetail/${props.dataItem.platformCallDetailId}?recordingId=${props.dataItem.recordings[0].id}`;
  var flagColor = props.dataItem.colorCategory
    ? IsValidString(props.dataItem.colorCategory.hexCode)
      ? props.dataItem.colorCategory.hexCode
      : "#ccc"
    : "#ccc";

  const totalComments = props.dataItem.recordings[0].totalComments ? props.dataItem.recordings[0].totalComments : 0;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [shareDialogVisible, setShareDialogVisible] = useState<boolean>(false);

  const [toggleVisible, setToggleVisible] = useState<boolean>(false);
  const toggleParticipantDialog = () => {
    setToggleVisible(!toggleVisible);
  };

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["calls"]) {
      trans.fetchTranslations("calls");
    }
  }, [localeCtx?.selectedLocale]);

  const toggleShareDialogHandler = () => {
    setShareDialogVisible((prev) => !prev);
  };

  const onCloseShareDialog = () => {
    setShareDialogVisible(false);
  };


  const CustomGCellWithCallParty = (callPartyprops: GridCellProps, field: string) => {
    // In mobile view, we receive the callPartyprops.field as "cellMobile", so we had to send field parameter separately.
    const user = field === "firstParty.email"
      ? callPartyprops.dataItem.firstParty
      : callPartyprops.dataItem.secondParties.find((sp: any) => sp.id === 0) ?? callPartyprops.dataItem.secondParties[0];

      const uniqueParticipants = callPartyprops.dataItem.secondParties.filter((value:any, index:any, self:any) =>
        index == self.findIndex((obj:any) => 
        obj.email === value.email)); 

    return (
      <div className="uCardCol">
        <div className="moreData">
          <div>
            {field === "secondParty.email" ? (
              <>
                <CustomUserGridInfo userInfo={user}
                  label={trans.fetchLabelKeyTranslation("CallsGridRemotePartyColLabel", "Participant")}
                />
              </>
            ) : (
              <CustomUserGridInfo userInfo={user} />
            )}
          </div>
          {(field === "secondParty.email"
            && callPartyprops.dataItem.secondParties.length > 1) && (
              <div className="moreDataInr">
                <Tooltip
                  anchorElement={"target"}
                  position={"top"}
                  parentTitle={true}
                >
                  <div className="moreDataList">
                    <div
                      className="moreDataListBtn"
                      onClick={toggleParticipantDialog}
                    >
                      <i className="bi bi-caret-down-fill text-black-9"></i>
                    </div>
                    {toggleVisible && (
                      <ParticipantPopup
                        toggleDialog={toggleParticipantDialog}
                        participants={uniqueParticipants}
                      ></ParticipantPopup>
                    )}
                  </div>
                </Tooltip>
              </div>
            )}
        </div>
      </div>
    );
  };

  return (
    <td className="listMobile">
      <div className="listRowMobile float-left p-0">
        <div className="float-left w-100">
          <CustomUserGridInfo
            userInfo={props.dataItem.firstParty}
            label={trans.fetchLabelKeyTranslation("CallsGridFirstPartyColLabel", "Recorded User")}
          />

          <div className="miniTextTime float-left w-100 p-t-6 p-l-43">
            <div className="listLabelRow d-flex justify-content-start w-100 float-left line-height-1">
              <div className="listLabelCol">
                <span className="fs-11 font-weight-semi p-r-3 text-black-12">
                  {trans.fetchLabelKeyTranslation("CallsGridDateTimeColLabel", "Date/Time")}:
                </span>
              </div>
              <div className="listLabelCol">
                <span className="fs-11 text-black-10">
                  {formatDateTime(props.dataItem.callTime, "MMM DD, YYYY, HH:mm")}
                </span>
              </div>
            </div>
            <div className="listLabelRow d-flex justify-content-start w-100 float-left line-height-1">
              <div className="listLabelCol">
                <span className="fs-11 font-weight-semi p-r-3 text-black-12">
                  {trans.fetchLabelKeyTranslation("CallsGridDurationColLabel", "Duration")}:
                </span>
              </div>
              <div className="listLabelCol">
                <span className="fs-11 text-default text-black-11">
                  {formatSecondsToTime(props.dataItem.callDuration)}
                </span>
              </div>
            </div>
            <div className="hideShowLabel fs-12 text-primary text-decoration-underline">
              <a
                onClick={() => {
                  setExpanded(!expanded);
                }}
                className="hideShowLink"
              >
                {expanded ? "Less" : "More"} Details
              </a>
            </div>
          </div>
        </div>
        {expanded && (
          <div className="viewMoreData float-left w-100">
            <div className="viewMoreDataInr float-left w-100">
              {CustomGCellWithCallParty(props, "secondParty.email")}
              <div className="listLabel">
                <div className="listLabelRow w-100 float-left border-top-solid border-bottom-solid border-w-1 border-black-1 m-t-10 p-t-7 p-b-7">
                  <span className="spanLabel d-flex align-items-center p-r-15">
                    <span className="fs-13 font-weight-semi p-r-5">
                      {trans.fetchLabelKeyTranslation("CallsGridCallDirectionColLabel", "Call Direction")}:
                    </span>
                    <CallDirection
                      callDirection={props.dataItem.callDirection}
                      callType={props.dataItem.callType}
                      iconSize={13}
                      isMobileView={true}
                    />
                  </span>
                  <span className="spanLabel d-flex align-items-center p-r-15">
                    <span className="fs-13 font-weight-semi p-r-5">
                      {trans.fetchLabelKeyTranslation("CallsGridFlagColLabel", "Flag")}:
                    </span>
                    <span className="fs-13" style={{ color: flagColor }}>
                      <i
                        className={`bi bi-flag${props.dataItem.colorCategory ? "-fill" : ""
                          }`}
                      ></i>
                    </span>
                  </span>
                  <span className="spanLabel d-flex align-items-center p-r-15 p-t-1">
                    <span className="fs-13 font-weight-semi p-r-5">
                      {trans.fetchLabelKeyTranslation("CallsGridEvalColLabel", "Eval")}:
                    </span>
                    {props.dataItem.isEvaluated ? (
                      <Button
                        style={{ background: "transparent", border: "none", padding: "0px" }}
                        className="radius-50 d-flex align-items-center justify-conter m-r-3"
                      >
                        <span
                          className={`fs-17 line-height-1 ${scoreColor}`}
                          title="Evaluated"
                        >
                          <i className="bi bi-check-circle"></i>
                        </span>
                      </Button>
                    ) : (
                      <Button
                        style={{ background: "transparent", border: "none", padding: "0px" }}
                        className="radius-50 d-flex align-items-center justify-conter m-r-3"
                      >
                        <span
                          className="fs-17 line-height-1 text-black-6"
                          title="Not Evaluated"
                        >
                          <i className="bi bi-check-circle"></i>
                        </span>
                      </Button>
                    )}
                  </span>
                  <span className="spanLabel d-flex align-items-center">
                    <span className="fs-13 font-weight-semi p-r-5">
                      {trans.fetchLabelKeyTranslation("CallsGridSentimentColLabel", "Sentiment")}:
                    </span>
                    <CustomSentiment
                      sentiment={props.dataItem.recordings[0]?.sentiment}
                      iconSize={17}
                      isAIAnalysed={props.dataItem.recordings[0].isAIAnalysed}
                    />
                  </span>
                </div>
              </div>
              <div className="iconlabel float-left w-100 p-b-10 p-t-10">
                <span className="spanLabel d-flex align-items-baseline w-100">
                  <span
                    className="fs-13 font-weight-semi p-r-3"
                    style={{ minWidth: "35px" }}
                  >
                    {trans.fetchLabelKeyTranslation("CallsGridCallOutcomeColLabel", "Call Outcomes")}:
                  </span>
                  <span className="fs-13 text-default line-height-1">
                    <span
                      className="mx-th-tag"
                      style={{ minWidth: "80px", maxWidth: "160px" }}
                    >
                      <div className="mx-td-spn" style={{ maxWidth: "70vw" }}>
                        {props.dataItem.callResult?.name ?? ""}
                      </div>
                    </span>
                  </span>
                </span>
              </div>
              <div className="iconlabel float-left w-100 p-b-10 p-t-10">
                <span className="spanLabel d-flex align-items-baseline w-100">
                  <span
                    className="fs-13 font-weight-semi p-r-3"
                    style={{ minWidth: "35px" }}
                  >
                    {trans.fetchLabelKeyTranslation("CallsGridRCColLabel", "Reason Code")}:
                  </span>
                  <span className="fs-13 text-default line-height-1">
                    <span
                      className="mx-th-tag"
                      style={{ minWidth: "120px", maxWidth: "160px" }}
                    >
                      <div className="mx-td-spn" style={{ maxWidth: "60vw" }}>
                        {props.dataItem?.reasonCode?.name ?? ""}
                      </div>
                    </span>
                  </span>
                </span>
              </div>
              <div className="iconlabel float-left w-100 p-b-10 p-t-10">
                <span className="spanLabel d-flex align-items-baseline w-100">
                  <span
                    className="fs-13 font-weight-semi p-r-3"
                    style={{ minWidth: "35px" }}
                  >
                    {trans.fetchLabelKeyTranslation("CallsGridNotesColLabel", "Notes")}:
                  </span>
                  <span className="fs-13 text-default line-height-1">
                    <span
                      className="mx-th-tag"
                      style={{ minWidth: "80px", maxWidth: "160px" }}
                    >
                      <div className="mx-td-spn" style={{ maxWidth: "70vw" }}>
                        {props.dataItem?.callTag ?? ""}
                      </div>
                    </span>
                  </span>
                </span>
              </div>
              <div className="iconlabel float-left w-100 p-b-10 p-t-10">
                <span className="spanLabel d-flex align-items-baseline w-100">
                  <span
                    className="fs-13 font-weight-semi p-r-3"
                    style={{ minWidth: "35px" }}
                  >
                    {trans.fetchLabelKeyTranslation("CallsGridCommentsColLabel", "Comments")}:
                  </span>
                  <span className="fs-13 text-default line-height-1">
                    <span
                      className="mx-th-tag"
                      style={{ minWidth: "80px", maxWidth: "160px" }}
                    >
                      <div className="mx-td-spn" style={{ maxWidth: "70vw" }}>
                        <span
                          className="line-height-1 text-primary"
                          title={"Comments: " + totalComments}
                        >
                          <i
                            className="bi bi-chat-left fs-13"
                            style={{ padding: "2px 4px 0 0", float: "left" }}
                          ></i>
                          <span className="fs-12 p-l-2">
                            {totalComments}
                          </span>
                        </span>
                      </div>
                    </span>
                  </span>
                </span>
              </div>
              <div className="topics float-left w-100 p-t-5 d-flex">
                <div className="fs-13 font-weight-semi p-r-5" style={{ minWidth: "60px" }}>
                  {trans.fetchLabelKeyTranslation("CallsGridTopicsColLabel", "Topics")}:
                </div>
                {CustomGridCellForCallTopic(props)}
              </div>
            </div>
          </div>
        )}

        <div className="iconMobileDetail">
          {shareDialogVisible && (
            <ShareDialog
              onClose={onCloseShareDialog}
              resourceObjId={props.dataItem.id}
              recordingPath={routeUrl}
            />
          )}
          <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
            <span className="iBtn d-flex justify-content-end">
              <span className="m-r-10" title="View Detail">
                <Link
                  to={routeUrl}
                  state={props.dataItem}
                  className="btn-link text-primary p-0 m-0 fs-20"
                >
                  <i className="bi bi-justify-left"></i>
                </Link>
              </span>
              <Button
                className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 ${auth?.checkUserAccess(AccessPermissionEnum.ShareCalls)
                  ? "iconBtn"
                  : "disabledIconBtn"
                  }
                  `}
                onClick={toggleShareDialogHandler}
                disabled={!auth?.checkUserAccess(AccessPermissionEnum.ShareCalls)}
              >
                <span
                  className="fs-15 line-height-1 text-primary"
                  title="Share"
                >
                  <i className="bi bi-share"></i>
                </span>
              </Button>
              <Button
                className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
                onClick={() => props.handlePlayerExpand(props.dataItem)}
              >
                <span className="fs-15 line-height-1 text-primary" title="Play">
                  <i className="bi bi-play"></i>
                </span>
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    </td>
  );
};

export default CallListingForMobile;
