import { GridCellProps, GridColumnProps } from '@progress/kendo-react-grid';
import { useEffect, useState } from 'react'
import { AggregateData, CustomGridCellProps } from './InsightViewByGrid';
import { InsightMetricKeyLabel, InsightViewByTblData } from '../../../types/insight';
import React from 'react';
import { TinyUser } from '../../../types/user';
import { concatUsername, getRandomProfileColor } from '../../../utils/profileUtils';
import CustomUserGridInfo from '../../../components/custom/grid/CustomUserGridInfo';
import { Dictionary } from '../../../types/Dictionary';
import useLocale from '../../../hooks/useLocale';
import { SweetAlertOptions } from 'sweetalert2';
import useSwal from '../../../hooks/useSwal';
import useTranslation from '../../../hooks/useTranslation';

interface IInsightViewByGridForMobileProps extends GridCellProps {
    dataItem: AggregateData;
    viewBy: InsightMetricKeyLabel;
    columns: GridColumnProps[];
    viewByFilterTblData: InsightViewByTblData | undefined
    onViewByCallCountClickHandler: (metricKeyLabel: string, metricKey: string, metricSubKeyLabel?: string, displayName?: string) => void
}

const InsightViewByGridForMobile = (props: IInsightViewByGridForMobileProps) => {
    const trans = useTranslation("InsightsViewByGrid")
    const localeCtx = useLocale();
    const swal = useSwal();

    useEffect(() => {
        if (!localeCtx?.selectedLocale?.current.componentTranslations["InsightsViewByGrid"]) {
            trans.fetchTranslations("InsightsViewByGrid");
        }
    }, [localeCtx?.selectedLocale]);

    const [expanded, setExpanded] = useState<boolean>(false);

    const columns: GridColumnProps[] = [...props?.columns];
    const dataItem: AggregateData = props?.dataItem;
    const viewBy: InsightMetricKeyLabel = props?.viewBy;
    const viewByFilterTblData: InsightViewByTblData | undefined = props?.viewByFilterTblData;
    const onViewByCallCountClickHandler: (metricKeyLabel: string, metricKey: string, metricSubKeyLabel?: string, displayName?: string) => void = props?.onViewByCallCountClickHandler;

    interface CustomRowCellProps {
        customGridCellProps: CustomGridCellProps;
        column: GridColumnProps
    }

    const CustomRowCell: React.FC<CustomRowCellProps> = ({ customGridCellProps, column }) => {
        if (column?.field && customGridCellProps.dataItem[column.field as keyof AggregateData]) {
            switch (column.field) {
                case "topTopics":
                    return CustomTopTopicsPropCell(customGridCellProps);
                case "topUsers":
                    return CustomTopUsersPropCell(customGridCellProps);
                case "sentiments":
                    return CustomSentimentsCell(customGridCellProps);
                case "evaluation":
                    {
                        switch (column.title) {
                            case "Total Evaluated Calls":
                                return CustomTotalEvaluatedCallsPropCell(customGridCellProps)
                            case "Total Evaluated Duration":
                                return CustomTotalEvaluatedDurationPropCell(customGridCellProps)
                            case "Avg. Score %":
                                return CustomTotalAverageEvaluatedCallsScorePropCell(customGridCellProps)
                            case "Evaluation":
                                return CustomTotalDifferentEvaluatedCallsCell(customGridCellProps)
                        }
                    }
                    return null;
            }
        } else {
        }
        return null;
    }
    const CustomTopTopicsPropCell = (props: CustomGridCellProps) => {
        const topicMetrics = props.dataItem.topTopics;
        return (
            <div className="float-left w-100 p-b-10 p-t-10">
                <div
                    className="fs-13 font-weight-semi p-r-5"
                    style={{ minWidth: "60px" }}
                >
                    {trans.fetchLabelKeyTranslation("TopicsHeading", "Topics")
                    }
                    :
                </div>

                <div className="mx-th-tag">
                    <div className="mx-td-spn">
                        <div className="keywordRow">
                            {topicMetrics &&
                                topicMetrics.metricKeyCounts.map((m, idx) => {
                                    return (
                                        <div
                                            className={`keywordTrk ${m.additionalProperties["IsExactMatch"] === "true"
                                                ? "bg-greenDarkDLL"
                                                : ""
                                                }`}
                                            key={idx}
                                        >
                                            <i
                                                className={`bi ${m.metricKey.substring(0, 3) === "PUB"
                                                    ? "bi-people"
                                                    : "bi-lock-fill"
                                                    } m-r-6`}
                                            ></i>
                                            {m.metricKey.substring(4)}

                                            {m.subKeyCounts.map((c) => {
                                                return (
                                                    <span
                                                        style={{ cursor: "default" }}
                                                        className={`numCount`}
                                                        title={trans.fetchLabelKeyTranslation("TextCalls", "Calls")
                                                        }
                                                    >
                                                        {c.count}
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const CustomTotalEvaluatedCallsPropCell = (props: CustomGridCellProps) => {
        const data = props.dataItem;
        return (
            <div className="float-left w-100 p-b-10 p-t-10 d-flex">
                <div
                    className="fs-13 font-weight-semi p-r-5"
                    style={{ minWidth: "60px" }}

                >
                    <span>{trans.fetchLabelKeyTranslation("TotalEvaluatedCallsHeading", "Total Evaluated Calls")
                    }:</span>
                </div>
                <span style={{ color: "#259fb2" }}>
                    {
                        data.evaluation?.metricKeyCounts.find(
                            (metric) => metric.metricKey === "Total"
                        )?.totalCount
                    }
                </span>
            </div>
        );
    };
    const CustomTotalEvaluatedDurationPropCell = (
        props: CustomGridCellProps
    ) => {
        const data = props.dataItem;
        return (
            <div className="float-left w-100 p-b-10 p-t-10">
                <div
                    className="fs-13 font-weight-semi p-r-5"
                    style={{ minWidth: "60px" }}
                >
                    {trans.fetchLabelKeyTranslation("TotalEvaluatedDurationHeading", "Total Evaluated Duration")
                    }
                    :
                </div>
                <div className="mx-th-tag">
                    <div className="mx-td-spn">
                        <span style={{ color: "#259fb2" }}>
                            {formatTotalEvaluatedDuration(
                                Number(data.evaluation?.metricKeyCounts.find((metric) => metric.metricKey === "Total")?.additionalProperties.EvaluatedDuration)
                            )}
                        </span>
                    </div>
                </div>
            </div>
        );
    };
    const formatTotalEvaluatedDuration = (seconds: number) => {
        if (seconds < 60) {
            return `${seconds} sec`;
        } else if (seconds < 3600) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${minutes} mins ${remainingSeconds} sec`;
        } else {
            const hours = Math.floor(seconds / 3600);
            const remainingMinutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;
            return `${hours} hrs ${remainingMinutes} mins ${remainingSeconds} sec`;
        }
    }
    const CustomTotalAverageEvaluatedCallsScorePropCell = (
        props: CustomGridCellProps
    ) => {
        const data = props.dataItem;
        return (
            <div className="float-left w-100 p-b-10 p-t-10">
                <div
                    className="fs-13 font-weight-semi p-r-5"
                    style={{ minWidth: "60px" }}
                >
                    {trans.fetchLabelKeyTranslation("AverageDuarationHeading", "Average Duaration")
                    }
                    :
                </div>
                <div className="mx-th-tag">
                    <div className="mx-td-spn">
                        <span style={{ color: "#259fb2" }}>
                            {Number(
                                data.evaluation?.metricKeyCounts.find(
                                    (metric) => metric.metricKey === "Total"
                                )?.additionalProperties.AverageScore
                            ).toFixed(2)}
                        </span>
                    </div>
                </div>
            </div>
        );
    };
    const CustomTotalDifferentEvaluatedCallsCell = (props: CustomGridCellProps) => {
        const evaluationMetrics = props.dataItem.evaluation;

        const EvaluationBadge: React.FC<{ evaluation: string; cnt: number }> = ({ evaluation, cnt, }) => {
            const cls =
                evaluation === "Good"
                    ? "success"
                    : evaluation === "Poor"
                        ? "danger"
                        : "warning";
            return (
                <div
                    className="keywordTrk"
                    style={{
                        border: "2px solid rgba(0,0,0,.08)",
                        background: "transparent",
                    }}
                >
                    <div className="tblUsr float-left">
                        <div className="topUsrAreaPic">
                            <div
                                className="topUsrAreaPic-i"
                                style={{ padding: "3px 1px 2px 0" }}
                            >
                                <div
                                    className="contact-list-icon"
                                    title={evaluation}
                                    style={{ height: "22px", width: "22px", border: "none" }}
                                >
                                    <i
                                        className={`bi bi-hand-thumbs-up-fill fs-20 text-${cls}`}
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span
                        className="numCount"
                        title={trans.fetchLabelKeyTranslation("TextCalls", "Calls")
                        }
                        style={{ color: "rgba(0,0,0,.4)", padding: "0", cursor: "default" }}
                    >
                        {cnt}
                    </span>
                </div>
            );
        };

        return (
            <div className="float-left w-100 p-b-10 p-t-10">
                <div
                    className="fs-13 font-weight-semi p-r-5"
                    style={{ minWidth: "60px" }}
                >
                    {trans.fetchLabelKeyTranslation("EvaluationHeading", "Evaluation")
                    }
                    :
                </div>
                <div className="mx-th-tag">
                    <div className="mx-td-spn">
                        <div className="keywordRow">
                            {evaluationMetrics &&
                                evaluationMetrics.metricKeyCounts.map((m, idx) => {
                                    if (
                                        m.metricKey === "Good" ||
                                        m.metricKey === "Average" ||
                                        m.metricKey === "Poor"
                                    ) {
                                        return (
                                            <EvaluationBadge
                                                key={idx}
                                                evaluation={m.metricKey}
                                                cnt={m.totalCount}
                                            />
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                        </div>
                    </div>
                </div>
            </div>

        );
    };
    const CustomTopUsersPropCell = (props: CustomGridCellProps) => {
        const userMetrics = props.dataItem.topUsers;

        const UserBadge: React.FC<{ userInfo: TinyUser; cnt: number }> = ({
            userInfo,
            cnt,
        }) => {
            const name = concatUsername(
                userInfo?.firstName ? userInfo.firstName : "",
                userInfo?.lastName ? userInfo?.lastName : ""
            ).trim();
            const color = getRandomProfileColor(userInfo?.id ?? 0);
            const userIcon = userInfo?.image ? (
                <div className="contact-list-icon-img">
                    <img src={require("../../../assets/images/user-img-2.jpg")} />
                </div>
            ) : name !== "" ? (
                <span>
                    {name
                        .split(" ")
                        .map((n: string) => n[0])
                        .join("")}
                </span>
            ) : (
                <span className="fs-10">UU</span>
            );

            return (
                <>
                    {!userInfo ? (
                        <span></span>
                    ) : (
                        <div
                            className="keywordTrk"
                            style={{
                                border: "2px solid rgba(0,0,0,.08)",
                                background: "transparent",
                            }}
                        >
                            <div className="tblUsr float-left">
                                <div className="topUsrAreaPic">
                                    <div
                                        className="topUsrAreaPic-i"
                                        style={{ padding: "2px 1px 2px 0" }}
                                    >
                                        <div
                                            className={`contact-list-icon ${color}`}
                                            title={`${name}`}
                                            style={{ height: "22px", width: "22px", border: "none" }}
                                        >
                                            <div className="contact-list-icon-txt fs-10">
                                                {userIcon}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span
                                className="numCount"
                                title={trans.fetchLabelKeyTranslation("TextCalls", "Calls")
                                }
                                style={{
                                    color: "rgba(0,0,0,.4)",
                                    padding: "0",
                                    cursor: "default",
                                }}
                            >
                                {cnt}
                            </span>
                        </div>
                    )}
                </>
            );
        };

        return (
            <div className="float-left w-100 p-b-10 p-t-10">
                <div
                    className="fs-13 font-weight-semi p-r-5"
                    style={{ minWidth: "60px" }}
                >
                    {trans.fetchLabelKeyTranslation("TopUsersHeading", "Top Users")
                    }
                    :
                </div>
                <div className="mx-th-tag">
                    <div className="mx-td-spn">
                        <div className="keywordRow">
                            {userMetrics &&
                                userMetrics.metricKeyCounts.map((m, idx) => {
                                    return (
                                        <UserBadge key={idx} userInfo={m.metricKey} cnt={m.count} />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const CustomTotalCallsPropCell = (props: CustomGridCellProps) => {
        const data = props.dataItem;

        const onCallsClick = (subKeyLabel?: string) => {
            let tinyUser: TinyUser | undefined = undefined;
            if (viewBy.name === "Users" || viewBy.name == "Evaluators") {
                try {
                    tinyUser = JSON.parse(data.displayName);
                } catch (err) { }
            }
            const name = concatUsername(
                tinyUser?.firstName ? tinyUser.firstName : "",
                tinyUser?.lastName ? tinyUser?.lastName : ""
            ).trim();

            onViewByCallCountClickHandler(
                viewBy.name,
                data.name,
                subKeyLabel,
                tinyUser
                    ? name.length > 0
                        ? name
                        : tinyUser.email
                    : viewBy.name === "Topics"
                        ? data.displayName.substring(4)
                        : data.displayName
            );
        };

        return (
            <div className="mx-th-tag">
                <div className="mx-td-spn">
                    {data.subKeyCallsCnt.length > 0 ? (
                        data.subKeyCallsCnt.map((c) => {
                            return (
                                <div
                                    className={`keywordTrk m-b-1 fs-15 cursor-pointer w-auto ${c.key === "HighRelevanceTopic"
                                        ? "bg-greenDLL"
                                        : c.key === "LowRelevanceTopic"
                                            ? "bg-yellowDLL"
                                            : c.key === "ExactMatchTopic"
                                                ? "bg-greenDarkDLL"
                                                : ""
                                        }`}
                                    title={c.key}
                                    style={{ color: "#1E6570" }}
                                    onClick={(e) => onCallsClick(c.key)}
                                >
                                    {c.count}
                                </div>
                            );
                        })
                    ) : (
                        <span
                            className="cursor-pointer"
                            style={{ color: "#259fb2" }}
                            onClick={(e) => onCallsClick()}
                        >
                            {data.totalCallsCnt}
                        </span>
                    )}
                </div>
            </div>
        );
    };
    const CustomSentimentsCell = (props: CustomGridCellProps) => {
        const sentimentMetrics = props.dataItem.sentiments;

        const SentimentBadge: React.FC<{ sentiment: string; cnt: number }> = ({
            sentiment,
            cnt,
        }) => {
            const cls =
                sentiment === "Positive"
                    ? "success"
                    : sentiment === "Negative"
                        ? "danger"
                        : "warning";
            const emoji =
                sentiment === "Positive"
                    ? "smile"
                    : sentiment === "Negative"
                        ? "frown"
                        : "neutral";
            return (
                <div
                    className="keywordTrk"
                    style={{
                        border: "2px solid rgba(0,0,0,.08)",
                        background: "transparent",
                    }}
                >
                    <div className="tblUsr float-left">
                        <div className="topUsrAreaPic">
                            <div
                                className="topUsrAreaPic-i"
                                style={{ padding: "3px 1px 2px 0" }}
                            >
                                <div
                                    className="contact-list-icon"
                                    title={sentiment}
                                    style={{ height: "22px", width: "22px", border: "none" }}
                                >
                                    <i
                                        className={`bi bi-emoji-${emoji}-fill fs-20 text-${cls}`}
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span
                        className="numCount"
                        title={trans.fetchLabelKeyTranslation("TextCalls", "Calls")
                        }
                        style={{ color: "rgba(0,0,0,.4)", padding: "0", cursor: "default" }}
                    >
                        {cnt}
                    </span>
                </div>
            );
        };

        return (
            <div className="float-left w-100 p-b-10 p-t-10">
                <div
                    className="fs-13 font-weight-semi p-r-5"
                    style={{ minWidth: "60px" }}
                >
                    {trans.fetchLabelKeyTranslation("SentimentsHeading", "Sentiments")
                    }
                    :
                </div>
                <div className="mx-th-tag">
                    <div className="mx-td-spn">
                        <div className="keywordRow">
                            {sentimentMetrics &&
                                sentimentMetrics.metricKeyCounts.map((m, idx) => {
                                    return (
                                        <SentimentBadge
                                            key={idx}
                                            sentiment={m.metricKey}
                                            cnt={m.totalCount}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const CustomViewByHeaderCell = (props: CustomGridCellProps) => {
        const data = props.dataItem;
        let tinyUser: TinyUser | undefined = undefined;
        if (
            viewBy.name === "Users" || viewBy.name === "Evaluators"
        ) {
            try {
                tinyUser = JSON.parse(data.displayName);
            } catch (err) { }
        }

        const label = tinyUser ? (
            <CustomUserGridInfo userInfo={tinyUser} />
        ) : viewBy.name === "Moments" || viewBy.name === "Topics" ? (
            <React.Fragment>
                {data.displayName.substring(0, 3) === "Pri" ? (
                    <i className="bi-lock-fill m-r-6" />
                ) : (
                    <i className="bi-people m-r-6" />
                )}
                <span>{`${data.displayName.substring(4)}`}</span>
                <span className="boxLabelAI m-l-4 bg-primary tx-white">
                    {data.additionalProperties["IsExactMatch"] === "true"
                        ? "EXACT"
                        : "EXTENDED"}
                </span>
            </React.Fragment>
        ) : (
            <span>{`${data.displayName}`}</span>
        );

        return (
            <div className="mx-th-tag">
                <div>{label}</div>
            </div>
        );
    };

    return (
        <td className="listMobile">
            <div className="listRowMobile float-left p-l-15">
                <div className="float-left w-100">
                    <div className="float-left text-primary font-weight-semi line-height-2 w-100 p-b-10">
                        {CustomViewByHeaderCell(props)}
                    </div>

                    <div className="line-height-2 mx-td-spn cursor-default">
                        <div className="d-flex">
                            <span className="fs-13 p-r-5">
                                {columns[2].title}:
                            </span>
                            <span className="fs-13">
                                {CustomTotalCallsPropCell(props)}
                            </span>
                        </div>
                    </div>

                    <div className="miniTextTime float-left w-100 p-t-6 "> {/*p-l-43*/}
                        <div className="hideShowLabel fs-12 text-primary text-decoration-underline">
                            <a
                                onClick={() => {
                                    setExpanded(!expanded);
                                }}
                                className="hideShowLink"
                            >
                                {expanded ? "Less" : "More"} Details
                            </a>
                        </div>
                    </div>
                </div>

                {expanded && (
                    <div className="viewMoreMobileData float-left w-100">
                        <div className="viewMoreDataInr float-left w-100">
                            {
                                columns.length > 0 &&
                                columns.map((columnsVal, columnsKey) => {
                                    if (columnsKey >= 3) {
                                        const key = columnsVal && columnsVal.title ? columnsVal?.title?.toString() + viewByFilterTblData?.id.toString() : viewByFilterTblData?.id.toString();
                                        return (
                                            <CustomRowCell
                                                customGridCellProps={props}
                                                column={columnsVal}
                                            />
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                )}

            </div>
        </td >
    );
}

export default InsightViewByGridForMobile;