import { BatchJob } from "../types/batchjob/BatchJob";
import { axiosWithAuth } from "../utils/customAxios";

const batchjobServices = {
  getBatchJobsStatus: async (batchJobType: string, customerId?:number, serviceProviderId?:number, batchJobCount:number = 3, batchJobIdentifier?:string): Promise<BatchJob[]> => {
    var requestQuery = "";
    requestQuery += `?batchJobType=${batchJobType}`;
    if(customerId && customerId > 0){
        requestQuery += `&customerId=${customerId}`;
    }
    if(serviceProviderId && serviceProviderId > 0){
        requestQuery += `&serviceProviderId=${serviceProviderId}`;
    }
    requestQuery += `&batchJobCount=${batchJobCount}`;
    if(batchJobIdentifier && batchJobIdentifier!==""){
        requestQuery += `&batchJobIdentifier=${batchJobIdentifier}`;
    }
    const response = await axiosWithAuth.get(`/batchjob/batchjobstatus${requestQuery}`);
    const batchjobs: BatchJob[] = response.data;
    return batchjobs;
  },
};

export default batchjobServices;