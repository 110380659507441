import { Button } from "@progress/kendo-react-buttons";
import { Form } from "@progress/kendo-react-form";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridNoRecords,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Rating, RatingChangeEvent } from "@progress/kendo-react-inputs";
import { useContext, useEffect, useState } from "react";
import { AccessPermissionEnum, EntityLocalizationGroupEnum } from "../../enums/accessPermissionEnum";
import useAuth from "../../hooks/useAuth";
import {
  ScorecardEvaluationQuestion,
  ShortScorcardType,
} from "../../pages/call-details/RecordingScorecardTab";
import recordingsService from "../../services/recordings.service";
import { RecordingScorecard } from "../../types/recording/RecordingScorecard";
import useSwal from "../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import LoadingOverlay from "../LoadingOverlay";
import useMasterData from "../../hooks/useMasterData";

interface CustomGridCellProps extends GridCellProps {
  dataItem: ScorecardEvaluationQuestion;
}

interface RecordingScorecardGridProps {
  recordingId: number;
  scorecard: ShortScorcardType;
  questions?: ScorecardEvaluationQuestion[];
  onEvaluationSave: (e: RecordingScorecard) => void;
  error?: string;
  shared?: boolean;
  fetchKeyTranslation: (key: string, defaultVal: string) => string;
  edited: boolean;
  setEdited: (e: boolean) => void;
  sendFetchRecordingScorecardRequest: () => void;
  isEvaluated: boolean;
  setEvaluating: (e: boolean) => void;
}

const RecordingScorecardGrid: React.FC<RecordingScorecardGridProps> = ({
  recordingId,
  scorecard,
  questions,
  onEvaluationSave,
  error,
  shared,
  fetchKeyTranslation,
  edited,
  setEdited,
  sendFetchRecordingScorecardRequest,
  isEvaluated,
  setEvaluating,
}) => {
  const master = useMasterData();
  const auth = useAuth();
  const swal = useSwal();
  const initialDataState = {
    skip: 0,
    take: 5,
  };
  const [page, setPage] = useState(initialDataState);
  const pageChange = (event: GridPageChangeEvent) => {
    setPage(event.page);
  };

  const [localQuestions, setLocalQuestions] = useState<
    ScorecardEvaluationQuestion[] | undefined
  >();
  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    if (questions) {
      setLocalQuestions(questions);
    } 
    setPage(initialDataState)
  }, [questions]);

  const onRatingChange = (questionId: number, rating: number) => {
    const updatedLocalQuestions: ScorecardEvaluationQuestion[] = [];
    localQuestions?.forEach((q) => {
      if (q.id === questionId) {
        updatedLocalQuestions.push({
          ...q,
          rating: rating === null ? 0 : rating,
        });
      } else {
        updatedLocalQuestions.push(q);
      }
    });
    setLocalQuestions(updatedLocalQuestions);
    setEdited(true);
  };

  const onSaveHandler = async () => {
    if (localQuestions) {
      try {
        setPending(true);
        const data = await recordingsService.addRecordingScorecard(
          recordingId,
          scorecard,
          localQuestions,
          shared === true
        );
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: fetchKeyTranslation(
                  "SwtSCCreatedUpdatedTitle",
                  "Scorecard Updated"
                ),
          confirmButtonText: 
           fetchKeyTranslation("OKText", "OK"),
        };
        swal.fire(swalOptions);
        setEdited(false);
        onEvaluationSave(data);
      } catch (err) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: fetchKeyTranslation("SwtAltFailedTitle", "Oops...")
          ,
          text: fetchKeyTranslation(
                  "SwtAltFailedText",
                  "Something went wrong! Please Try Again!"
                ),
        };
        swal.fire(swalOptions);
      } finally {
        setPending(false);
      }
    }
  };

  const onCancelHandler = () => {
    setLocalQuestions(questions);
    setEdited(false);
    if (isEvaluated) {
      sendFetchRecordingScorecardRequest();
    } else {
      setEvaluating(false);
    }
  };

  const customCellMobile = (props: any) => {
    return (
      <td className="listMobile">
        <div className="listRowMobile p-r-40">
          <div className="tblUsr float-left w-100">
            <div className="topUsrAreaPic">
              <div className="topUsrAreaPic-i p-r-12">
                <div className="contact-list-icon bg-darksteelblue">
                  <div className="contact-list-icon-txt">
                    <i className="bi bi-arrow-right-short fs-26"></i>
                  </div>
                </div>
              </div>
              <div className="topUsrAreaPic-tx">
                <span className="text-black fs-14 font-weight-bold cursor-default text-decoration-none float-left w-100 line-height-1 p-b-5">
                  Question
                </span>
                <span className="fs-14 cursor-default text-decoration-none float-left w-100 line-height-1">
                  Hello customer could you provide the your detail for the next
                  level requirement?
                </span>
              </div>
            </div>
          </div>
          <div className="float-left w-100 p-l-50">
            <div className="inerLabel p-t-15 float-left w-100">
              <span className="text-black fs-14 font-weight-bold cursor-default text-decoration-none float-left line-height-1 p-b-5 p-r-5">
                Category:
              </span>
              <span className="fs-14 float-left line-height-1">Financial</span>
            </div>
            <div className="inerLabel p-t-3 float-left w-100 d-flex align-items-center">
              <span className="text-black fs-14 font-weight-bold float-left line-height-1 p-t-5">
                Evaluation:
              </span>
              <span className="float-left line-height-1">
                <span className="ratingStar">
                  <Rating precision={"half"} />
                </span>
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const CellScoreCardCategory = (props: CustomGridCellProps) => {
    return (
      <td>
        <div>
        {fetchKeyTranslation(
                master?.getEntityNameKeyfromConfigMap(
                  EntityLocalizationGroupEnum.ScorecardQuestionGroup,
                  props.dataItem.category ?? ""
                ) ?? "",
                props.dataItem.category
              )}
        </div>
      </td>
    );
  };

  const CellScoreCardQuestion = (props: CustomGridCellProps) => {
    return (
      <td className="mx-th-tag d-flex align-items-center">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-i p-r-12">
              <div className="contact-list-icon bg-darksteelblue">
                <div className="contact-list-icon-txt">
                  <i className="bi bi-arrow-right-short fs-26"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-td-spn line-height-2">
          {fetchKeyTranslation(
                master?.getEntityNameKeyfromConfigMap(
                  EntityLocalizationGroupEnum.ScorecardQuestion,
                  props.dataItem.question ?? ""
                ) ?? "",
                props.dataItem.question
              )}
        </div>
      </td>
    );
  };

  const CustomCellForEvaluation = (props: CustomGridCellProps) => {
    return (
      <td>
        <div className="ratingStar">
          <Rating
            min={0.5}
            max={5}
            precision={"half"}
            value={props.dataItem.rating}
            onChange={(e: RatingChangeEvent) =>
              onRatingChange(props.dataItem.id, e.value)
            }
            disabled={
              scorecard.isArchived ||
              !auth?.checkUserAccess(AccessPermissionEnum.EvaluateCalls) ||
              (!auth?.accessToken && shared === true)
            }
          />
        </div>
      </td>
    );
  };

  return (
    <>
      {pending && (
        <LoadingOverlay
          customStyle={{ position: "fixed", marginTop: "55px"}}
          themeColor={"light"}
          size={"medium"}
        />
      )}
      <div className="row">
        <div className="col-md-12">
          <div className="table-container table-responsive table-overflow-hedden borderLeftSecondChild table-mobile">
            <Grid
              data={localQuestions?.slice(page.skip, page.take + page.skip)}
              skip={page.skip}
              take={page.take}
              total={localQuestions?.length}
              pageable={true}
              onPageChange={pageChange}
            >
              <GridNoRecords>
                {!error && !localQuestions && (
                  <Loader themeColor={"primary"} type={"infinite-spinner"} />
                )}
                {error && (
                  <span>
                    <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                    {fetchKeyTranslation("SwtAltFailedText", "Something went wrong! Please Try Again!")}
                  </span>
                )}
                {localQuestions && (
                  <span>
                    {fetchKeyTranslation("MsgSCNoQuesFound","No Scorecard Questions Added.")}
                  </span>
                )}
              </GridNoRecords>
              <GridColumn
                width={0}
                field="cellMobile"
                cell={customCellMobile}
              />
              <GridColumn
                field="question"
                title={fetchKeyTranslation("TitleQuestion", "Question")}
                cell={CellScoreCardQuestion}
              />
              <GridColumn
                field="category"
                title={fetchKeyTranslation("TitleCategory", "Category")}
                cell={CellScoreCardCategory}
              />
              <GridColumn
                field="rating"
                title={fetchKeyTranslation("TitleEvaluation", "Evaluation Score")}
                cell={CustomCellForEvaluation}
              />
            </Grid>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="btnGroup p-t-10 text-right">
            {edited && questions && questions.length > 0 && (
              <Button
                className="selectScorecardBtn line-height-1 text-white"
                themeColor={"secondary"}
                style={{ height: "30px" }}
                onClick={onCancelHandler}
              >
                {fetchKeyTranslation("TextCancel", "Cancel")}
              </Button>
            )}
            {edited && questions && questions.length > 0 && (
              <Button
                className="selectScorecardBtn line-height-1 text-white m-l-5"
                themeColor={"primary"}
                style={{ height: "30px" }}
                onClick={onSaveHandler}
              >
                {pending ? (
                  <Loader
                    size={"small"}
                    type={"infinite-spinner"}
                    themeColor={"light"}
                  />
                ) : (
                  fetchKeyTranslation("TextSave", "Save")
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecordingScorecardGrid;
