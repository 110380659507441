import React, { useEffect, useRef } from "react";

interface SearchFieldWithButtonFields {
  className?: string;
  placeholder?: string;
  value: string;
  onEscapeKeyFunc: boolean;
  searchTextChangeHandler: (event: any) => void;
  handleOnKeyDown?: (event: any) => void;
  updateValue: (value: string) => void;
  disabled?: boolean;
  clearSearch: () => void;
}

const CustomSearchFieldWithButton: React.FC<SearchFieldWithButtonFields> = ({
  className,
  placeholder,
  value,
  onEscapeKeyFunc,
  searchTextChangeHandler,
  handleOnKeyDown,
  updateValue,
  disabled,
  clearSearch,
}) => {
  const searchField = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const keyDownFunction = (event: KeyboardEvent) => {
      const key = event.key;
      if (key === "Escape" && onEscapeKeyFunc) {
        updateValue("");
        searchField?.current?.blur();
      }
    };
    document.addEventListener("keydown", keyDownFunction);
    return () => {
      document.removeEventListener("keydown", keyDownFunction);
    };
  }, []);

  return (
    <>
      <input
        style={disabled ? { opacity: "0.5" } : {}}
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={searchTextChangeHandler}
        onKeyDown={handleOnKeyDown}
        ref={onEscapeKeyFunc ? searchField : null}
        disabled={disabled}
      />
      {value ? (
        <span
          className="searchIconBtn"
          onClick={clearSearch}
          style={{ cursor: "pointer" }}
        >
          <i style={disabled ? { opacity: "0.5" } : {}} className="bi bi-x"></i>
        </span>
      ) : (
        <span className="searchIconBtn">
          <i
            style={disabled ? { opacity: "0.5" } : {}}
            className="bi bi-search"
          ></i>
        </span>
      )}
    </>
  );
};

export default CustomSearchFieldWithButton;
